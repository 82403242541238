import React from 'react';
import { useSelector } from 'react-redux';

import { Icon, Persona, Stack, Text } from '@fluentui/react';

import { RootState } from '../../../../../redux/application/redux-store';
import { useAuthContext } from '../../../../../contexts/auth-context';
import { useClassNames } from './iai-layout-heading.classNames';

import './iai-layout-heading.scss';

import { IAIShimmer } from '../../../../components/IAIShimmer';

interface IIAILayoutHeading {
    icon?: string;
    heading?: string;
    secondaryHeading?: string;
}

const IAILayoutHeading: React.FunctionComponent<IIAILayoutHeading> = ({ icon, heading, secondaryHeading }) => {
    const {
        contextType
    } = useAuthContext();

    const {
        headingIconContainer,
        headingIcon,
        headingSecondaryHeading
    } = useClassNames();

    const iaiAccountInfo = useSelector((state: RootState) => state.account);

    return (
        <div className={`iai-layout-heading-container`}>
            <Stack horizontal horizontalAlign="center">
                <div className={`heading-icon-container ${headingIconContainer}`}>
                    <Icon className={headingIcon} iconName={icon} />
                </div>

                <Stack.Item>
                    <Text block variant="xxLargePlus" className="layout-heading">{heading}</Text>
                    <Text block variant="mediumPlus">{secondaryHeading}</Text>
                </Stack.Item>

                <div style={{ flexGrow: 1 }} />

                {iaiAccountInfo.accountInitialized && contextType === 'ms-online' &&
                    <IAIShimmer
                        type={'shimmer-persona'}
                        loading={!iaiAccountInfo.graphInitialized}
                        style={{ minWidth: 215 }}
                    >
                        <Persona
                            text={iaiAccountInfo.displayName}
                            secondaryText={iaiAccountInfo.department}
                            tertiaryText={iaiAccountInfo.mail}
                            optionalText={iaiAccountInfo.jobTitle}
                            imageUrl={iaiAccountInfo.photo}
                            imageAlt={iaiAccountInfo.displayName}
                            imageShouldFadeIn={true}
                        />
                    </IAIShimmer>
                }
            </Stack>
        </div>
    );
}

export default IAILayoutHeading;