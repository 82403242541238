import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Stack, Text } from '@fluentui/react';

import { RootState } from '../../redux/application/redux-store';
import { ProfileRoleEnum } from '../../api-client';
import { RouterSettings } from '../../utils/router';
import { useClassNames } from './homepage.classNames';

import { IAIApplicationLayout } from '../../common/layouts/IAIApplicationLayout';
import { IAIWidgetIcon } from '../../common/components/IAIWidget';
import { default as WidgetNotices } from './components/WidgetNotices/widget-notices';

const Homepage: React.FunctionComponent = () => {
    const {
        homepageContainer
    } = useClassNames();

    const HOMEPAGE_WIDGETS = [
        {
            icon: 'Add',
            title: 'Nuova Richiesta',
            tooltip: 'Inserisci una nuova richiesta di supporto.',
            to: RouterSettings.PATHS.Dashboard.Ticket.new,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            icon: 'Ticket',
            title: 'Richieste di Supporto',
            tooltip: 'Gestisci le richieste di supporto inserite.',
            to: RouterSettings.PATHS.Dashboard.Ticket.root,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            icon: 'Lightbulb',
            title: 'FAQ',
            tooltip: 'Consulta le risposte rapide inserite.',
            to: RouterSettings.PATHS.Dashboard.Solution.root,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentManager
            ]
        },
        {
            icon: 'LineChart',
            title: 'Report & Stats.',
            tooltip: 'Consulta i dati della piattaforma.',
            to: RouterSettings.PATHS.Dashboard.ReportStats.root,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentManager
            ]
        }
    ];

    const iaiAccountInfo = useSelector((state: RootState) => state.account);

    const _handleWidgetsByRole = () => (
        _.filter(HOMEPAGE_WIDGETS, entry => (
            _.some(entry.roles, entry => entry === iaiAccountInfo.role)
        ))
    );

    return (
        <IAIApplicationLayout
            icon="Home"
            heading="Help Point"
            secondaryHeading="Punto di accesso per gestire le richieste di supporto dell'Ateneo."
        >
            <div className={`mt-3 ${homepageContainer}`}>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Text block variant="xLargePlus" className="mb-4">
                            Esplora la piattaforma
                        </Text>

                        <Stack
                            horizontal
                            horizontalAlign="center"
                            tokens={{ childrenGap: 15 }}
                            wrap
                            styles={{ inner: { justifyContent: 'start' } }}
                        >
                            {_.map(_handleWidgetsByRole(), (dataItem, dataIndex) => (
                                <IAIWidgetIcon key={dataIndex} {...dataItem} />
                            ))}
                        </Stack>
                    </div>
                    <div className="col-12 col-md-6">
                        <Text block variant="xLargePlus" className="mb-4">
                            Avvisi
                        </Text>

                        <WidgetNotices />
                    </div>
                </div>
            </div>
        </IAIApplicationLayout>
    );
}

export default Homepage;