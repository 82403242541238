import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Spinner } from '@fluentui/react/lib/Spinner';

import { _loginRequest, _msalConfig } from '../../providers/auth-provider';
import { ApplicationSettings } from '../../utils/application';
import { RouterSettings } from '../../utils/router';
import { useAuthContext } from '../../contexts/auth-context';
import { useLogin } from './hooks/useLogin';
import { useClassNames } from './login-page.classNames';

import { IAILoginLayout } from '../../common/layouts/IAILoginLayout';

const LoginPage: React.FunctionComponent = () => {
    const {
        instance,
        inProgress
    } = useMsal();

    const {
        isAuthenticated
    } = useAuthContext();

    const {
        handleAccountInfo,
        accountInfoLoading,
        handleGraphInfo,
        handleGraphAccountPhoto
    } = useLogin();

    const {
        loginPageButton,
        loginPageContainer,
        loginPageH2,
        loginPageLogo,
        loginPageSpinner
    } = useClassNames();

    const navigate = useNavigate();

    // const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        const onAccountSuccess = () => {
            handleGraphInfo();
            handleGraphAccountPhoto();

            navigate(RouterSettings.PATHS.Dashboard.root);
        };

        isAuthenticated && handleAccountInfo({ onSuccess: onAccountSuccess });
    }, [isAuthenticated]);

    return (
        <IAILoginLayout>
            <div className={loginPageContainer}>
                <div className="row h-100 justify-content-center">
                    <div className="col-12 align-self-center">
                        <img
                            src={`/theme/${ApplicationSettings.IAI_APP_THEME_ROOT}/app-logo.png`}
                            className={loginPageLogo}
                            alt={'Intranet AI'}
                        />

                        {(
                            inProgress === InteractionStatus.Startup ||
                            inProgress === InteractionStatus.HandleRedirect ||
                            inProgress === InteractionStatus.Login ||
                            accountInfoLoading
                        ) &&
                            <React.Fragment>
                                <h2 className={loginPageH2}>
                                    {'Access in progress...'}
                                </h2>

                                <Spinner className={loginPageSpinner} />
                            </React.Fragment>
                        }

                        {(
                            inProgress !== InteractionStatus.Startup &&
                            inProgress !== InteractionStatus.HandleRedirect &&
                            inProgress !== InteractionStatus.Login &&
                            !accountInfoLoading
                        ) &&
                            <React.Fragment>
                                <h2 className={loginPageH2}>
                                    {'Login into your Office365 Account'}
                                </h2>

                                <PrimaryButton
                                    className={loginPageButton}
                                    disabled={isAuthenticated}
                                    iconProps={{ iconName: 'OfficeLogo' }}
                                    text={'Login'}
                                    onClick={() => instance.loginRedirect(_loginRequest)}
                                />
                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </IAILoginLayout>
    );
}

export default LoginPage;