import { useState } from 'react';
import { saveAs } from 'file-saver';

import { useMsal } from "@azure/msal-react";

import { TicketAttachment, TicketAttachmentFile } from '../../../../../../api-client';
import { IHookCallback } from '../../../../../../common/models/IHookCallback';
import { TicketApiClient } from '../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../utils/notification';

export const useTicketAttachment = () => {
    const {
        instance
    } = useMsal();

    interface IRetrieveTicketAttachments extends IHookCallback {
        id: string;
    }

    const [ticketAttachments, setTicketAttachments] = useState<TicketAttachment[]>();
    const [ticketAttachmentsLoading, setTicketAttachmentsLoading] = useState<boolean>(false);

    const handleRetrieveTicketAttachments = async (params: IRetrieveTicketAttachments) => {
        setTicketAttachmentsLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getTicketAttachments(params.id)
            .then((response) => {
                setTicketAttachments(response.data);

                params.onSuccess && params.onSuccess();
            })
            .catch((reason) => {
                setTicketAttachments([]);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero degli allegati.',
                    title: 'Impossibile recuperare gli allegati!'
                });
            })
            .finally(() => {
                setTicketAttachmentsLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveTicketAttachment extends IHookCallback {
        id: string;
        attachmentId: string;
        fileName: string;
    }

    const [ticketAttachmentLoading, setTicketAttachmentLoading] = useState<boolean>(false);

    const handleRetrieveTicketAttachment = async (params: IRetrieveTicketAttachment) => {
        setTicketAttachmentLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getTicketAttachment(params.id, params.attachmentId, { responseType: 'blob' })
            .then((response) => {
                saveAs(new Blob([response.data]), `${params.fileName}`);

                params.onSuccess && params.onSuccess();
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero dell\'allegato.',
                    title: 'Impossibile recuperare l\'allegato!'
                });
            })
            .finally(() => {
                setTicketAttachmentLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteTicketAttachment extends IHookCallback {
        id: string;
        attachmentId: string;
    }

    const [deleteTicketAttachmentLoading, setDeleteTicketAttachmentLoading] = useState<boolean>(false);

    const handleDeleteTicketAttachment = async (params: IDeleteTicketAttachment) => {
        setDeleteTicketAttachmentLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.deleteTicketAttachment(params.id, params.attachmentId)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Allegato cancellato con successo.',
                    title: 'Allegato cancellato!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la cancellazione dell\'allegato.',
                    title: 'Impossibile cancellare l\'allegato!'
                });
            })
            .finally(() => {
                setDeleteTicketAttachmentLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    // Upload Attachments

    interface IUploadTicketAttachment extends IHookCallback {
        id: string;
        attachment: TicketAttachmentFile;
    }

    const [uploadTicketAttachmentLoading, setUploadTicketAttachmentLoading] = useState<boolean>(false);

    const handleUploadTicketAttachment = async (params: IUploadTicketAttachment) => {
        setUploadTicketAttachmentLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.insertTicketAttachment(params.id, params.attachment)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Allegato caricato con successo con successo.',
                    title: 'Allegato caricato!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il caricamento dell\'allegato.',
                    title: 'Impossibile caricare l\'allegato!'
                });
            })
            .finally(() => {
                setUploadTicketAttachmentLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleRetrieveTicketAttachments,
        ticketAttachments,
        ticketAttachmentsLoading,
        handleRetrieveTicketAttachment,
        ticketAttachmentLoading,
        handleDeleteTicketAttachment,
        deleteTicketAttachmentLoading,
        // UPLOAD
        handleUploadTicketAttachment,
        uploadTicketAttachmentLoading
    }
}