import React from 'react';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IDialogProps, Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';

interface IIAIDialog {
    dialogProps?: IDialogProps
    disabled?: boolean;
    title?: string | JSX.Element;
    subText?: string;
    confirmText?: string;
    dismissText?: string;
    onConfirm?: () => void;
    onDismiss?: () => void;
}

const IAIDialog: React.FunctionComponent<IIAIDialog> = ({ dialogProps, disabled, title, subText, confirmText, dismissText, onConfirm, onDismiss }) => (
    <Dialog
        dialogContentProps={{
            type: DialogType.largeHeader,
            title: title,
            subText: subText
        }}
        modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 650 } }
        }}
        {...dialogProps}
    >
        <DialogFooter>
            <PrimaryButton
                disabled={disabled}
                text={confirmText || 'Ok'}
                onClick={onConfirm}
            />

            <DefaultButton
                disabled={disabled}
                text={dismissText || 'Cancel'}
                onClick={onDismiss}
            />
        </DialogFooter>
    </Dialog>
);

export default IAIDialog;