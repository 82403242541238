import { useDispatch } from 'react-redux';

import { initAccountInfo } from '../../redux/slices/Account/accountSlice';
import { initAgents, initAreas, initDepartments, initNotices } from '../../redux/slices/System/systemSlice';

import { SessionStorageHelper } from '../../utils/storage';

const persistingConfiguration = [
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_ACCOUNT,
        initializer: initAccountInfo
    },
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AGENT,
        initializer: initAgents
    },
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AREA,
        initializer: initAreas
    },
    {
        key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_DEPARTMENT,
        initializer: initDepartments
    },
    // {
    //     key: SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_NOTICES,
    //     initializer: initNotices
    // }
];

export const usePersistReduxStore = () => {
    const dispatch = useDispatch();

    const handlePersistingStore = () => {
        persistingConfiguration.forEach((configuration) => {
            let persistedData: any = SessionStorageHelper.retrieveItem(configuration.key);
            if (persistedData) {
                dispatch(configuration.initializer(persistedData));
            }
        });
    }

    return {
        handlePersistingStore
    }
}