import React from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import _ from 'lodash';

import { Stack, Text } from '@fluentui/react';

import { RootState } from '../../../../redux/application/redux-store';
import { DateTimeEnchanter } from '../../../../utils/data-enchanters';
import { useClassNames } from './widget-notices.classNames';

import './widget-notices.scss';

import { IAIEmptyContent } from '../../../../common/components/IAIEmptyContent';

const WidgetNotices: React.FunctionComponent = () => {
    const {
        noticeItem,
        noticeItemTitle
    } = useClassNames();

    const iaiNotices = _.filter(useSelector((state: RootState) => state.system.notices), entry => new Date(entry.availableTo!) > new Date());

    if (!iaiNotices.length) {
        return (
            <IAIEmptyContent title="Nessun avviso disponibile" />
        );
    }

    return (
        <Stack
            tokens={{ childrenGap: 15 }}
            wrap
            className="widget-notices-container"
        >
            {_.map(iaiNotices, (dataItem, dataIndex) => (
                <div key={dataIndex} className={`notice-item ${noticeItem}`}>
                    <Text block variant="smallPlus" className="mb-1">
                        {DateTimeEnchanter.formatLongDate(dataItem.availableFrom)}
                    </Text>
                    <Text block className={noticeItemTitle}>
                        {dataItem.title}
                    </Text>
                    <Text block>
                        {parse(dataItem.description || '')}
                    </Text>
                </div>
            ))}
        </Stack>
    );
}

export default WidgetNotices;