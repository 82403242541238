import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IIAITextEditor {
    errorMessage?: string | JSX.Element;
    required?: boolean;
}

interface IIAITextEditorClassNames {
    iaiControlContainer: string;
    iaiControlTitle: string;
    iaiControlDescription: string;
    iaiControlEditor: string;
    iaiControlSmallEditor: string;
    iaiControlError: string;
}

export const useClassNames = ({ errorMessage, required }: IIAITextEditor): IIAITextEditorClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        iaiControlContainer: {
            background: 'trasparent'
        },
        iaiControlTitle: {
            background: 'trasparent',
            fontSize: '14px',
            fontWeight: '600',
            color: 'rgb(48, 46, 40)',
            boxSizing: 'border-box',
            boxShadow: 'none',
            margin: '0px',
            display: 'block',
            padding: '5px 0px',
            overflowWrap: 'break-word',
            selector: required && {
                '&::after': {
                    content: "' *'",
                    color: 'rgb(164, 38, 44)',
                    paddingRight: 12
                }
            }
        },
        iaiControlDescription: {
            background: 'trasparent',
            color: 'rgb(162, 159, 150)',
            fontSize: '10px'
        },
        iaiControlEditor: {
            background: applicationTheme.semanticColors.bodyBackground,
            width: '100%',
            height: 300,
            display: 'inline-block',
            zIndex: '9999',
            selector: {
                '& .ql-container': {
                    background: applicationTheme.semanticColors.bodyBackground,
                    border: errorMessage && '1px solid rgb(164, 38, 44) !important'
                }
            }
        },
        iaiControlSmallEditor: {
            background: applicationTheme.semanticColors.bodyBackground,
            width: '100%',
            height: 100,
            display: 'inline-block',
            zIndex: '9999',
            selector: {
                '& .ql-container': {
                    background: applicationTheme.semanticColors.bodyBackground,
                    border: errorMessage && '1px solid rgb(164, 38, 44) !important'
                }
            }
        },
        iaiControlError: {
            animationName: 'css-0, css-13',
            animationDuration: '0.367s',
            animationTimingFunction: 'cubic-bezier(0.1, 0.9, 0.2, 1)',
            animationFillMode: 'both',
            background: 'trasparent',
            fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif',
            fontSize: 12,
            fontWeight: 400,
            color: 'rgb(164, 38, 44)',
            margin: 0,
            paddingTop: 5,
            display: 'flex',
            alignItems: 'center'
        }
    });
}