import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IReportPageClassNames {
    reportPageContainer: string;
}

export const useClassNames = (): IReportPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        reportPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        }
    });
}