import React from 'react';
import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { useClassNames } from './parameters-page.classNames';

const ParametersPage: React.FunctionComponent = () => {
    const {
        parametersPageContainer
    } = useClassNames();

    return (
        <IAIApplicationLayout
            icon={'Settings'}
            heading={'Parametri Generici'}
            secondaryHeading={'Gestisci i Parametri Generici.'}
        >
            <div className={parametersPageContainer}>
                ...
            </div>
        </IAIApplicationLayout>
    );
}

export default ParametersPage;