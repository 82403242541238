import { toast } from 'react-toastify';

import { Icon } from '@fluentui/react/lib/Icon';

interface INotification {
    title: React.ReactNode;
    message: React.ReactNode;
    type: 'info' | 'success' | 'warning' | 'error' | 'default';
}

export class NotificationHelper {
    public static createNotification = (notificaton: INotification) => (
        toast(_handleNotificationContent(notificaton.title, notificaton.message), {
            icon: _handleNotificationIcon(notificaton.type),
            type: notificaton.type
        })
    );
}

const _handleNotificationContent = (title: React.ReactNode, message: React.ReactNode) => (
    <div className="iai-notification">
        <div className="iai-notification-title">
            {title}
        </div>
        <div className="iai-notification-message">
            {message}
        </div>
    </div>
);

const _handleNotificationIcon = (type: 'info' | 'success' | 'warning' | 'error' | 'default') => {
    switch (type) {
        case 'error': {
            return (
                <Icon iconName="ErrorBadge" />
            );
        }
        case 'info': {
            return (
                <Icon iconName="Info" />
            );
        }
        case 'success': {
            return (
                <Icon iconName="CompletedSolid" />
            );
        }
        case 'warning': {
            return (
                <Icon iconName="Warning" />
            );
        }
        default: {
            return false;
        }
    }
}