import React from 'react';

interface IIAITextColumn {
    text?: string;
}

const IAITextColumn: React.FunctionComponent<IIAITextColumn> = ({ text }) => (
    <div className="text-left">
        {text || ''}
    </div>
);

export default IAITextColumn;