const helpers = {
    humanFileSize: function (bytes: any, si = false, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    },

    convertToBase64: function (file: any) {
        return new Promise((resolve, reject) => {
            const fileReader: any = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
                file.base64 = fileReader.result.split(',')[1];
            };
            fileReader.onerror = (error: any) => {
                reject(error);
            };
        });
    },
    downloadFile: function (blob: any, fileName: string, mimetype: string) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blob;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    downloadBase64File: function (contentBase64: string, fileName: string, mimetype: string) {
        //let mimetype = this.getMimeType(fileName);
        const linkSource = `data:${mimetype};base64,${contentBase64}`;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = fileName;
        downloadLink.click();
    },
    // TODO
    getMimeType: function (filename: string) {
        const extension = filename.split('.').pop();
        let mimetype = '';
        if (extension?.toLowerCase() === 'pdf') {
            mimetype = 'application/pdf';
        }
        else if (extension?.toLowerCase() === 'png') {
            mimetype = 'image/png';
        }
    },

    getFormatDate: function (date?: Date): string {
        if (!date) return '';
        const month = date.getMonth() + 1; // + 1 because 0 indicates the first Month of the Year.
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    },

    generateUuid: function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            c => {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
            });
        return uuid;
    }
}

export default helpers;