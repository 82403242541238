import React from 'react';

import './iai-shimmer.scss';

import IAICalloutShimmer from './components/IAICalloutShimmer/iai-callout-shimmer';
import IAIHedingShimmer from './components/IAIHedingShimmer/iai-heading-shimmer';
import IAICommentShimmer from './components/IAICommentShimmer/iai-comment-shimmer';
import IAIPersonaShimmer from './components/IAIPersonaShimmer/iai-persona-shimmer';
import IAIWidgetShimmer from './components/IAIWidgetShimmer/iai-widget-shimmer';

interface IIAIShimmer {
    type: 'shimmer-callout' | 'shimmer-heading' | 'shimmer-comment' | 'shimmer-persona' | 'shimmer-widget';
    loading?: boolean;
    style?: any;
}

const IAIShimmer: React.FunctionComponent<IIAIShimmer> = ({ type, children, loading, style }) => {
    const _handleShimmerType = () => {
        switch (type) {
            case 'shimmer-callout':
                return (
                    <IAICalloutShimmer style={style} />)
                    ;
            case 'shimmer-heading':
                return (
                    <IAIHedingShimmer style={style} />
                );
            case 'shimmer-comment':
                return (
                    <IAICommentShimmer style={style} />
                );
            case 'shimmer-persona':
                return (
                    <IAIPersonaShimmer style={style} />
                );
            case 'shimmer-widget':
                return (
                    <IAIWidgetShimmer style={style} />
                );
        }
    }

    return (
        <div className="iai-shimmer-container" style={style}>
            {loading
                ? _handleShimmerType()
                : children
            }
        </div>
    );
}

export default IAIShimmer;