import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

import { DataEnchanter } from '../../../../../../utils/data-enchanters';

interface IIAIStatusColumn {
    status?: string;
}

interface IIAIStatusColumnClassNames {
    statusIcon: string;
}

export const useClassNames = ({ status }: IIAIStatusColumn): IIAIStatusColumnClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        statusIcon: {
            color: DataEnchanter.colorByStatusEnum(status || ''),
            fontSize: '16px',
            marginRight: '5px'
        }
    });
}