import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IIAIWidgetClassNames {
    widgetContainer: string;
    widgetIcon: string;
}

export const useClassNames = (): IIAIWidgetClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        widgetContainer: {
            background: applicationTheme.palette.neutralLight,
            '&:hover': {
                background: applicationTheme.palette.themeLighter
            },
            '&:hover .widget-icon': {
                color: applicationTheme.palette.themePrimary
            }
        },
        widgetIcon: {
            color: applicationTheme.palette.themePrimary
        }
    });
}