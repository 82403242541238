import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Icon } from '@fluentui/react/lib/Icon';

import { RootState } from '../../../../../../../redux/application/redux-store';
import { IRouteSettings } from '../../../../../../../utils/router';
import { useClassNames } from './iai-sidenav-item.classNames';

interface IIAISidenavItem {
    routeItem: IRouteSettings;
}

const IAISidenavItem: React.FunctionComponent<IIAISidenavItem> = ({ routeItem }) => {
    const {
        iaiSidenavItem
    } = useClassNames();

    const iaiAccountInfo = useSelector((state: RootState) => state.account);

    const accountIsInRole = _.some(routeItem.roles, entry => entry === iaiAccountInfo.role);

    const location = useLocation();

    if (accountIsInRole) {
        return (
            <Link
                className={`${iaiSidenavItem} ${location.pathname === routeItem.path && 'isActive'}`}
                to={routeItem.path}
            >
                <TooltipHost
                    id={`th_${routeItem.routeKey}__`}
                    content={routeItem.displayName}
                    styles={{ root: routeItem.style }}
                >
                    <Icon iconName={routeItem.icon} />
                </TooltipHost>
            </Link>
        );
    }

    return null;
}

export default IAISidenavItem;