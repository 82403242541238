import * as React from 'react';

import { ApplicationSettings } from '../../../utils/application';
import { useClassNames } from './iai-login-layout.classNames';

import './iai-login-layout.scss';

const IAILoginLayout: React.FunctionComponent = (props) => {
    const {
        loginLayoutContainer,
        layoutBgContainer
    } = useClassNames();

    return (
        <div className={`layout-container ${loginLayoutContainer}`}>
            <div className="row h-100 g-0">
                <div className="col-8">
                    <div
                        className={`layout-bg-container ${layoutBgContainer}`}
                        style={{
                            backgroundImage: `url('/theme/${ApplicationSettings.IAI_APP_THEME_ROOT}/app-login-bg.jpg')`
                        }}
                    />
                </div>
                <div className="col-4">
                    <div className="layout-child-container h-100">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IAILoginLayout;