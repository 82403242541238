import axios from 'axios';

import { IPublicClientApplication } from "@azure/msal-browser";

import { ApplicationSettings } from '../utils/application';
import { aquireGraphToken } from './auth-provider';
import { aquireMsTeamsToken } from './ms-teams-provider';

const baseAPIUrl = 'https://graph.microsoft.com/v1.0';

export const GraphApiClient = async (instance: IPublicClientApplication) => {
	const acquireTokenResponse = await aquireGraphToken(instance);
	const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

	return axios.create({
		baseURL: baseAPIUrl,
		headers: {
			Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
		},
		timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
	});
}