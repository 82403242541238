import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';

import {
    IButtonStyles,
    Callout,
    DefaultButton,
    PrimaryButton,
    DirectionalHint,
    IconButton,
    Label,
    Stack,
    TooltipHost
} from '@fluentui/react';

import { TicketAttachmentFile } from '../../../../../api-client';
import { DataEnchanter } from '../../../../../utils/data-enchanters';
import { keepAttachments, clearAttachments } from '../../../../../redux/slices/Ticket/ticketSlice';
import { useTicketAttachment } from './hooks/useTicketAttachment';
import { useClassNames } from './ticket-attachment.classNames';

import './ticket-attachment.scss';

import { IAIShimmer } from '../../../../../common/components/IAIShimmer';

interface ITicketAttachment {
    disabled?: boolean;
    ticketId?: string;
}

const TicketAttachment: React.FunctionComponent<ITicketAttachment> = ({ disabled, ticketId }) => {
    const {
        handleRetrieveTicketAttachments,
        ticketAttachments,
        ticketAttachmentsLoading,
        handleRetrieveTicketAttachment,
        ticketAttachmentLoading,
        handleDeleteTicketAttachment,
        deleteTicketAttachmentLoading,
        handleUploadTicketAttachment,
        uploadTicketAttachmentLoading
    } = useTicketAttachment();

    useEffect(() => {
        ticketId && handleRetrieveTicketAttachments({ id: ticketId });
    }, [ticketId]);

    const {
        calloutContainerHeader,
        calloutHeading,
        calloutContainerBody
    } = useClassNames();

    const buttonStyles: IButtonStyles = {
        root: {
            minWidth: 0,
            padding: '0 5px'
        }
    }

    // Upload Attachments

    const dispatch = useDispatch();

    const [attachmentsUpload, setAttachmentsUpload] = useState<any[]>([]);

    useEffect(() => {
        if (attachmentsUpload && !!attachmentsUpload.length) {
            dispatch(clearAttachments());

            _.each(attachmentsUpload, entry => {
                if (entry) {
                    DataEnchanter.fileToB64(entry).then((response) => {
                        let attachmentFile: TicketAttachmentFile = {
                            fileContent: response.split(',')[1],
                            fileName: entry.name,
                            fileSize: entry.size,
                            fileType: entry.type
                        };

                        if (ticketId) {
                            handleUploadTicketAttachment({
                                id: ticketId,
                                attachment: attachmentFile,
                                onSuccess: () => handleRetrieveTicketAttachments({ id: ticketId })
                            });
                        }
                        else {
                            dispatch(keepAttachments(attachmentFile));
                        }
                    });
                }
            });
        }
    }, [attachmentsUpload]);

    const _handleDrop = useCallback((acceptedFiles) => {
        setAttachmentsUpload(acceptedFiles);
    }, []);

    const {
        getRootProps,
        getInputProps
    } = useDropzone({ onDrop: _handleDrop });

    const [delegatedAttachment, setDelegatedAttachment] = useState<any>();

    const [attachmentRef, setAttachmentRef] = useState<number>(0);

    const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);

    return (
        <div className={`ticket-attachment-container`}>
            <Label>
                Inserisci eventuali allegati:
            </Label>

            <IAIShimmer type={'shimmer-heading'} loading={ticketAttachmentsLoading}>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                    {ticketId
                        ?
                        _.map(ticketAttachments, (dataItem, dataIndex) => (
                            <TooltipHost
                                key={dataIndex}
                                content={`${dataItem.fileName} (${DataEnchanter.fileSize(dataItem.fileSize)})`}
                            >
                                <DefaultButton
                                    id={'attachment_button_' + dataIndex}
                                    iconProps={{ iconName: DataEnchanter.fileIcon(dataItem.fileName || '') }}
                                    styles={buttonStyles}
                                    onClick={() => {
                                        setDelegatedAttachment(dataItem);
                                        setAttachmentRef(dataIndex);
                                        setIsCalloutVisible(true);
                                    }}
                                />
                            </TooltipHost>
                        ))
                        :
                        _.map(attachmentsUpload, (dataItem, dataIndex) => (
                            <TooltipHost
                                key={(dataIndex + (ticketAttachments?.length || 0))}
                                content={`${dataItem.name} (${DataEnchanter.fileSize(dataItem.size)})`}
                            >
                                <DefaultButton
                                    id={'attachment_button_' + (dataIndex + (ticketAttachments?.length || 0))}
                                    iconProps={{ iconName: DataEnchanter.fileIcon(dataItem.name || '') }}
                                    styles={buttonStyles}
                                    onClick={() => {
                                        setDelegatedAttachment(dataItem);
                                        setAttachmentRef((dataIndex + (ticketAttachments?.length || 0)));
                                        setIsCalloutVisible(true);
                                    }}
                                />
                            </TooltipHost>
                        ))
                    }

                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />

                        <PrimaryButton
                            disabled={disabled || deleteTicketAttachmentLoading || uploadTicketAttachmentLoading}
                            iconProps={{ iconName: 'Add' }}
                            styles={buttonStyles}
                        />
                    </div>
                </Stack>
            </IAIShimmer>

            {isCalloutVisible &&
                <Callout
                    target={`#attachment_button_${attachmentRef}`}
                    className="iai-callout-container-file"
                    setInitialFocus
                    onDismiss={() => setIsCalloutVisible(false)}
                    directionalHint={DirectionalHint.topLeftEdge}
                    directionalHintFixed={false}
                >
                    <div className={`callout-container-header-file  ${calloutContainerHeader}`}>
                        <span className={`callout-heading-file ${calloutHeading}`}>
                            {`${delegatedAttachment.fileName} (${DataEnchanter.fileSize(delegatedAttachment.fileSize)})`}
                        </span>

                        <IconButton
                            className={`callout-close-btn-file`}
                            iconProps={{ iconName: 'Cancel' }}
                            onClick={() => setIsCalloutVisible(false)}
                        />
                    </div>
                    <div className={`callout-container-body-file ${calloutContainerBody} pt-3`}>
                        {ticketId
                            ?
                            <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 15 }}>
                                <PrimaryButton
                                    type="button"
                                    text={'Apri'}
                                    disabled={ticketAttachmentLoading}
                                    iconProps={{ iconName: 'Download' }}
                                    onClick={() => {
                                        handleRetrieveTicketAttachment({
                                            id: ticketId,
                                            attachmentId: delegatedAttachment.id,
                                            fileName: delegatedAttachment.fileName
                                        });
                                    }}
                                />
                                <PrimaryButton
                                    type="button"
                                    text={'Cancella'}
                                    disabled={deleteTicketAttachmentLoading}
                                    iconProps={{ iconName: 'Delete' }}
                                    styles={{ root: { alignSelf: 'end' } }}
                                    onClick={() => {
                                        handleDeleteTicketAttachment({
                                            id: ticketId,
                                            attachmentId: delegatedAttachment.id,
                                            onSuccess: () => {
                                                handleRetrieveTicketAttachments({ id: ticketId });

                                                setIsCalloutVisible(false);
                                            }
                                        });
                                    }}
                                />
                            </Stack>
                            :
                            <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 15 }}>
                                <PrimaryButton
                                    type="button"
                                    text={'Cancella'}
                                    disabled={deleteTicketAttachmentLoading}
                                    iconProps={{ iconName: 'Delete' }}
                                    styles={{ root: { alignSelf: 'end' } }}
                                    onClick={() => {
                                        const _attachments = _.clone(attachmentsUpload);

                                        if (_attachments && !!_attachments.length) {
                                            const index: number = _attachments.indexOf(delegatedAttachment, 0);
                                            if (index > -1) {
                                                _attachments.splice(index, 1);

                                                setAttachmentsUpload(_attachments);
                                                setIsCalloutVisible(false);
                                            }
                                        }
                                    }}
                                />
                            </Stack>
                        }
                    </div>
                </Callout>
            }
        </div>
    );
}

export default TicketAttachment;