import { IPublicClientApplication } from "@azure/msal-browser";

import { ApplicationSettings } from '../utils/application';
import { aquireToken } from './auth-provider';
import { aquireMsTeamsToken } from './ms-teams-provider';

import {
    AnnouncementApi,
    AreaApi,
    Configuration,
    EventReceiverApi,
    ExternalApi,
    PropertyApi,
    SolutionApi,
    SystemApi,
    TicketApi
} from '../api-client';

const baseAPIUrl = ApplicationSettings.IAI_APP_API_URL;

export const AnnouncementApiClient = async (instance: IPublicClientApplication) => {
    const acquireTokenResponse = await aquireToken(instance);
    const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

    return new AnnouncementApi({
        basePath: baseAPIUrl,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
            },
            timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
        },
        isJsonMime: Configuration.prototype.isJsonMime
    });
}

export const AreaApiClient = async (instance: IPublicClientApplication) => {
    const acquireTokenResponse = await aquireToken(instance);
    const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

    return new AreaApi({
        basePath: baseAPIUrl,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
            },
            timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
        },
        isJsonMime: Configuration.prototype.isJsonMime
    });
}

export const EventReceiverApiClient = async (instance: IPublicClientApplication) => {
    const acquireTokenResponse = await aquireToken(instance);
    const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

    return new EventReceiverApi({
        basePath: baseAPIUrl,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
            },
            timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
        },
        isJsonMime: Configuration.prototype.isJsonMime
    });
}

export const ExternalApiClient = async (instance: IPublicClientApplication) => {
    const acquireTokenResponse = await aquireToken(instance);
    const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

    return new ExternalApi({
        basePath: baseAPIUrl,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
            },
            timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
        },
        isJsonMime: Configuration.prototype.isJsonMime
    });
}

export const ExternalPublicApiClient = async () => new ExternalApi({
    basePath: baseAPIUrl,
    baseOptions: {
        timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
    },
    isJsonMime: Configuration.prototype.isJsonMime
});

export const PropertyApiClient = async (instance: IPublicClientApplication) => {
    const acquireTokenResponse = await aquireToken(instance);
    const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

    return new PropertyApi({
        basePath: baseAPIUrl,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
            },
            timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
        },
        isJsonMime: Configuration.prototype.isJsonMime
    });
}

export const SolutionApiClient = async (instance: IPublicClientApplication) => {
    const acquireTokenResponse = await aquireToken(instance);
    const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

    return new SolutionApi({
        basePath: baseAPIUrl,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
            },
            timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
        },
        isJsonMime: Configuration.prototype.isJsonMime
    });
}

export const SystemApiClient = async (instance: IPublicClientApplication) => {
    const acquireTokenResponse = await aquireToken(instance);
    const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

    return new SystemApi({
        basePath: baseAPIUrl,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
            },
            timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
        },
        isJsonMime: Configuration.prototype.isJsonMime
    });
}

export const TicketApiClient = async (instance: IPublicClientApplication) => {
    const acquireTokenResponse = await aquireToken(instance);
    const aquireMsTeamsTokenResponse = await aquireMsTeamsToken();

    return new TicketApi({
        basePath: baseAPIUrl,
        baseOptions: {
            headers: {
                Authorization: `Bearer ${acquireTokenResponse?.accessToken || aquireMsTeamsTokenResponse}`
            },
            timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
        },
        isJsonMime: Configuration.prototype.isJsonMime
    });
}

export const TicketPublicApiClient = async () => new TicketApi({
    basePath: baseAPIUrl,
    baseOptions: {
        timeout: ApplicationSettings.IAI_APP_AXIOS_TIMEOUT
    },
    isJsonMime: Configuration.prototype.isJsonMime
});