import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { useAuthContext } from '../contexts/auth-context';

import { LoginPage } from '../pages/Login';

export const withAuthentication = (WrappedComponent: any) => (props: any) => {
    const {
        inProgress
    } = useMsal();

    const {
        isAuthenticated
    } = useAuthContext();

    // const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return (
            <WrappedComponent {...props} />
        );
    }
    else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        return (
            <LoginPage {...props} />
        );
    }
    else {
        return null;
    }
}