import { Configuration, PopupRequest, IPublicClientApplication } from "@azure/msal-browser";
import { ApplicationSettings } from '../utils/application';

// Config object to be passed to Msal on creation
export const _msalConfig: Configuration = {
    auth: {
        authority: ApplicationSettings.IAI_APP_AUTHORITY,
        clientId: ApplicationSettings.IAI_APP_CLIENTID,
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin + '/login'
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const _loginRequest: PopupRequest = {
    scopes: [
        `https://${ApplicationSettings.IAI_APP_RESOURCE_NAME}/${ApplicationSettings.IAI_APP_CLIENTID}/user_impersonation`
    ]
};

export const aquireToken = async (instance: IPublicClientApplication) => {
    const account: any = instance.getActiveAccount();
    if (!account) {
        // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        return null;
    }

    return await instance.acquireTokenSilent({
        ..._loginRequest,
        account: account
    });
}

export const _loginGraphRequest: PopupRequest = {
    scopes: [
        "openid",
        "profile",
        "User.Read"
    ]
};

export const aquireGraphToken = async (instance: IPublicClientApplication) => {
    const account: any = instance.getActiveAccount();
    if (!account) {
        // throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        return null;
    }

    return await instance.acquireTokenSilent({
        ..._loginGraphRequest,
        account: account
    });
}