import React from 'react';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './iai-toast-container.scss';

import { useClassNames } from './iai-toast-container.classNames';

const IAIToastContainer: React.FunctionComponent = (props) => {
    const {
        toastWrapper,
        toastContainer,
        toastContainerProgress
    } = useClassNames();

    return (
        <ToastContainer
            autoClose={3000}
            className={toastWrapper}
            toastClassName={toastContainer}
            newestOnTop
            progressClassName={toastContainerProgress}
        />
    );
}

export default IAIToastContainer;