import * as MsTeams from "@microsoft/teams-js";

export const isMsTeamsAuthenticated = async (): Promise<boolean> => {
	try {
		await MsTeams.app.initialize();

		const context = await MsTeams.app.getContext();

		return !!context.user;
	}
	catch
	{
		return false;
	}
}

export const aquireMsTeamsToken = async (): Promise<string> => {
	try {
		await MsTeams.app.initialize();

		return await MsTeams.authentication.getAuthToken({});;
	}
	catch
	{
		return '';
	}
}