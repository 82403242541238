import { ProfileRoleEnum } from '../../api-client';

export interface IRouteSettings {
    routeKey: string;
    displayName?: string;
    icon?: string;
    needAuthentication?: boolean;
    path: string;
    sidenavRender?: boolean;
    style?: any;
    roles?: string[]
}

export class RouterSettings {
    public static ROUTES_KEYS = {
        IAI_APP_LOGIN: "IAI_APP_LOGIN",
        IAI_APP_DASHBOARD: "IAI_APP_DASHBOARD",
        IAI_APP_TICKET: "IAI_APP_TICKET",
        IAI_APP_TICKET_TYPE: "IAI_APP_TICKET_TYPE",
        IAI_APP_TICKET_DETAILS: "IAI_APP_TICKET_DETAILS",
        IAI_APP_TICKET_VIEW: "IAI_APP_TICKET_VIEW",
        IAI_APP_SOLUTION: "IAI_APP_SOLUTION",
        IAI_APP_REPORT: "IAI_APP_REPORT",
        IAI_APP_SETTING: "IAI_APP_SETTING",
        IAI_APP_SETTING_NOTICE: "IAI_APP_SETTING_NOTICE",
        IAI_APP_SETTING_PROPERTY: "IAI_APP_SETTING_PROPERTY",
        IAI_APP_SETTING_AREA: "IAI_APP_SETTING_AREA",
        IAI_APP_SETTING_PARAMETER: "IAI_APP_SETTING_PARAMETER",
        IAI_APP_SETTING_GROUPS: "IAI_APP_SETTING_GROUPS",
        IAI_APP_SETTING_EVENT_RECEIVER: "IAI_APP_SETTING_EVENT_RECEIVER"
    };

    public static ROUTES: IRouteSettings[] = [
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_LOGIN,
            path: '/login'
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_DASHBOARD,
            displayName: 'Homepage',
            icon: 'Home',
            needAuthentication: true,
            path: '/',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET,
            displayName: 'Ticket',
            icon: 'Ticket',
            needAuthentication: true,
            path: '/ticket',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_TYPE,
            needAuthentication: true,
            path: '/ticket/:ticketType/type',
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_DETAILS,
            needAuthentication: true,
            path: '/ticket/:ticketId',
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_TICKET_VIEW,
            path: '/ticket/:ticketId/view',
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentAgent,
                ProfileRoleEnum.DepartmentManager,
                ProfileRoleEnum.User
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SOLUTION,
            displayName: 'FAQ',
            icon: 'Lightbulb',
            needAuthentication: true,
            path: '/solution',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentManager
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_REPORT,
            displayName: 'Report',
            icon: 'LineChart',
            needAuthentication: true,
            path: '/report-stats',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator,
                ProfileRoleEnum.DepartmentManager
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING,
            displayName: 'Impostazioni',
            icon: 'Settings',
            needAuthentication: true,
            path: '/setting',
            sidenavRender: true,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_NOTICE,
            displayName: 'Notifiche',
            icon: 'Flag',
            needAuthentication: true,
            path: '/setting/notice',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_PROPERTY,
            displayName: 'Proprietà Personalizzate',
            icon: 'AllApps',
            needAuthentication: true,
            path: '/setting/property',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_GROUPS,
            displayName: 'Gruppi / Dipartimenti',
            icon: 'EngineeringGroup',
            needAuthentication: true,
            path: '/setting/groups',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_AREA,
            displayName: 'Aree',
            icon: 'ArrangeBringToFront',
            needAuthentication: true,
            path: '/setting/area',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_PARAMETER,
            displayName: 'Parametri Generici',
            icon: 'Settings',
            needAuthentication: true,
            path: '/setting/parameter',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            routeKey: RouterSettings.ROUTES_KEYS.IAI_APP_SETTING_EVENT_RECEIVER,
            displayName: 'Event Receivers',
            icon: 'ScheduleEventAction',
            needAuthentication: true,
            path: '/setting/eventreceivers',
            sidenavRender: false,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        }
    ];

    public static PATHS = {
        Login: {
            root: '/login'
        },
        Dashboard: {
            root: '/',
            Ticket: {
                root: '/ticket',
                new: '/ticket/00-0000',
                type: (type: string) => `/ticket/${type}/type`,
                view: (id: string) => `/ticket/${id}/view`,
                edit: (id: string) => `/ticket/${id}`
            },
            Solution: {
                root: '/solution'
            },
            ReportStats: {
                root: '/report-stats'
            },
            Setting: {
                root: '/setting',
                notice: '/setting/notice',
                property: '/setting/property',
                groups: '/setting/groups',
                area: '/setting/area',
                parameter: '/setting/parameter',
                eventreceivers: '/setting/eventreceivers'
            }
        }
    };
}