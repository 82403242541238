import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { Item, EventReceiver } from '../../../../../../api-client';
import { EventReceiverApiClient } from '../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../utils/notification';
import { IHookCallback } from '../../../../../../common/models';

export const useEventReceiver = () => {
    const {
        instance
    } = useMsal();

    const [eventReceivers, setEventReceivers] = useState<EventReceiver[]>();
    const [eventReceiversLoading, setEventReceiversLoading] = useState<boolean>(false);

    const handleEventReceivers = async () => {
        setEventReceiversLoading(true);

        const clientApi = await EventReceiverApiClient(instance);

        clientApi.getEventReceivers()
            .then((response) => {
                setEventReceivers(response.data);
            })
            .catch((reason) => {
                setEventReceivers([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero delle aree.',
                    title: 'Impossibile recuperare le aree!'
                });
            })
            .finally(() => {
                setEventReceiversLoading(false);
            });
    }

    // CRUD Operations

    interface ICreateEventReceiver extends IHookCallback {
        eventReceiver: EventReceiver;
    }

    const [createEventReceiver, setCreateEventReceiver] = useState<Item>();
    const [createEventReceiverLoading, setCreateEventReceiverLoading] = useState<boolean>(false);

    const handleCreateEventReceiver = async (params: ICreateEventReceiver) => {
        setCreateEventReceiverLoading(true);

        const clientApi = await EventReceiverApiClient(instance);

        clientApi.insertEventReceiver(params.eventReceiver)
            .then((response) => {
                setCreateEventReceiver(response.data);

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'EventReceiver creata con successo.',
                    title: 'EventReceiver creata!'
                });
            })
            .catch((reason) => {
                setCreateEventReceiver(undefined);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la creazione dell\'eventReceiver.',
                    title: 'Impossibile creare l\'eventReceiver!'
                });
            })
            .finally(() => {
                setCreateEventReceiverLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateEventReceiver extends IHookCallback {
        id: string;
        eventReceiver: EventReceiver;
    }

    const [updateEventReceiverLoading, setUpdateEventReceiverLoading] = useState<boolean>(false);

    const handleUpdateEventReceiver = async (params: IUpdateEventReceiver) => {
        setUpdateEventReceiverLoading(true);

        const clientApi = await EventReceiverApiClient(instance);

        clientApi.updateEventReceiver(params.id, params.eventReceiver)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'EventReceiver aggiornata con successo.',
                    title: 'EventReceiver aggiornata!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante l\'aggiornamento dell\'eventReceiver.',
                    title: 'Impossibile aggiornare l\'eventReceiver!'
                });
            })
            .finally(() => {
                setUpdateEventReceiverLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    // interface IRetrieveEventReceiver extends IHookCallback {
    //     id: string;
    //     onRetrieveSuccess: (eventReceiver: EventReceiver) => void;
    // }

    // const [retrieveEventReceiver, setRetrieveEventReceiver] = useState<EventReceiver>();
    // const [retrieveEventReceiverLoading, setRetrieveEventReceiverLoading] = useState<boolean>(false);

    // const handleRetrieveEventReceiver = async (params: IRetrieveEventReceiver) => {
    //     setRetrieveEventReceiverLoading(true);
    //     const clientApi = await EventReceiverApiClient(instance);
    //     clientApi.getEventReceiver(params.id)
    //         .then((response: any) => {
    //             setRetrieveEventReceiver(response.data);
    //             params.onRetrieveSuccess(response.data);
    //         })
    //         .catch((reason) => {
    //             setRetrieveEventReceiver(undefined);
    //             params.onError && params.onError();
    //             NotificationHelper.createNotification({
    //                 type: 'error',
    //                 message: 'Si è verificato un errore durante il recupero dell\'eventReceiver.',
    //                 title: 'Impossibile recuperare l\'eventReceiver!'
    //             });
    //         })
    //         .finally(() => {
    //             setRetrieveEventReceiverLoading(false);
    //             params.onAlways && params.onAlways();
    //         });
    // }

    // interface IDeleteEventReceiver extends IHookCallback {
    //     id: string;
    // }

    // const [deleteEventReceiverLoading, setDeleteEventReceiverLoading] = useState<boolean>(false);

    // const handleDeleteEventReceiver = async (params: IDeleteEventReceiver) => {
    //     setDeleteEventReceiverLoading(true);

    //     const clientApi = await EventReceiverApiClient(instance);

    //     clientApi.deleteEventReceiver(params.id)
    //         .then((response) => {
    //             params.onSuccess && params.onSuccess();

    //             NotificationHelper.createNotification({
    //                 type: 'success',
    //                 message: 'EventReceiver cancellata con successo.',
    //                 title: 'EventReceiver cancellata!'
    //             });
    //         })
    //         .catch((reason) => {
    //             params.onError && params.onError();

    //             NotificationHelper.createNotification({
    //                 type: 'error',
    //                 message: 'Si è verificato un errore durante la cancellazione dell\'eventReceiver.',
    //                 title: 'Impossibile cancellare l\'eventReceiver!'
    //             });
    //         })
    //         .finally(() => {
    //             setDeleteEventReceiverLoading(false);

    //             params.onAlways && params.onAlways();
    //         });
    // }

    return {
        handleEventReceivers,
        eventReceivers,
        eventReceiversLoading,
        // CRUD Operations
        handleCreateEventReceiver,
        createEventReceiver,
        createEventReceiverLoading,
        handleUpdateEventReceiver,
        updateEventReceiverLoading,
        // handleRetrieveEventReceiver,
        // retrieveEventReceiver,
        // retrieveEventReceiverLoading,
        // handleDeleteEventReceiver,
        // deleteEventReceiverLoading
    }
}