import React, { useState } from 'react';
import moment from 'moment';

import { DatePicker, PrimaryButton, Separator, TextField, TimePicker } from '@fluentui/react';

import { useAuthContext } from '../../../../../contexts/auth-context';
import { DatePickerStringsIT } from '../../../../../utils/constants/date-format-costants';
import helpers from '../../../../../utils/helpers/helpers';
import { useTeams } from './hooks/useTeams';

import './teams-modal.scss';

import { IAIModal } from '../../../IAIModal';

interface ITeamsModal {
    visible?: boolean;
    onCreation: (joinWebUrl: string) => void;
    onModalDismiss: () => void;
}

const TeamsModal: React.FunctionComponent<ITeamsModal> = ({ visible, onCreation, onModalDismiss }) => {
    const {
        contextType
    } = useAuthContext();

    const {
        handleOnlineMeeting,
        handleOnlineMeetingClient,
        graphOnlineMeetingLoading
    } = useTeams();

    const [meetingSubject, setMeetingSubject] = useState<any>('');

    const [meetingDate, setMeetingtDate] = useState<any>(null);
    const [meetingStartDateTime, setMeetingStartDateTime] = useState<any>(null);
    const [meetingEndDateTime, setMeetingEndDateTime] = useState<any>(null);

    const [meetingStartTime, setMeetingStartTime] = useState<any>(null);
    const [meetingEndTime, setMeetingEndTime] = useState<any>(null);

    const _handleCreateMeeting = () => {
        const onCreationSuccess = (meetingCreated: any) => {
            if (!meetingCreated || !meetingCreated.joinWebUrl) {
                return;
            }

            onCreation(
                `<div>
                    E' stato schedulato il meeting <b>"${meetingSubject}"</b> su teams il
                    <br />
                    <b>${moment(meetingDate).format("DD/MM/YYYY")}</b> dalle <b>${moment(meetingStartTime).format("HH:mm")}</b> alle <b>${moment(meetingEndTime).format("HH:mm")}</b>
                </div>
                <br />
                <div>
                    Premi sul seguente link per partecipare:
                    <br />
                    <a href="${meetingCreated.joinWebUrl}" target="_blank">Partecipa al Meeting</a>
                </div>`
            );

            _handleResetAll();
        }

        if (contextType === 'ms-online') {
            handleOnlineMeeting({
                subject: meetingSubject,
                startDateTime: meetingStartDateTime,
                endDateTime: meetingEndDateTime,
                onOnlineMeetingCreated: onCreationSuccess
            });
        }

        if (contextType === 'ms-teams') {
            handleOnlineMeetingClient({
                subject: meetingSubject,
                startDateTime: meetingStartDateTime,
                endDateTime: meetingEndDateTime,
                onOnlineMeetingCreated: onCreationSuccess
            });
        }
    }

    const _handleResetAll = () => {
        setMeetingSubject('');

        setMeetingtDate(null);
        setMeetingStartDateTime(null);
        setMeetingEndDateTime(null);

        setMeetingStartTime(null);
        setMeetingEndTime(null);

        onModalDismiss();
    }

    const handleMeetingCreation = (name: string, value: any) => {
        if (name == 'meetingSubject') {
            setMeetingSubject(value);
        }
        if (name == 'meetingDate') {
            setMeetingtDate(value);

            // Set "startDateTime"
            let dateStart = moment(value).format("YYYY-MM-DD");
            let timeStart = moment(new Date()).format("HH:mm");
            const dateTimeStart = moment(moment(dateStart + ' ' + timeStart)).toDate()
            setMeetingStartTime(dateTimeStart);
            setMeetingStartDateTime(dateTimeStart);

            // Set "endDateTime"
            let dateEnd = moment(value).format("YYYY-MM-DD");
            let timeEnd = moment(new Date()).format("HH:mm");
            const dateTimeEnd = moment(moment(dateEnd + ' ' + timeEnd)).toDate()
            setMeetingEndTime(dateTimeEnd);
            setMeetingEndDateTime(dateTimeEnd);
        }
        if (name == 'meetingStartTime') {
            setMeetingStartTime(value);
            // Set "startDateTime"
            let date = moment(meetingDate).format("YYYY-MM-DD");
            let time = moment(value).format("HH:mm");
            const dateTimeStart = moment(moment(date + ' ' + time)).toDate()
            setMeetingStartDateTime(dateTimeStart);
        }
        if (name == 'meetingEndTime') {
            setMeetingEndTime(value);
            // Set "endDateTime"
            let date = moment(meetingDate).format("YYYY-MM-DD");
            let time = moment(value).format("HH:mm");
            const dateTimeEnd = moment(moment(date + ' ' + time)).toDate()
            setMeetingEndDateTime(dateTimeEnd);
        }
    }

    return (
        <IAIModal
            isOpen={visible}
            heading="Pianifica riunione"
            secondaryHeading="Compila il modulo e pianifica una riunione su Microsoft Teams."
            isBlocking={true}
            onDismiss={_handleResetAll}
        >
            <div className="row">
                <div className="col-4 text-start">
                    <DatePicker
                        label="Data:"
                        strings={DatePickerStringsIT}
                        formatDate={(date?: Date): string => helpers.getFormatDate(date)}
                        disabled={graphOnlineMeetingLoading}
                        value={meetingDate}
                        onSelectDate={(value) => {
                            handleMeetingCreation('meetingDate', value)
                        }}
                    />
                </div>
                <div className="col-4 text-start">
                    <TimePicker
                        label="dalle:"
                        allowFreeform={true}
                        defaultValue={meetingStartTime}
                        disabled={meetingDate == null || meetingDate == '' || graphOnlineMeetingLoading}
                        onChange={(event, time) => {
                            handleMeetingCreation('meetingStartTime', time)
                        }}
                    />
                </div>
                <div className="col-4 text-start">
                    <TimePicker
                        label="alle:"
                        defaultValue={meetingEndTime}
                        disabled={meetingDate == null || meetingDate == '' || graphOnlineMeetingLoading}
                        onChange={(event, time) => {
                            handleMeetingCreation('meetingEndTime', time)
                        }}
                    />
                </div>
                <div className="col-12 text-start">
                    <Separator />
                </div>
                <div className="col-12 text-start">
                    <TextField
                        id={'sln_displayName__'}
                        label={'Oggetto:'}
                        placeholder={'Inserisci oggetto del Meeting...'}
                        disabled={graphOnlineMeetingLoading}
                        value={meetingSubject}
                        onChange={(ev, value) => handleMeetingCreation('meetingSubject', value)}
                    />
                    <Separator />
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-end">
                    <PrimaryButton
                        type="submit"
                        text={'Crea Meeting'}
                        iconProps={{ iconName: 'TeamsLogo' }}
                        styles={{ root: { alignSelf: 'end' } }}
                        disabled={graphOnlineMeetingLoading || meetingSubject == '' || meetingStartDateTime == null || meetingEndDateTime == null || meetingStartDateTime == '' || meetingEndDateTime == ''}
                        onClick={_handleCreateMeeting}
                    />
                </div>
            </div>
        </IAIModal>
    );
}

export default TeamsModal;