import React from 'react';

import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';

import { IShimmer } from '../../../../models';

const IAICalloutShimmer: React.FunctionComponent<IShimmer> = ({ style }) => {
    const _handleShimmerElements = (): JSX.Element => (
        <div>
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, height: 7, width: '100%' }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, height: 7, width: '100%' }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, height: 7, width: '100%' }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, height: 7, width: '100%' }]} />
            <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 10 }]} />
        </div>
    );

    return (
        <div className="iai-callout-shimmer-container" style={style}>
            <Shimmer customElementsGroup={_handleShimmerElements()} width="100%" />
        </div>
    );
}

export default IAICalloutShimmer;