import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IIAILoginLayout {
    loginLayoutContainer: string;
    layoutBgContainer: string;
}

export const useClassNames = (): IIAILoginLayout => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        loginLayoutContainer: {
            background: applicationTheme.semanticColors.bodyBackground,
            height: '100vh'
        },
        layoutBgContainer: {
            backgroundPosition: 'center right',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            boxShadow: 'inset 0 0 0 1000px rgba(100, 100, 100, .35)',
            width: '100%',
            height: '100%'
        }
    });
}