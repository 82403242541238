import { getTheme, FontWeights } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IIAIAnnouncementItemClassNames {
    calloutContainerHeader: string;
    calloutHeading: string;
    calloutContainerBody: string;
}

export const useClassNames = (): IIAIAnnouncementItemClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        calloutContainerHeader: {
            borderLeft: `4px solid ${applicationTheme.palette.themePrimary}`
        },
        calloutHeading: {
            color: applicationTheme.palette.neutralPrimary
        },
        calloutContainerBody: {
            borderLeft: `4px solid ${applicationTheme.palette.themePrimary}`
        }
    });
}