import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IIAIModalClassNames {
    modalContainer: string;
    modalHeader: string;
    modalHeading: string;
    modalCloseButton: string;
    modalBody: string;
}

export const useClassNames = (): IIAIModalClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        modalContainer: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            maxHeight: 750,
            width: '60%',
            overflowX: 'hidden'
        },
        modalHeader: {
            flex: '1 1 auto',
            borderTop: `4px solid ${applicationTheme.palette.themePrimary}`,
            color: applicationTheme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'start',
            fontWeight: applicationTheme.fonts.mediumPlus,
            padding: '12px 12px 14px 24px',
        },
        modalHeading: {
            color: applicationTheme.palette.themePrimary
        },
        modalCloseButton: {
            color: applicationTheme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        modalBody: {
            flex: '0 0 auto',
            padding: '0 24px 24px 24px',
            overflow: 'hidden'
        }
    });
}