export class SortingComparer {
    public static stringComparer = (isSortedDescending?: boolean, recordA?: string, recordB?: string) => {
        if (!recordA || !recordB) {
            return -1;
        }

        if (isSortedDescending) {
            return recordA.toLowerCase() < recordB.toLowerCase() ? 1 : -1;
        }

        if (!isSortedDescending) {
            return recordA.toLowerCase() > recordB.toLowerCase() ? 1 : -1;
        }

        return 0;
    };
    public static numberComparer = (isSortedDescending?: boolean, recordA?: number, recordB?: number) => {
        if (!recordA || !recordB) {
            return -1;
        }

        if (isSortedDescending) {
            return recordA < recordB ? 1 : -1;
        }

        if (!isSortedDescending) {
            return recordA > recordB ? 1 : -1;
        }

        return 0;
    };
    public static dateComparer = (isSortedDescending?: boolean, recordA?: string, recordB?: string) => {
        if (isSortedDescending) {
            return new Date(recordA || 0).getTime() < new Date(recordB || 0).getTime() ? 1 : -1;
        }

        if (!isSortedDescending) {
            return new Date(recordA || 0).getTime() > new Date(recordB || 0).getTime() ? 1 : -1;
        }

        return 0;
    };
}