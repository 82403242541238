import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IWebchatMessageClassNames {
    messageContainer: string;
    messageContainerAlt: string;
}

export const useClassNames = (): IWebchatMessageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        messageContainer: {
            background: applicationTheme.palette.themePrimary
        },
        messageContainerAlt: {
            background: applicationTheme.palette.themeLight
        }
    });
}