interface IIntlCurrencyFormat {
    (value?: number | bigint, currency?: string, isoCode?: string | string[]): string;
}

interface IIntlNumberFormat {
    (value?: number | bigint, isoCode?: string | string[], maximumFractionDigits?: number): string;
}

const _defaultISOCode = navigator.language || 'en-US';

const _defaultCurrency = _defaultISOCode === 'en-US' ? 'USD' : 'EUR';

export class CurrencyEnchanter {
    public static formatCurrency: IIntlCurrencyFormat = (value = 0, currency = _defaultCurrency, isoCode = _defaultISOCode) => {
        let currencyCode = currency;
        if (!currency) {
            currencyCode = _defaultCurrency;
        }

        let currencyValue = value;
        if (!value || value < 0) {
            return `0.00`;
        }

        return new Intl.NumberFormat(isoCode, {
            style: "currency",
            currency: currencyCode,
            maximumFractionDigits: 2
        }).format(currencyValue);
    };
    public static formatDecimal: IIntlNumberFormat = (value = 0, isoCode = _defaultISOCode, maximumFractionDigits = 2) => (
        new Intl.NumberFormat(isoCode, {
            style: "decimal",
            maximumFractionDigits: maximumFractionDigits
        }).format(value)
    );
    public static formatPercentage: IIntlNumberFormat = (value = 0, isoCode = _defaultISOCode, maximumFractionDigits = 2) => (
        new Intl.NumberFormat(isoCode, {
            style: "percent",
            maximumFractionDigits: maximumFractionDigits
        }).format(value)
    );
}