import React, { useEffect } from 'react';
import parse from "html-react-parser";

import { IconButton } from '@fluentui/react/lib/Button';

import { Solution } from '../../../../../api-client';
import { SortingComparer } from '../../../../../utils/data-comparer';
import { DateTimeEnchanter } from '../../../../../utils/data-enchanters';
import { useSolution } from '../../../../hooks/useSolution';

import './solution-modal.scss';

import { IAICalloutColumn } from '../../../IAIDataTable/components/IAIColumns/IAICalloutColumn';
import { IAIDateColumn } from '../../../IAIDataTable/components/IAIColumns/IAIDateColumn';
import { IAIDataTable } from '../../../IAIDataTable';
import { IAIModal } from '../../../IAIModal';

interface ISolutionModal {
    visible?: boolean;
    onSelection: (description: string) => void;
    onModalDismiss: () => void;
}

const SolutionModal: React.FunctionComponent<ISolutionModal> = ({ visible, onSelection, onModalDismiss }) => {
    const {
        handleSolutions,
        solutions,
        solutionsLoading,
        handleRetrieveSolution,
        retrieveSolution,
        retrieveSolutionLoading
    } = useSolution();

    useEffect(() => {
        handleSolutions();
    }, []);

    const _handleSearchSolution = (entry: Solution, searchValue: string): boolean => (
        entry.title?.toLowerCase().search(searchValue) !== -1
    );

    return (
        <IAIModal
            isOpen={visible}
            heading="Rispondi con una soluzione"
            secondaryHeading="Consulta e seleziona una FAQ."
            isBlocking={true}
            onDismiss={onModalDismiss}
        >
            <IAIDataTable
                columns={[
                    {
                        key: 'solution_preview__',
                        name: '',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (element: any) => (
                            <IAICalloutColumn
                                title={element.title}
                                tooltipContent={'Anteprima soluzione'}
                                contentLoading={retrieveSolutionLoading}
                                content={
                                    retrieveSolution &&
                                    <div className="col-12">
                                        <span className={'callout-secondary-heading'}>
                                            {`Ultimo aggiornamento: ${DateTimeEnchanter.formatStandardDate(retrieveSolution.createdAt, 'it-IT')}`}
                                        </span>
                                        {/* <span className={'callout-text'} dangerouslySetInnerHTML={{ __html: retrieveSolution && retrieveSolution.description ? retrieveSolution.description : '' }}></span> */}
                                        <span className={'callout-text'}>
                                            {parse(retrieveSolution.description || '')}
                                        </span>
                                    </div>
                                }
                                onEngage={() => handleRetrieveSolution({
                                    id: element.id,
                                    onRetrieveSuccess: () => { }
                                })}
                            />
                        )
                    },
                    {
                        key: 'solution_title__',
                        fieldName: 'title',
                        name: 'Titolo',
                        minWidth: 250,
                        isResizable: true,
                        sorter: SortingComparer.stringComparer
                    },
                    {
                        key: 'solution_date__',
                        fieldName: 'createdAt',
                        name: 'Data inserimento',
                        minWidth: 100,
                        maxWidth: 100,
                        sorter: SortingComparer.dateComparer,
                        onRender: (element: Solution) => <IAIDateColumn dateTime={element.createdAt} />
                    },
                    {
                        key: 'solution_action__',
                        fieldName: '',
                        name: '',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (element: any) => (
                            <div className="text-center">
                                <IconButton
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={() => {
                                        onSelection(element.description);
                                        onModalDismiss();
                                    }}
                                />
                            </div>
                        )
                    }
                ]}
                dataSource={solutions || []}
                dataLoading={solutionsLoading}
                emptyContentLabel="Nessuna richiesta disponibile"
                searchable
                onSearch={_handleSearchSolution}
            />
        </IAIModal>
    );
}

export default SolutionModal;