import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import {
    DatePicker,
    DefaultButton,
    PrimaryButton,
    Panel,
    PanelType,
    Separator,
    Stack,
    TextField
} from '@fluentui/react';

import { RootState } from '../../../redux/application/redux-store';
import { TicketFilteringCriteria, TicketModeEnum, TicketPriorityEnum, TicketStatusEnum } from '../../../api-client';

// TODO: REFACTOR
import { DatePickerStringsIT } from '../../../utils/constants/date-format-costants';
import helpers from '../../../utils/helpers/helpers';

import { IAIDropdown } from '../IAIControls/IAIDropdown';

interface IIAITicketsRefinementDrawer {
    dataLoading?: boolean;
    drawerVisible?: boolean;
    criteria?: TicketFilteringCriteria;
    onApplyCriteria: () => void;
    onClearCriteria: () => void;
    onChangeCriteria: (name: string, value: any) => void;
    onDrawerDismiss: () => void;
}

const IAITicketsRefinementDrawer: React.FunctionComponent<IIAITicketsRefinementDrawer> = ({
    dataLoading,
    drawerVisible,
    criteria,
    onApplyCriteria,
    onClearCriteria,
    onChangeCriteria,
    onDrawerDismiss
}) => {
    const iaiSystem = useSelector((state: RootState) => state.system);
    const iaiAccount = useSelector((state: RootState) => state.account);

    const onTextChange = (e: any, newValue?: string) => {
        onChangeCriteria('requestedBy', newValue);
    }

    const debouncedOnChange = _.debounce(onTextChange, 500);

    return (
        <Panel
            headerText={'Filtri'}
            type={PanelType.medium}
            isBlocking={true}
            isFooterAtBottom={true}
            isOpen={drawerVisible}
            onDismiss={onDrawerDismiss}
            onRenderFooterContent={() => (
                <div className="text-end">
                    <PrimaryButton
                        disabled={dataLoading}
                        iconProps={{ iconName: 'Search' }}
                        styles={{ root: { marginRight: 10 } }}
                        onClick={onApplyCriteria}
                    >
                        {'Cerca'}
                    </PrimaryButton>
                    <DefaultButton
                        disabled={dataLoading}
                        iconProps={{ iconName: 'Delete' }}
                        onClick={onClearCriteria}
                    >
                        {'Pulisci'}
                    </DefaultButton>
                </div>
            )}
        >
            <Separator />

            <Stack tokens={{ childrenGap: 5 }}>
                <TextField
                    label={'Richiedente:'}
                    placeholder={'Inserisci un richiedente...'}
                    disabled={dataLoading}
                    defaultValue={criteria?.requestedBy || ''}
                    onChange={debouncedOnChange}
                />

                {(iaiAccount.role === 'administrator' || iaiAccount.role === 'departmentManager') && (
                    <React.Fragment>
                        <IAIDropdown
                            label={'Area:'}
                            placeholder={'Seleziona area...'}
                            /**/
                            options={_.map(iaiSystem.areas, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                            disabled={dataLoading || !iaiSystem.areasInitialized}
                            selectedKey={criteria?.areaId || null}
                            onChange={(ev, option) => onChangeCriteria('areaId', option?.key)}
                            onClear={() => onChangeCriteria('areaId', null)}
                        />
                        <IAIDropdown
                            label={'Dipartimento:'}
                            placeholder={'Seleziona dipartimento...'}
                            /**/
                            options={_.map(iaiSystem.departments, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                            disabled={dataLoading || !iaiSystem.departmentsInitialized}
                            selectedKey={criteria?.departmentId || null}
                            onChange={(ev, option) => onChangeCriteria('departmentId', option?.key)}
                            onClear={() => onChangeCriteria('departmentId', null)}
                        />
                        <IAIDropdown
                            label={'Operatore:'}
                            placeholder={'Seleziona l\'operatore...'}
                            /**/
                            options={_.map(iaiSystem.agents, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                            disabled={dataLoading || !iaiSystem.agentsInitialized}
                            selectedKey={criteria?.agentId || null}
                            onChange={(ev, option) => onChangeCriteria('agentId', option?.key)}
                            onClear={() => onChangeCriteria('agentId', null)}
                        />
                    </React.Fragment>
                )}

                <DatePicker
                    label={'Data da:'}
                    placeholder="Seleziona una data..."
                    /**/
                    strings={DatePickerStringsIT}
                    formatDate={(date?: Date): string => helpers.getFormatDate(date)}
                    disabled={dataLoading}
                    value={criteria?.fromDate ? new Date(criteria?.fromDate) : undefined}
                    onSelectDate={(value) => (
                        onChangeCriteria('fromDate', value ? moment(value).endOf('day').toDate().toISOString() : undefined)
                    )}
                />
                <DatePicker
                    label={'Data a:'}
                    placeholder="Seleziona una data..."
                    /**/
                    strings={DatePickerStringsIT}
                    formatDate={(date?: Date): string => helpers.getFormatDate(date)}
                    disabled={dataLoading}
                    value={criteria?.toDate ? new Date(criteria?.toDate) : undefined}
                    onSelectDate={(value) => (
                        onChangeCriteria('toDate', value ? moment(value).endOf('day').toDate().toISOString() : undefined)
                    )}
                />

                <IAIDropdown
                    label={'Stato:'}
                    placeholder={'Seleziona stato...'}
                    /**/
                    options={[
                        { key: TicketStatusEnum.Open, text: 'Aperto' },
                        { key: TicketStatusEnum.Pending, text: 'Preso in carico' },
                        { key: TicketStatusEnum.Working, text: 'In lavorazione' },
                        { key: TicketStatusEnum.Closed, text: 'Risolto' }
                    ]}
                    disabled={dataLoading}
                    selectedKey={criteria?.status || null}
                    onChange={(ev, option) => onChangeCriteria('status', option?.key)}
                    onClear={() => onChangeCriteria('status', null)}
                />
                <IAIDropdown
                    label={'Livello:'}
                    placeholder={'Seleziona il livello...'}
                    /**/
                    options={[
                        { key: TicketModeEnum.Level01, text: 'Livello 0' },
                        { key: TicketModeEnum.Level02, text: 'Livello 1' },
                        { key: TicketModeEnum.Level03, text: 'Livello 2' }
                    ]}
                    disabled={dataLoading}
                    selectedKey={criteria?.mode || null}
                    onChange={(ev, option) => onChangeCriteria('mode', option?.key)}
                    onClear={() => onChangeCriteria('mode', null)}
                />
                <IAIDropdown
                    label={'Priorità:'}
                    placeholder={'Seleziona priorità...'}
                    /**/
                    options={[
                        { key: TicketPriorityEnum.Low, text: 'Bassa' },
                        { key: TicketPriorityEnum.Medium, text: 'Media' },
                        { key: TicketPriorityEnum.High, text: 'Alta' }
                    ]}
                    disabled={dataLoading}
                    selectedKey={criteria?.priority || null}
                    onChange={(ev, option) => onChangeCriteria('priority', option?.key)}
                    onClear={() => onChangeCriteria('priority', null)}
                />
            </Stack>
        </Panel>
    );
}

export default IAITicketsRefinementDrawer;