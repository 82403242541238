import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { useMsal } from "@azure/msal-react";

import { RootState } from '../../../../redux/application/redux-store';
import { Ticket, TicketAttachmentFile, TicketChangeLog } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models/IHookCallback';
import { TicketApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { clearAttachments } from '../../../../redux/slices/Ticket/ticketSlice';

export const useTicketDetails = () => {
    const {
        instance
    } = useMsal();

    const dispatch = useDispatch();

    const iaiAttachments = useSelector((state: RootState) => state.ticket.attachments);

    interface ICreateTicket extends IHookCallback {
        ticket: Ticket;
    }

    const [createTicketLoading, setCreateTicketLoading] = useState<boolean>(false);

    const handleCreateTicket = async (params: ICreateTicket) => {
        setCreateTicketLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.insertTicket(params.ticket)
            .then((response) => {
                if (iaiAttachments && !!iaiAttachments.length) {
                    _.each(iaiAttachments, entry => {
                        _handleUploadTicketAttachment({
                            id: response.data.id!,
                            attachment: entry
                        });
                    });

                    dispatch(clearAttachments());
                }

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Ticket creato con successo.',
                    title: 'Ticket creato!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la creazione del ticket.',
                    title: 'Impossibile creare il ticket!'
                });
            })
            .finally(() => {
                setCreateTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveTicket extends IHookCallback {
        id: string;
        onRetrieveSuccess: (ticket: Ticket) => void;
    }

    const [retrieveTicket, setRetrieveTicket] = useState<Ticket>();
    const [retrieveTicketLoading, setRetrieveTicketLoading] = useState<boolean>(false);

    const handleRetrieveTicket = async (params: IRetrieveTicket) => {
        setRetrieveTicketLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getTicket(params.id)
            .then((response: any) => {
                setRetrieveTicket(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveTicket(undefined);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero del ticket.',
                    title: 'Impossibile recuperare il ticket!'
                });
            })
            .finally(() => {
                setRetrieveTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateTicket extends IHookCallback {
        id: string;
        ticket: Ticket;
    }

    const [updateTicketLoading, setUpdateTicketLoading] = useState<boolean>(false);

    const handleUpdateTicket = async (params: IUpdateTicket) => {
        setUpdateTicketLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.updateTicket(params.id, params.ticket)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Ticket aggiornato con successo.',
                    title: 'Ticket aggiornato!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante l\'aggiornamento del ticket.',
                    title: 'Impossibile aggiornare il ticket!'
                });
            })
            .finally(() => {
                setUpdateTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IUploadTicketAttachment extends IHookCallback {
        id: string;
        attachment: TicketAttachmentFile;
    }

    const _handleUploadTicketAttachment = async (params: IUploadTicketAttachment) => {
        const clientApi = await TicketApiClient(instance);

        clientApi.insertTicketAttachment(params.id, params.attachment)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Allegato caricato con successo con successo.',
                    title: 'Allegato caricato!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il caricamento dell\'allegato.',
                    title: 'Impossibile caricare l\'allegato!'
                });
            })
            .finally(() => {
                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveChangeLogs extends IHookCallback {
        id: string;
        onRetrieveSuccess: (changeLogs: TicketChangeLog[]) => void;
    }

    const [retrieveChangeLogs, setRetrieveChangeLogs] = useState<TicketChangeLog[]>();
    const [retrieveChangeLogsLoading, setRetrieveChangeLogsLoading] = useState<boolean>(false);

    const handleRetrieveChangeLogs = async (params: IRetrieveChangeLogs) => {
        setRetrieveChangeLogsLoading(true);

        const clientApi = await TicketApiClient(instance);
        clientApi.getTicketChangeLogs(params.id)
            .then((response) => {
                setRetrieveChangeLogs(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveChangeLogs([]);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: `Si è verificato un errore durante il recupero dei dati di monitoraggio.`,
                    title: `Impossibile recuperare i dati di monitoraggio!`
                });
            })
            .finally(() => {
                setRetrieveChangeLogsLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleCreateTicket,
        createTicketLoading,
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading,
        handleUpdateTicket,
        updateTicketLoading,
        handleRetrieveChangeLogs,
        retrieveChangeLogs,
        retrieveChangeLogsLoading
    }
}