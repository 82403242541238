import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { Area } from '../../../../api-client';
import { AreaApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { IHookCallback } from '../../../../common/models';
import { useSystem } from '../../../../common/hooks/useSystem';

export const useArea = () => {
    const {
        instance
    } = useMsal();

    const [areas, setAreas] = useState<Area[]>();
    const [areasLoading, setAreasLoading] = useState<boolean>(false);

    const handleAreas = async () => {
        setAreasLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.getAreas()
            .then((response) => {
                setAreas(response.data);
            })
            .catch((reason) => {
                setAreas([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero delle aree.',
                    title: 'Impossibile recuperare le aree!'
                });
            })
            .finally(() => {
                setAreasLoading(false);
            });
    }

    // CRUD Operations

    const {
        handleSystemAreas
    } = useSystem();

    interface ICreateArea extends IHookCallback {
        area: Area;
    }

    const [createAreaLoading, setCreateAreaLoading] = useState<boolean>(false);

    const handleCreateArea = async (params: ICreateArea) => {
        setCreateAreaLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.insertArea(params.area)
            .then((response) => {
                handleSystemAreas();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Area creata con successo.',
                    title: 'Area creata!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la creazione dell\'area.',
                    title: 'Impossibile creare l\'area!'
                });
            })
            .finally(() => {
                setCreateAreaLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveArea extends IHookCallback {
        id: string;
        onRetrieveSuccess: (area: Area) => void;
    }

    const [retrieveArea, setRetrieveArea] = useState<Area>();
    const [retrieveAreaLoading, setRetrieveAreaLoading] = useState<boolean>(false);

    const handleRetrieveArea = async (params: IRetrieveArea) => {
        setRetrieveAreaLoading(true);
        const clientApi = await AreaApiClient(instance);
        clientApi.getArea(params.id)
            .then((response: any) => {
                setRetrieveArea(response.data);
                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveArea(undefined);
                params.onError && params.onError();
                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero dell\'area.',
                    title: 'Impossibile recuperare l\'area!'
                });
            })
            .finally(() => {
                setRetrieveAreaLoading(false);
                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateArea extends IHookCallback {
        id: string;
        area: Area;
    }

    const [updateAreaLoading, setUpdateAreaLoading] = useState<boolean>(false);

    const handleUpdateArea = async (params: IUpdateArea) => {
        setUpdateAreaLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.updateArea(params.id, params.area)
            .then((response) => {
                handleSystemAreas();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Area aggiornata con successo.',
                    title: 'Area aggiornata!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante l\'aggiornamento dell\'area.',
                    title: 'Impossibile aggiornare l\'area!'
                });
            })
            .finally(() => {
                setUpdateAreaLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteArea extends IHookCallback {
        id: string;
    }

    const [deleteAreaLoading, setDeleteAreaLoading] = useState<boolean>(false);

    const handleDeleteArea = async (params: IDeleteArea) => {
        setDeleteAreaLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.deleteArea(params.id)
            .then((response) => {
                handleSystemAreas();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Area cancellata con successo.',
                    title: 'Area cancellata!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la cancellazione dell\'area.',
                    title: 'Impossibile cancellare l\'area!'
                });
            })
            .finally(() => {
                setDeleteAreaLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleAreas,
        areas,
        areasLoading,
        // CRUD Operations
        handleCreateArea,
        createAreaLoading,
        handleRetrieveArea,
        retrieveArea,
        retrieveAreaLoading,
        handleUpdateArea,
        updateAreaLoading,
        handleDeleteArea,
        deleteAreaLoading
    }
}