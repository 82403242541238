import React from 'react';

import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';

import { IShimmer } from '../../../../models';

const IAIPersonaShimmer: React.FunctionComponent<IShimmer> = ({ style }) => {
    const _handleShimmerElements = (): JSX.Element => (
        <div style={{ display: 'flex' }}>
            <ShimmerElementsGroup
                shimmerElements={[
                    { type: ShimmerElementType.circle, height: 40 },
                    { type: ShimmerElementType.gap, width: 16, height: 40 },
                ]}
            />
            <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                    { type: ShimmerElementType.line, width: '100%', height: 10, verticalAlign: 'bottom' },
                    { type: ShimmerElementType.line, width: '90%', height: 8 },
                    { type: ShimmerElementType.gap, width: '10%', height: 20 },
                ]}
            />
        </div>
    );

    return (
        <div className="iai-persona-shimmer-container" style={style}>
            <Shimmer customElementsGroup={_handleShimmerElements()} width="100%" />
        </div>
    );
}

export default IAIPersonaShimmer;