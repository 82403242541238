import { PartialTheme } from '@fluentui/react/lib/Theme';

const defaultTheme: PartialTheme = {
    palette: {
        themePrimary: '#e83782',
        themeLighterAlt: '#fef6fa',
        themeLighter: '#fbddea',
        themeLight: '#f8c0d7',
        themeTertiary: '#f183b1',
        themeSecondary: '#eb4e8f',
        themeDarkAlt: '#d13274',
        themeDark: '#b02a62',
        themeDarker: '#821f48',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
}

const univaqTheme: PartialTheme = {
    palette: {
        themePrimary: '#f1c350',
        themeLighterAlt: '#fefdf8',
        themeLighter: '#fdf5e2',
        themeLight: '#fbedc9',
        themeTertiary: '#f7db94',
        themeSecondary: '#f4cb64',
        themeDarkAlt: '#dab148',
        themeDark: '#b8953d',
        themeDarker: '#886e2d',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
};

export class ApplicationTheme {
    public static THEMES = {
        IAI_UNIVAQ: "IAI_UNIVAQ",
        IAI_APPLICATION: "IAI_APPLICATION",
    };

    public static partialTheme = (theme: string): PartialTheme => {
        switch (theme) {
            case ApplicationTheme.THEMES.IAI_UNIVAQ: return univaqTheme;
            case ApplicationTheme.THEMES.IAI_APPLICATION: return defaultTheme;

            default: return defaultTheme;
        }
    };

    public static themeResourcesRoot = (theme: string): string => {
        switch (theme) {
            case ApplicationTheme.THEMES.IAI_UNIVAQ: return 'univaq';
            case ApplicationTheme.THEMES.IAI_APPLICATION: return 'iai';

            default: return 'iai';
        }
    };
}