interface IIntlDateFormat {
    (dateTime?: string | number | Date, isoCode?: string | string[], options?: Intl.DateTimeFormatOptions): string;
}

const _defaultISOCode = navigator.language || 'en-US';

export class DateTimeEnchanter {
    public static formatStandardDate: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).format(d);
    };
    public static formatFullDate: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, { dateStyle: 'full' }).format(d);
    };
    public static formatLongDate: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, { dateStyle: 'long' }).format(d);
    };
    public static formatMediumDate: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, { dateStyle: 'medium' }).format(d);
    };
    public static formatShortDate: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, { dateStyle: 'short' }).format(d);
    };
    public static formatFullTime: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, { timeStyle: 'full' }).format(d);
    };
    public static formatLongTime: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, { timeStyle: 'long' }).format(d);
    };
    public static formatMediumTime: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, { timeStyle: 'medium' }).format(d);
    };
    public static formatShortTime: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, { timeStyle: 'short' }).format(d);
    };
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
    public static formatCustomDate: IIntlDateFormat = (dateTime, isoCode = _defaultISOCode, options = { dateStyle: 'full' }) => {
        if (!dateTime) {
            return '';
        }

        const d = new Date(dateTime);

        return new Intl.DateTimeFormat(isoCode, options).format(d);
    };
}