import React from 'react';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

interface IAnnouncementFormFooter {
    disabled?: boolean;
    onDismiss: () => void;
    onSubmit: () => void;
}

const AnnouncementFormFooter: React.FunctionComponent<IAnnouncementFormFooter> = ({ disabled, onDismiss, onSubmit }) => (
    <div className="announcement-form-footer-container">
        <PrimaryButton disabled={disabled} onClick={onSubmit} styles={{ root: { marginRight: 10 } }}>
            Salva
        </PrimaryButton>

        <DefaultButton disabled={disabled} onClick={onDismiss}>
            Annulla
        </DefaultButton>
    </div>
);

export default AnnouncementFormFooter;