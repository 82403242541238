import React from 'react';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

interface ISolutionFormFooter {
    disabled?: boolean;
    onDismiss: () => void;
    onSubmit: () => void;
}

const SolutionFormFooter: React.FunctionComponent<ISolutionFormFooter> = ({ disabled, onDismiss, onSubmit }) => (
    <div className="solution-form-footer-container">
        <PrimaryButton type="submit" disabled={disabled} onClick={onSubmit} styles={{ root: { marginRight: 10 } }}>
            Salva
        </PrimaryButton>

        <DefaultButton disabled={disabled} onClick={onDismiss}>
            Annulla
        </DefaultButton>
    </div>
);

export default SolutionFormFooter;