import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IReportCardClassNames {
    reportCardContainer: string;
    reportCardHead: string;
    reportCardHeading: string;
}

export const useClassNames = (): IReportCardClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        reportCardContainer: {
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgba(182, 178, 175, 0.25)',
            borderLeft: `4px solid ${applicationTheme.palette.themePrimary}`
        },
        reportCardHead: {
            background: applicationTheme.palette.themeLighterAlt,
            borderBottom: '1px solid rgba(182, 178, 175, 0.25)',
            boxShadow: '0 5px 15px 0 rgba(182, 178, 175, 0.25)',
            padding: '10px 0 10px 0',
            position: 'relative'
        },
        reportCardHeading: {
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '1.5rem',
            textAlign: 'center',
            textTransform: 'uppercase',
            margin: 0
        }
    });
}