import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import Select from 'react-select';
import _ from 'lodash';

import { useId } from '@fluentui/react-hooks';
import { Label, Text, Panel, PanelType, Stack, TextField } from '@fluentui/react';

import { RootState } from '../../../../../redux/application/redux-store';
import { Area } from '../../../../../api-client';

import './area-form-drawer.scss';

import AreaFormFooter from './components/AreaFormFooter/area-form-footer';

interface IAreaFormDrawer {
    disabled?: boolean;
    drawerVisible?: boolean;
    area?: Area;
    onDrawerDismiss: () => void;
    onSubmit: (area: Area, areaId?: string) => void;
}

const AreaFormDrawer: React.FunctionComponent<IAreaFormDrawer> = ({
    disabled,
    drawerVisible,
    area,
    onDrawerDismiss,
    onSubmit
}) => {
    const {
        control,
        formState: { errors },
        reset,
        handleSubmit
    } = useForm<Area>();

    const _handleSubmit: SubmitHandler<Area> = (values: Area) => onSubmit(values, values.id);

    const departmentTagsControl = useId('departmentTagsControl');

    const [departmentTags, setDepartmentTags] = useState<any[]>([]);

    useEffect(() => {
        if (area) {
            if (!!area.departments?.length) {
                let areaDepartments = _.map(area?.departments, entry => ({
                    value: `${entry.key}`,
                    label: `${entry.text}`
                }));

                setDepartmentTags(areaDepartments);
            }

            reset(area);
        }
        else {
            setDepartmentTags([]);

            reset({
                id: '',
                displayName: ''
            });
        }
    }, [area]);

    const _handleDismissForm = () => {
        setDepartmentTags([]);

        reset({
            id: '',
            displayName: ''
        });

        onDrawerDismiss();
    }

    const iaiSystem = useSelector((state: RootState) => state.system);

    return (
        <Panel
            type={PanelType.medium}
            headerText={area?.id ? 'Modifica Area' : 'Inserisci Area'}
            isOpen={drawerVisible}
            isBlocking={true}
            isFooterAtBottom={true}
            onDismiss={_handleDismissForm}
            onRenderFooterContent={() => (
                <AreaFormFooter
                    disabled={disabled}
                    onDismiss={_handleDismissForm}
                    onSubmit={handleSubmit(_handleSubmit)}
                />
            )}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <Controller
                    name="displayName"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={'Titolo:'}
                            placeholder={'Inserisci il nome...'}
                            errorMessage={errors.displayName && "Il nome é obbligatorio!"}
                            disabled={disabled}
                            required
                        />
                    )}
                />

                <Controller
                    name="departments"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <div>
                            <Label htmlFor={departmentTagsControl}>
                                Dipartimenti: *
                            </Label>
                            <Select
                                options={_.map(iaiSystem.departments, entry => ({
                                    ...entry,
                                    value: `${entry.key}`,
                                    label: `${entry.text}`
                                }))}
                                isMulti={true}
                                placeholder={"Aggiungi Dipartimenti..."}
                                value={departmentTags}
                                onChange={(elements: any) => {
                                    field.onChange(elements);

                                    setDepartmentTags(elements);
                                }}
                            />
                            <Text key={'smallPlus'} variant={'smallPlus'} nowrap block style={{ marginTop: '5px' }}>
                                Inizia a digitare il nome del Dipartimento da inserire
                            </Text>
                        </div>
                    )}
                />
            </Stack>
        </Panel>
    );
}

export default AreaFormDrawer;