import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";

import { IconButton } from '@fluentui/react/lib/Button';

import { Solution } from '../../api-client';
import { SortingComparer } from '../../utils/data-comparer';
import { DateTimeEnchanter } from '../../utils/data-enchanters';
import { useSolution } from '../../common/hooks/useSolution';
import { useClassNames } from './solution-page.classNames';

import { IAIApplicationLayout } from '../../common/layouts/IAIApplicationLayout';
import { IAICalloutColumn } from '../../common/components/IAIDataTable/components/IAIColumns/IAICalloutColumn';
import { IAIDateColumn } from '../../common/components/IAIDataTable/components/IAIColumns/IAIDateColumn';
import { IAITextColumn } from '../../common/components/IAIDataTable/components/IAIColumns/IAITextColumn';
import { IAIDataTable } from '../../common/components/IAIDataTable';
import { IAIDialog } from '../../common/components/IAIDialog';
import SolutionFormDrawer from './components/SolutionFormDrawer/solution-form-drawer';

const SolutionPage: React.FunctionComponent = () => {
    const {
        handleSolutions,
        solutions,
        solutionsLoading,
        handleCreateSolution,
        createSolutionLoading,
        handleRetrieveSolution,
        retrieveSolution,
        retrieveSolutionLoading,
        handleUpdateSolution,
        updateSolutionLoading,
        handleDeleteSolution,
        deleteSolutionLoading
    } = useSolution();

    const {
        solutionPageContainer,
        calloutSecondaryHeading,
        calloutText,
        tableCell
    } = useClassNames();

    useEffect(() => {
        handleSolutions();
    }, []);

    const _handleSearchSolution = (entry: Solution, searchValue: string): boolean => (
        entry.title?.toLowerCase().search(searchValue) !== -1
    );

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [delegatedSolution, setDelegatedSolution] = useState<Solution>();

    const _handleSubmit = (solution: Solution, solutionId?: string) => {
        const onUpsertSuccess = () => {
            handleSolutions();

            setDrawerVisible(false);
            setDelegatedSolution(undefined);
        };

        !solutionId
            ?
            handleCreateSolution({
                solution: solution,
                onSuccess: onUpsertSuccess
            })
            :
            handleUpdateSolution({
                id: solutionId,
                solution: solution,
                onSuccess: onUpsertSuccess
            });
    }

    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);

    return (
        <IAIApplicationLayout
            icon={'Lightbulb'}
            heading={'FAQ'}
            secondaryHeading={'Sfoglia le risposte rapide.'}
        >
            <div className={solutionPageContainer}>
                <IAIDataTable
                    columns={[
                        {
                            key: 'solution_preview__',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: any) => (
                                <IAICalloutColumn
                                    title={element.title}
                                    tooltipContent={'Anteprima risposta rapida'}
                                    contentLoading={retrieveSolutionLoading}
                                    content={
                                        retrieveSolution &&
                                        <div className="col-12">
                                            <span className={calloutSecondaryHeading}>
                                                {`Ultimo aggiornamento: ${DateTimeEnchanter.formatStandardDate(retrieveSolution.createdAt, 'it-IT')}`}
                                            </span>
                                            <span className={calloutText}>
                                                {parse(retrieveSolution.description || '')}
                                            </span>
                                        </div>
                                    }
                                    onEngage={() => handleRetrieveSolution({
                                        id: element.id,
                                        onRetrieveSuccess: (solution) => setDelegatedSolution(solution)
                                    })}
                                    onDismiss={() => setDelegatedSolution(undefined)}
                                />
                            )
                        },
                        {
                            key: 'solution_title__',
                            fieldName: 'title',
                            name: 'Titolo',
                            className: tableCell,
                            minWidth: 250,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer
                        },
                        {
                            key: 'solution_department__',
                            fieldName: 'department',
                            name: 'Dipartimento',
                            className: tableCell,
                            minWidth: 250,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer,
                            onRender: (element: Solution) => <IAITextColumn text={element.department?.displayName} />
                        },
                        {
                            key: 'solution_date__',
                            fieldName: 'createdAt',
                            name: 'Data inserimento',
                            className: tableCell,
                            minWidth: 120,
                            maxWidth: 120,
                            sorter: SortingComparer.dateComparer,
                            onRender: (element: Solution) => <IAIDateColumn dateTime={element.createdAt} />
                        },
                        {
                            key: 'solution_action__',
                            fieldName: '',
                            name: 'Dettagli',
                            minWidth: 75,
                            maxWidth: 75,
                            onRender: (element: any, dataIndex?: number) => (
                                <div className="text-center">
                                    <IconButton
                                        className={''}
                                        menuIconProps={{ iconName: 'CollapseMenu' }}
                                        menuProps={{
                                            items: [
                                                {
                                                    key: `${dataIndex}_menu_update__`,
                                                    iconProps: { iconName: 'EditCreate' },
                                                    text: 'Modifica',
                                                    onClick: () => handleRetrieveSolution({
                                                        id: element.id,
                                                        onRetrieveSuccess: (solution) => {
                                                            setDelegatedSolution(solution);
                                                            setDrawerVisible(true);
                                                        }
                                                    })
                                                },
                                                {
                                                    key: `${dataIndex}_menu_delete__`,
                                                    iconProps: { iconName: 'Delete' },
                                                    text: 'Elimina',
                                                    onClick: () => {
                                                        setDelegatedSolution(element);
                                                        setDeleteDialogVisible(true);
                                                    }
                                                }
                                            ]
                                        }}
                                    />
                                </div>
                            )
                        }
                    ]}
                    dataSource={solutions || []}
                    dataLoading={solutionsLoading}
                    dataTableCommands={[{
                        disabled: solutionsLoading,
                        text: 'Nuova risposta rapida',
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            setDelegatedSolution(undefined)
                            setDrawerVisible(true)
                        }
                    }]}
                    emptyContentLabel="Nessuna richiesta disponibile"
                    onItemInvoked={(element) => handleRetrieveSolution({
                        id: element.id,
                        onRetrieveSuccess: (solution) => {
                            setDelegatedSolution(solution);
                            setDrawerVisible(true);
                        }
                    })}
                    searchable
                    onSearch={_handleSearchSolution}
                />

                <IAIDialog
                    dialogProps={{
                        hidden: !deleteDialogVisible,
                        onDismissed: () => setDelegatedSolution(undefined)
                    }}
                    title={'Eliminazione risposta rapida'}
                    subText={`Stai per eliminare la risposta rapida "${delegatedSolution?.title}". Vuoi continuare?`}
                    disabled={deleteSolutionLoading || retrieveSolutionLoading}
                    confirmText={'Elimina'}
                    dismissText={'Annulla'}
                    onConfirm={() => {
                        if (delegatedSolution?.id) {
                            handleDeleteSolution({
                                id: delegatedSolution.id,
                                onSuccess: () => {
                                    setDeleteDialogVisible(false);
                                    handleSolutions();
                                }
                            });
                        }
                    }}
                    onDismiss={() => setDeleteDialogVisible(false)}
                />

                <SolutionFormDrawer
                    disabled={retrieveSolutionLoading || createSolutionLoading || updateSolutionLoading}
                    drawerVisible={drawerVisible}
                    solution={delegatedSolution}
                    onDrawerDismiss={() => {
                        setDrawerVisible(false);
                        setDelegatedSolution(undefined);
                    }}
                    onSubmit={_handleSubmit}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default SolutionPage;