import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Announcement, Agent, Area, Department } from '../../../api-client';
import { SessionStorageHelper } from '../../../utils/storage';

// Define a type for the slice state
interface SystemState {
    agents: Agent[];
    agentsInitialized?: boolean;
    areas: Area[];
    areasInitialized?: boolean;
    departments: Department[];
    departmentsInitialized?: boolean;
    notices: Announcement[];
}

// Define the initial state using that type
const initialState: SystemState = {
    agents: [],
    agentsInitialized: false,
    areas: [],
    areasInitialized: false,
    departments: [],
    departmentsInitialized: false,
    notices: []
};

export const systemSlice = createSlice({
    name: 'system',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        initAgents: (state, action: PayloadAction<Agent[]>) => {
            state.agents = action.payload;
            state.agentsInitialized = true;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AGENT, action.payload);
            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AGENT_INIT, true);
        },
        initAreas: (state, action: PayloadAction<Area[]>) => {
            state.areas = action.payload;
            state.areasInitialized = true;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AREA, action.payload);
            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AREA_INIT, true);
        },
        initDepartments: (state, action: PayloadAction<Department[]>) => {
            state.departments = action.payload;
            state.departmentsInitialized = true;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_DEPARTMENT, action.payload);
            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_DEPARTMENT_INIT, true);
        },
        initNotices: (state, action: PayloadAction<Announcement[]>) => {
            state.notices = action.payload;

            // SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_NOTICES, action.payload);
        }
    }
});

export const { initAgents, initAreas, initDepartments, initNotices } = systemSlice.actions;

export default systemSlice.reducer;