import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IIAISidenavClassNames {
    iaiSidenavItem: string;
}

export const useClassNames = (): IIAISidenavClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        iaiSidenavItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: applicationTheme.palette.neutralLight,
            borderRadius: 8,
            color: applicationTheme.semanticColors.inputText,
            cursor: 'pointer',
            fontSize: '1.35rem',
            width: 55,
            height: 55,
            textDecoration: 'none',
            transition: 'all 0.3s cubic-bezier(0.75, 0, 0.175, 1)',
            userSelect: 'none',
            position: 'relative',
            selector: {
                '&:hover': {
                    background: applicationTheme.palette.themeLighter,
                    color: applicationTheme.palette.themePrimary
                },
                '&.isActive': {
                    background: applicationTheme.palette.themeLighter,
                    color: applicationTheme.palette.themePrimary
                }
            }
        }
    });
}