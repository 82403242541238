import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface ITicketPageClassNames {
    pageContainer: string;
    tableCell: string;
}

export const useClassNames = (): ITicketPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        pageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        },
        tableCell: {
            alignSelf: 'center'
        },
    });
}