import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IIAIDataTableCommandBarClassNames {
    commandBarContainer: string;
    commandBarFill: string;
}

export const useClassNames = (): IIAIDataTableCommandBarClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        commandBarContainer: {
            background: applicationTheme.semanticColors.bodyBackground,
            marginTop: 25
        },
        commandBarFill: {
            flex: '1 1 auto'
        }
    });
}