import React, { useState } from 'react';
import _ from 'lodash';

import { ActivityItem, Stack } from '@fluentui/react';

import { TicketChangeLog } from '../../../../../../../api-client';
import { DateTimeEnchanter } from '../../../../../../../utils/data-enchanters';

import { IAIEmptyContent } from '../../../../../../../common/components/IAIEmptyContent';

interface IChangeLogsAccordion {
    dataLoading?: boolean;
    changeLogs?: TicketChangeLog[];
};

const ChangeLogsAccordion: React.FunctionComponent<IChangeLogsAccordion> = ({
    dataLoading,
    changeLogs
}) => {
    const [accordionVisible, setAccordionVisible] = useState<boolean>(false);

    const mapObj: any = {
        Level01Enum: 'Livello 0',
        Level02Enum: 'Livello 1',
        Level03Enum: 'Livello 2',
        OpenEnum: 'Aperto',
        PendingEnum: 'Preso in carico',
        WorkingEnum: 'In lavorazione',
        ClosedEnum: 'Risolto',
        LowEnum: 'Bassa',
        MediumEnum: 'Media',
        HighEnum: 'Alta'
    };

    const _handleChangesRefined = (changeLog: TicketChangeLog) => {
        let changeLogRefined = changeLog?.change || '';

        _.each(Object.keys(mapObj), entry => {
            changeLogRefined = changeLogRefined.replace(entry, mapObj[entry])
        });

        return changeLogRefined;
    }

    const _handleInitialsFromName = (displayName?: string) => {
        if (!displayName) {
            return 'OP';
        }

        let initials = displayName.split(' ');

        return ((initials.shift()?.[0] || '') + (initials.pop()?.[0] || '')).toUpperCase();
    }

    return (
        <div className="accordion" id="accordionExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                    <button
                        onClick={() => setAccordionVisible(!accordionVisible)}
                        className={`accordion-button ${!accordionVisible && 'collapsed'}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                    >
                        Monitoraggio Ticket
                    </button>
                </h2>
                <div id="collapseOne" className={`accordion-collapse collapse ${!!accordionVisible && 'show'}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <Stack tokens={{ childrenGap: 15 }}>
                            {_.map(changeLogs, (dataItem, dataIndex) => (
                                <ActivityItem
                                    key={dataIndex}
                                    activityDescription={(
                                        <React.Fragment>
                                            <span key={0}><strong>{dataItem?.userInfo?.displayName}</strong> </span>
                                            <span key={1}>{dataItem?.field} </span>
                                            <span key={2}>{_handleChangesRefined(dataItem)}</span>
                                        </React.Fragment>
                                    )}
                                    activityPersonas={[
                                        {
                                            imageInitials: _handleInitialsFromName(dataItem?.userInfo?.displayName),
                                            text: dataItem?.userInfo?.displayName,
                                            imageAlt: dataItem?.userInfo?.displayName,
                                            imageShouldFadeIn: true
                                        }
                                    ]}
                                    timeStamp={DateTimeEnchanter.formatCustomDate(dataItem?.createdAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}
                                />
                            ))}

                            {!dataLoading && !changeLogs?.length && (
                                <IAIEmptyContent title="Nessun dato disponibile" />
                            )}
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeLogsAccordion;