import React from 'react';
import ReactQuill from 'react-quill';

import { useClassNames } from './iai-text-editor.classNames';

import 'react-quill/dist/quill.snow.css';
import './iai-text-editor.scss';

interface IIAITextEditor {
    label?: string;
    description?: string;
    disabled?: boolean;
    errorMessage?: string | JSX.Element;
    required?: boolean;
    small?: boolean;
    textEditorProps?: any;
}

const IAITextEditor: React.FunctionComponent<IIAITextEditor> = ({ label, description, disabled, errorMessage, required, small, textEditorProps }) => {
    const {
        iaiControlContainer,
        iaiControlTitle,
        iaiControlDescription,
        iaiControlEditor,
        iaiControlSmallEditor,
        iaiControlError
    } = useClassNames({ required, errorMessage });

    return (
        <div className={`iai-text-editor-container ${iaiControlContainer}`}>
            <div style={{ padding: 0 }}>
                <label className={iaiControlTitle}>
                    {label}
                </label>
                <div className="p-0">
                    <ReactQuill
                        {...textEditorProps}
                        className={small
                            ? iaiControlSmallEditor
                            : iaiControlEditor
                        }
                        readOnly={disabled}
                    />
                </div>
            </div>
            <span className={iaiControlDescription}>
                {description}
            </span>
            {errorMessage &&
                <span>
                    <div role="alert">
                        <p className={`ms-TextField-errorMessage ${iaiControlError}`}>
                            <span data-automation-id="error-message">
                                {errorMessage}
                            </span>
                        </p>
                    </div>
                </span>
            }
        </div>
    );
}

export default IAITextEditor;