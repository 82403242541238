import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IAreaPageClassNames {
    areaPageContainer: string;
    calloutSecondaryHeading: string;
    calloutText: string;
    tableCell: string;
}

export const useClassNames = (): IAreaPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        areaPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        },
        calloutSecondaryHeading: {
            fontSize: '0.875rem',
            fontStyle: 'italic',
            fontWeight: 400,
            lineHeight: '1.5',
            display: 'block',
            marginBottom: 10
        },
        calloutText: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.5',
            display: 'block'
        },
        tableCell: {
            alignSelf: 'center'
        },
    });
}