import React from 'react';

import { useMsal } from '@azure/msal-react';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Icon } from '@fluentui/react/lib/Icon';

import { useClassNames } from './iai-logout-item.classNames';

const IAILogoutItem: React.FunctionComponent = () => {
    const {
        instance
    } = useMsal();

    const {
        iaiSidenavItem
    } = useClassNames();

    const _handleLogout = () => instance.logoutRedirect({ postLogoutRedirectUri: '/' })

    return (
        <TooltipHost id={`th_${'logout'}__`} content={'Logout'}>
            <div className={`${iaiSidenavItem}`} onClick={_handleLogout}>
                <Icon iconName={'SignOut'} />
            </div>
        </TooltipHost>
    );
}

export default IAILogoutItem;
