import * as React from 'react';

import { Separator } from '@fluentui/react';

import { useClassNames } from './iai-application-layout.classNames';

import './iai-application-layout.scss';

import IAILayoutHeading from './components/IAILayoutHeading/iai-layout-heading';
import IAISidenav from './components/IAISidenav/iai-sidenav';

interface IIAIApplicationLayout {
    icon?: string;
    heading?: string;
    secondaryHeading?: string;
}

const IAIApplicationLayout: React.FunctionComponent<IIAIApplicationLayout> = ({
    icon,
    heading,
    secondaryHeading,
    children
}) => {
    const {
        applicationLayoutContainer
    } = useClassNames();

    return (
        <div className={`application-layout-container ${applicationLayoutContainer}`}>
            <div className={`application-layout-sider`}>
                <IAISidenav />
            </div>
            <div className={`application-layout-child-container`}>
                <div className="row">
                    <div className="col-12">
                        <IAILayoutHeading
                            icon={icon}
                            heading={heading}
                            secondaryHeading={secondaryHeading}
                        />
                    </div>
                </div>

                <Separator className="mt-3" />

                {children}
            </div>
        </div>
    );
}

export default IAIApplicationLayout;