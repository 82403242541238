import React from 'react';

import { TooltipHost } from '@fluentui/react/lib/Tooltip';

import { DateTimeEnchanter } from '../../../../../../utils/data-enchanters';

interface IIAIDateColumn {
    dateTime?: string;
    showTime?: boolean;
}

const IAIDateColumn: React.FunctionComponent<IIAIDateColumn> = ({ dateTime, showTime }) => {
    const formattedDateTime = showTime
        ? DateTimeEnchanter.formatCustomDate(dateTime, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })
        : DateTimeEnchanter.formatStandardDate(dateTime, 'it-IT');

    return (
        <div className="text-left">
            <TooltipHost content={formattedDateTime}>
                {formattedDateTime}
            </TooltipHost>
        </div>
    );
}

export default IAIDateColumn;