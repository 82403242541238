import React from 'react';

import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';

import { IShimmer } from '../../../../models';

import './iai-widget-shimmer.scss';

const IAIWidgetShimmer: React.FunctionComponent<IShimmer> = ({ style }) => {
    const _handleShimmerElements = (): JSX.Element => (
        <div className={`iai-widget-shimmer-container`}>
            <div className={`iai-widget-shimmer-head`}>
                <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
            </div>

            <div className="iai-widget-shimmer-body">
                <span className={`iai-widget-shimmer-description my-5`}>
                    <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 125, width: '100%' }]} />
                </span>

                <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 16 }]} />
            </div>
        </div>
    );

    return (
        <div className="iai-callout-shimmer-container" style={style}>
            <Shimmer customElementsGroup={_handleShimmerElements()} width="100%" />
        </div>
    );
}

export default IAIWidgetShimmer;