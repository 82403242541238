import React, { useEffect } from 'react';
import _ from 'lodash';

import { TicketComment } from '../../../../../api-client';
import { useTicketViewChat } from './hooks/useTicketViewChat';

import { IAIWebchat } from '../../../../../common/components/IAIWebchat';

interface ITicketViewChat {
    disabled?: boolean;
    ticketId?: string;
}

const TicketViewChat: React.FunctionComponent<ITicketViewChat> = ({ disabled, ticketId }) => {
    const {
        handleCreateTicketComment,
        createTicketCommentLoading,
        handleRetrieveTicketComments,
        ticketComments,
        ticketCommentsLoading
    } = useTicketViewChat();

    useEffect(() => {
        ticketId && handleRetrieveTicketComments({ id: ticketId });
    }, [ticketId]);

    const _handleSubmit = (comment: TicketComment) => {
        ticketId && handleCreateTicketComment({
            id: ticketId,
            ticketComment: comment,
            onSuccess: () => handleRetrieveTicketComments({ id: ticketId })
        })
    }

    return (
        <IAIWebchat
            dataSource={ticketComments}
            disabled={disabled}
            loading={ticketCommentsLoading || createTicketCommentLoading}
            onSendMessage={_handleSubmit}
        />
    );
}

export default TicketViewChat;