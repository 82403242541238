import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useMsal } from "@azure/msal-react";

import { AnnouncementApiClient, AreaApiClient, SystemApiClient } from '../../providers/api-provider';
import { initAgents, initAreas, initDepartments, initNotices } from '../../redux/slices/System/systemSlice';
import { NotificationHelper } from '../../utils/notification';

export const useSystem = () => {
    const {
        instance
    } = useMsal();

    const dispatch = useDispatch();

    const [systemAgentsLoading, setSystemAgentsLoading] = useState<boolean>(false);

    const handleSystemAgents = async () => {
        setSystemAgentsLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.getAgents()
            .then((response) => {
                dispatch(initAgents(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero degli operatori.',
                    title: 'Impossibile recuperare gli operatori!'
                });
            })
            .finally(() => {
                setSystemAgentsLoading(false);
            });
    }

    const [systemAreasLoading, setSystemAreasLoading] = useState<boolean>(false);

    const handleSystemAreas = async () => {
        setSystemAreasLoading(true);

        const clientApi = await AreaApiClient(instance);

        clientApi.getAreas()
            .then((response) => {
                dispatch(initAreas(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero delle aree.',
                    title: 'Impossibile recuperare le aree!'
                });
            })
            .finally(() => {
                setSystemAreasLoading(false);
            });
    }

    const [systemDepartmentsLoading, setSystemDepartmentsLoading] = useState<boolean>(false);

    const handleSystemDepartments = async () => {
        setSystemDepartmentsLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.getDepartments()
            .then((response) => {
                dispatch(initDepartments(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero dei dipartimenti.',
                    title: 'Impossibile recuperare i dipartimenti!'
                });
            })
            .finally(() => {
                setSystemDepartmentsLoading(false);
            });
    }

    const [systemNoticesLoading, setSystemNoticesLoading] = useState<boolean>(false);

    const handleSystemNotices = async () => {
        setSystemNoticesLoading(true);

        const clientApi = await AnnouncementApiClient(instance);

        clientApi.getAnnouncements()
            .then((response) => {
                dispatch(initNotices(response.data));
            })
            .catch((reason) => {
                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero degli avvisi.',
                    title: 'Impossibile recuperare gli avvisi!'
                });
            })
            .finally(() => {
                setSystemNoticesLoading(false);
            });
    }

    return {
        handleSystemAgents,
        systemAgentsLoading,
        handleSystemAreas,
        systemAreasLoading,
        handleSystemDepartments,
        systemDepartmentsLoading,
        handleSystemNotices,
        systemNoticesLoading
    }
}