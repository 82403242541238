import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';

import { MessageBar, MessageBarType, Separator, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

import { TicketReopen } from '../../../api-client';
import { DataEnchanter, DateTimeEnchanter } from '../../../utils/data-enchanters';
import { useTicketView } from './hooks/useTicketView';

import './ticket-view-page.scss';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIShimmer } from '../../../common/components/IAIShimmer';
import TicketViewForm from './components/TicketViewForm/ticket-view-form';
import TicketViewChat from './components/TicketViewChat/ticket-view-chat';
import TicketViewReopen from './components/TicketViewReopen/ticket-view-reopen';

const TicketViewPage: React.FunctionComponent = () => {
    const {
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading,
        handleReopenTicket,
        reopenTicketLoading
    } = useTicketView();

    const routeParams = useParams();

    useEffect(() => {
        routeParams.ticketId && handleRetrieveTicket({
            id: routeParams.ticketId,
            onRetrieveSuccess: () => { }
        });
    }, [routeParams.ticketId]);

    const [reopenDialogVisible, { toggle: toggleReopenDialogVisible }] = useBoolean(false);

    const _handleReopenAction = () => toggleReopenDialogVisible();

    const _handleDismissReopenAction = () => toggleReopenDialogVisible();

    const _handleReopenEntity = (message: TicketReopen) => {
        retrieveTicket &&
            handleReopenTicket({
                id: retrieveTicket.id!,
                reopenRequest: {
                    ticketId: retrieveTicket.id!,
                    message: message.message,
                    requestedBy: retrieveTicket.requestedBy
                },
                onSuccess: () => {
                    _handleDismissReopenAction();

                    handleRetrieveTicket({
                        id: retrieveTicket.id!,
                        onRetrieveSuccess: () => { }
                    });
                }
            });
    }

    return (
        <IAIApplicationLayout
            icon={'Ticket'}
            heading={`Ticket #${routeParams.ticketId}`}
            secondaryHeading={'Visualizza i dettagli del ticket.'}
        >
            <div className="ticket-view-page-container">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <IAIShimmer type={'shimmer-heading'} loading={!retrieveTicket || retrieveTicketLoading}>
                            <span className="ticket-details-data ticket-details-heading">
                                {`Richiedente: ${retrieveTicket?.requestedBy}`}
                            </span>
                            <span className="ticket-details-data">
                                {`Creata il: ${DateTimeEnchanter.formatCustomDate(retrieveTicket?.createdAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}`}
                            </span>
                        </IAIShimmer>
                    </div>
                    <div className="col-12 col-md-5 text-end">
                        <IAIShimmer type={'shimmer-heading'} loading={!retrieveTicket || retrieveTicketLoading}>
                            <span className="ticket-details-data ticket-details-heading">
                                {`Stato: ${DataEnchanter.textByStatusEnum(retrieveTicket?.status || '')}`}
                            </span>

                            {retrieveTicket?.status === 'closed'
                                ?
                                <span className="ticket-details-data">
                                    {`Risolto il: ${DateTimeEnchanter.formatCustomDate(retrieveTicket?.closedAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}`}
                                </span>
                                :
                                retrieveTicket?.reopenAt && (
                                    <span className="ticket-details-data">
                                        {`Riaperto il: ${DateTimeEnchanter.formatCustomDate(retrieveTicket?.reopenAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}`}
                                    </span>
                                )
                            }
                        </IAIShimmer>

                        {retrieveTicket?.status === 'closed' &&
                            (!retrieveTicket.reopenCount || retrieveTicket.reopenCount < 2) &&
                            (moment(retrieveTicket.createdAt) > moment().subtract(3, 'd')) && (
                                <PrimaryButton
                                    className="mt-3"
                                    disabled={retrieveTicketLoading || reopenTicketLoading}
                                    text="Riapri il Ticket"
                                    onClick={_handleReopenAction}
                                />
                            )
                        }
                    </div>

                    <Separator className="my-3" />

                    {retrieveTicket?.status === 'closed' && (
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            isMultiline={false}
                            styles={{ root: { marginBottom: '25px' } }}
                        >
                            {'Il ticket é stato risolto, per inviare ulteriori messaggi, riaprire o creare un nuovo ticket.'}
                        </MessageBar>
                    )}

                    <div className="col-12 col-md-7">
                        <TicketViewForm ticket={retrieveTicket} />
                    </div>
                    <div className="col-12 col-md-5">
                        <TicketViewChat
                            disabled={retrieveTicket?.status === 'closed'}
                            ticketId={routeParams.ticketId}
                        />
                    </div>
                </div>

                <TicketViewReopen
                    disabled={!retrieveTicket || retrieveTicketLoading || reopenTicketLoading}
                    dialogVisible={reopenDialogVisible}
                    onDialogConfirm={_handleReopenEntity}
                    onDialogDismiss={_handleDismissReopenAction}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default TicketViewPage;