import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useId } from '@fluentui/react-hooks';
import _ from 'lodash';

import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';

import { EventReceiver } from '../../../../../api-client';

import { IAITextEditor } from '../../../../../common/components/IAIControls/IAITextEditor';
import EventReceiverFormFooter from './components/EventReceiversFormFooter/event-receivers-form-footer';
import { Dropdown, KeyCodes, Label, Text } from '@fluentui/react';

import CreatableSelect from 'react-select/creatable';

interface IEventReceiverFormDrawer {
    disabled?: boolean;
    drawerVisible?: boolean;
    eventReceiver?: EventReceiver;
    onDrawerDismiss: () => void;
    onSubmit: (eventReceiver: EventReceiver, eventReceiverId?: string) => void;
}

const EventReceiverFormDrawer: React.FunctionComponent<IEventReceiverFormDrawer> = ({ disabled, drawerVisible, eventReceiver, onDrawerDismiss, onSubmit }) => {
    const {
        control,
        formState: { errors },
        reset,
        handleSubmit,
        setValue
    } = useForm<EventReceiver>();

    const fixEventsCollectionToSaving = (collection: any) => {
        let collectionFixed: any = [];
        if(collection && collection.length > 0) {
            collection.forEach((tag: any) => {
                let obj = {
                    value: tag && tag.value ? tag.value : tag,
                    label: tag && tag.value ? tag.value : tag
                }
                collectionFixed.push(obj)
            });
        }
        return collectionFixed;
    }

    const _handleSubmit: SubmitHandler<EventReceiver> = (values: EventReceiver) => {
        // in ADD la struttura è    -> [{label: 'ggg', value: 'ggg'}]
        // in EDIT la struttura è   -> ['aaa', 'sss']
        values.onCreated    = fixEventsCollectionToSaving(values.onCreated);
        values.onCreating   = fixEventsCollectionToSaving(values.onCreating);
        values.onDeleted    = fixEventsCollectionToSaving(values.onDeleted);
        values.onDeleting   = fixEventsCollectionToSaving(values.onDeleting);
        values.onUpdated    = fixEventsCollectionToSaving(values.onUpdated);
        values.onUpdating   = fixEventsCollectionToSaving(values.onUpdating);

        // RESET 
        setTagsOnCreated([]);
        setTagsOnCreating([]);
        setTagsOnDeleted([]);
        setTagsOnDeleting([]);
        setTagsOnUpdated([]);
        setTagsOnUpdating([]);

        // SAVING
        onSubmit(values, values.id);
    }
    const [insertMessage, setInsertMessage] = useState<string>('Inizia a scrivere il valore da aggiugere')

    const [tagsOnCreated, setTagsOnCreated] = useState<any[]>([])
    const [tagsOnCreating, setTagsOnCreating] = useState<any[]>([])
    const [tagsOnDeleted, setTagsOnDeleted] = useState<any[]>([])
    const [tagsOnDeleting, setTagsOnDeleting] = useState<any[]>([])
    const [tagsOnUpdated, setTagsOnUpdated] = useState<any[]>([])
    const [tagsOnUpdating, setTagsOnUpdating] = useState<any[]>([])

    useEffect(() => {
        // onCreated
        if(eventReceiver && eventReceiver.onCreated && eventReceiver.onCreated.length > 0) {
            let collection: any = [];
            eventReceiver.onCreated.forEach((elem: any) => {
                // fix for REACT SELECT (Select: value | label)
                let obj = {
                    value: elem,
                    label: elem
                }
                collection.push(obj);
            });
            setTagsOnCreated(collection);
        }
        // onCreating
        if(eventReceiver && eventReceiver.onCreating && eventReceiver.onCreating.length > 0) {
            let collection: any = [];
            eventReceiver.onCreating.forEach((elem: any) => {
                // fix for REACT SELECT (Select: value | label)
                let obj = {
                    value: elem,
                    label: elem
                }
                collection.push(obj);
            });
            setTagsOnCreating(collection);
        }
        // onDeleted
        if(eventReceiver && eventReceiver.onDeleted && eventReceiver.onDeleted.length > 0) {
            let collection: any = [];
            eventReceiver.onDeleted.forEach((elem: any) => {
                // fix for REACT SELECT (Select: value | label)
                let obj = {
                    value: elem,
                    label: elem
                }
                collection.push(obj);
            });
            setTagsOnDeleted(collection);
        }
        // onDeleting
        if(eventReceiver && eventReceiver.onDeleting && eventReceiver.onDeleting.length > 0) {
            let collection: any = [];
            eventReceiver.onDeleting.forEach((elem: any) => {
                // fix for REACT SELECT (Select: value | label)
                let obj = {
                    value: elem,
                    label: elem
                }
                collection.push(obj);
            });
            setTagsOnDeleting(collection);
        }
        // onUpdated
        if(eventReceiver && eventReceiver.onUpdated && eventReceiver.onUpdated.length > 0) {
            let collection: any = [];
            eventReceiver.onUpdated.forEach((elem: any) => {
                // fix for REACT SELECT (Select: value | label)
                let obj = {
                    value: elem,
                    label: elem
                }
                collection.push(obj);
            });
            setTagsOnUpdated(collection);
        }
        // onUpdating
        if(eventReceiver && eventReceiver.onUpdating && eventReceiver.onUpdating.length > 0) {
            let collection: any = [];
            eventReceiver.onUpdating.forEach((elem: any) => {
                // fix for REACT SELECT (Select: value | label)
                let obj = {
                    value: elem,
                    label: elem
                }
                collection.push(obj);
            });
            setTagsOnUpdating(collection);
        }

        reset(eventReceiver);
    }, [eventReceiver]);

    const _handleDismissForm = () => {
        onDrawerDismiss();
    }

    return (
        <Panel
            customWidth={'750px'}
            headerText={eventReceiver && eventReceiver.id ? 'Modifica EventReceiver' : 'Inserisci EventReceiver'}
            type={PanelType.custom}
            isFooterAtBottom={true}
            isOpen={drawerVisible}
            layerProps={{ styles: { root: { zIndex: 0 } } }}
            overlayProps={{ styles: { root: { zIndex: 0 } } }}
            onDismiss={_handleDismissForm}
            onRenderFooterContent={() => (
                <EventReceiverFormFooter
                    disabled={disabled}
                    onDismiss={_handleDismissForm}
                    onSubmit={handleSubmit(_handleSubmit)}
                />
            )}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <Controller
                    name="displayName"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={'Titolo:'}
                            placeholder={'Inserisci il nome...'}
                            errorMessage={errors.displayName && "Il nome é obbligatorio!"}
                            disabled={disabled}
                            required
                        />
                    )}
                />

                <Controller
                    name="onCreated"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: false }}
                    render={({ field }) => (
                        <div>
                            <Label>onCreated:</Label>
                            <CreatableSelect
                                key={'onCreated'}
                                isMulti
                                // options={options}
                                defaultValue={tagsOnCreated}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                noOptionsMessage={() => insertMessage}
                                onChange={(tagsCollection) => {
                                    field.onChange(tagsCollection);
                                }}
                                isDisabled={disabled}
                            />
                        </div>
                    )}
                />

                <Controller
                    name="onCreating"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: false }}
                    render={({ field }) => (
                        <div>
                            <Label>onCreating:</Label>
                            <CreatableSelect
                                key={'onCreating'}
                                isMulti
                                defaultValue={tagsOnCreating}
                                //options={field.value}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                noOptionsMessage={() => insertMessage}
                                onChange={(tagsCollection) => {
                                    field.onChange(tagsCollection);
                                }}
                                isDisabled={disabled}
                            />
                        </div>
                    )}
                />

                <Controller
                    name="onDeleted"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: false }}
                    render={({ field }) => (
                        <div>
                            <Label>onDeleted:</Label>
                            <CreatableSelect
                                key={'onDeleted'}
                                isMulti
                                defaultValue={tagsOnDeleted}
                                //options={field.value}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                noOptionsMessage={() => insertMessage}
                                onChange={(tagsCollection) => {
                                    field.onChange(tagsCollection);
                                }}
                                isDisabled={disabled}
                            />
                        </div>
                    )}
                />

                <Controller
                    name="onDeleting"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: false }}
                    render={({ field }) => (
                        <div>
                            <Label>onDeleting:</Label>
                            <CreatableSelect
                                key={'onDeleting'}
                                isMulti
                                defaultValue={tagsOnDeleting}
                                //options={field.value}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                noOptionsMessage={() => insertMessage}
                                onChange={(tagsCollection) => {
                                    field.onChange(tagsCollection);
                                }}
                                isDisabled={disabled}
                            />
                        </div>
                    )}
                />

                <Controller
                    name="onUpdated"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: false }}
                    render={({ field }) => (
                        <div>
                            <Label>onUpdated:</Label>
                            <CreatableSelect
                                key={'onUpdated'}
                                isMulti
                                defaultValue={tagsOnUpdated}
                                //options={field.value}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                noOptionsMessage={() => insertMessage}
                                onChange={(tagsCollection) => {
                                    field.onChange(tagsCollection);
                                }}
                                isDisabled={disabled}
                            />
                        </div>
                    )}
                />

                <Controller
                    name="onUpdating"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: false }}
                    render={({ field }) => (
                        <div>
                            <Label>onUpdating:</Label>
                            <CreatableSelect
                                key={'onUpdating'}
                                isMulti
                                defaultValue={tagsOnUpdating}
                                //options={field.value}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                noOptionsMessage={() => insertMessage}
                                onChange={(tagsCollection) => {
                                    field.onChange(tagsCollection);
                                }}
                                isDisabled={disabled}
                            />
                        </div>
                    )}
                />
            </Stack>
        </Panel>
    );
}

export default EventReceiverFormDrawer;