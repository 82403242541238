import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { RootState } from '../../../redux/application/redux-store';

type IAIRoleGuardProps = {
    roles: string[];
    children: ReactNode | string;
};

export default function IAIRoleGuard({ roles, children }: IAIRoleGuardProps) {
    const iaiAccountInfo = useSelector((state: RootState) => state.account);

    if (iaiAccountInfo.accountInitialized) {
        const accountIsInRole = _.some(roles, entry => entry === iaiAccountInfo.role);

        if (!accountIsInRole) {
            return <Navigate to={'/'} replace />;
        }
    }

    return <>{children}</>;
}