import React, { useEffect } from 'react';

import { loadTheme } from '@fluentui/react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';

import { ApplicationSettings } from '../../../utils/application';
import { SessionStorageHelper } from '../../../utils/storage';
import { useAuthContext } from '../../../contexts/auth-context';
import { useSystem } from '../../hooks/useSystem';

import './application-template.scss';

import { IAIToastContainer } from '../../components/IAIToastContainer';

const ApplicationTemplate: React.FunctionComponent = (props) => {
    const {
        isAuthenticated
    } = useAuthContext();

    const {
        handleSystemAgents,
        handleSystemAreas,
        handleSystemDepartments,
        handleSystemNotices
    } = useSystem();

    loadTheme(ApplicationSettings.IAI_APP_THEME);

    initializeIcons();

    // let isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated) {
            handleSystemNotices();

            let agentsInit: boolean = SessionStorageHelper.retrieveItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AGENT_INIT);
            !agentsInit && handleSystemAgents();

            let areasInit: boolean = SessionStorageHelper.retrieveItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_AREA_INIT);
            !areasInit && handleSystemAreas();

            let departmentsInit: boolean = SessionStorageHelper.retrieveItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_DEPARTMENT_INIT);
            !departmentsInit && handleSystemDepartments();
        }
    }, [isAuthenticated]);

    return (
        <React.Fragment>
            {props.children}

            <IAIToastContainer />
        </React.Fragment>
    );
}

export default ApplicationTemplate;