import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Stack } from '@fluentui/react/lib/Stack';
import { IButtonStyles, DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';

import { TicketComment } from '../../../../../api-client';

import { IAITextEditor } from '../../../IAIControls/IAITextEditor';
import SolutionModal from '../SolutionModal/solution-modal';
import TeamsModal from '../TeamsModal/teams-modal';

const defaultButtonStyle: IButtonStyles = {
    root: {
        minWidth: 0,
        padding: '0 5px'
    }
}

interface IWebchatForm {
    disabled?: boolean;
    isAdmin?: boolean;
    onSendMessage: (message: TicketComment) => void;
}

const WebchatForm: React.FunctionComponent<IWebchatForm> = ({ disabled, isAdmin, onSendMessage }) => {
    const {
        control,
        formState: { errors },
        reset,
        setValue,
        handleSubmit
    } = useForm<TicketComment>();

    const _handleSubmit: SubmitHandler<TicketComment> = values => {
        onSendMessage(values);
        reset();
    }

    const [solutionModalVisible, setSolutionModalVisible] = useState<boolean>(false);
    const [meetingModalVisible, setMeetingModalVisible] = useState<boolean>(false);

    return (
        <React.Fragment>
            <Stack tokens={{ childrenGap: 15 }}>
                <Controller
                    name="message"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <IAITextEditor
                            label={'Scrivi un nuovo messaggio:'}
                            errorMessage={errors.message && "Il messaggio é obbligatorio!"}
                            disabled={disabled}
                            required
                            textEditorProps={field}
                        />
                    )}
                />

                <Stack horizontal horizontalAlign={'space-between'} tokens={{ childrenGap: 15 }}>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        {isAdmin &&
                            <TooltipHost content={'Cerca una Soluzione'}>
                                <DefaultButton
                                    disabled={disabled}
                                    iconProps={{ iconName: 'Lightbulb' }}
                                    styles={defaultButtonStyle}
                                    onClick={() => setSolutionModalVisible(true)}
                                />
                            </TooltipHost>
                        }

                        {isAdmin &&
                            <TooltipHost content={'Pianifica una riunione su Teams'}>
                                <DefaultButton
                                    disabled={disabled}
                                    iconProps={{ iconName: 'TeamsLogo' }}
                                    styles={defaultButtonStyle}
                                    onClick={() => setMeetingModalVisible(true)}
                                />
                            </TooltipHost>
                        }
                    </Stack>

                    <PrimaryButton
                        type="submit"
                        disabled={disabled}
                        text={'Invia'}
                        iconProps={{ iconName: 'Send' }}
                        styles={{ root: { alignSelf: 'end' } }}
                        onClick={handleSubmit(_handleSubmit)}
                    />
                </Stack>
            </Stack>

            {isAdmin &&
                <SolutionModal
                    visible={solutionModalVisible}
                    onSelection={(solution) => setValue('message', solution)}
                    onModalDismiss={() => setSolutionModalVisible(false)}
                />
            }
            
            {isAdmin &&
                <TeamsModal
                    visible={meetingModalVisible}
                    onCreation={(joinWebUrl) => setValue('message', joinWebUrl)}
                    onModalDismiss={() => setMeetingModalVisible(false)}
                />
            }
        </React.Fragment>
    );
}

export default WebchatForm;