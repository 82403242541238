import React, { useState } from 'react';

import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Callout } from '@fluentui/react/lib/Callout';
import { IconButton } from '@fluentui/react/lib/Button';
import { useId } from '@fluentui/react-hooks';

import { useClassNames } from './iai-callout-column.classNames';

import './iai-callout-column.scss';

import { IAIShimmer } from '../../../../IAIShimmer';

interface IIAICalloutColumn {
    content?: string | JSX.Element | JSX.Element[];
    contentLoading?: boolean;
    title?: string | JSX.Element | JSX.Element[];
    tooltipContent?: string | JSX.Element | JSX.Element[];
    onEngage?: () => void;
    onDismiss?: () => void;
}

const IAICalloutColumn: React.FunctionComponent<IIAICalloutColumn> = ({ content, contentLoading, title, tooltipContent, onEngage, onDismiss }) => {
    const {
        calloutContainerHeader,
        calloutHeading,
        calloutContainerBody
    } = useClassNames();

    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);

    const buttonId = useId('iai_callout_column__');

    const _handleEngageCallout = () => {
        setCalloutVisible(true);

        onEngage && onEngage();
    }

    const _handleDismissCallout = () => {
        setCalloutVisible(false);

        onDismiss && onDismiss();
    }

    return (
        <div className="text-center">
            <TooltipHost content={tooltipContent}>
                <IconButton id={buttonId} iconProps={{ iconName: 'ZoomIn' }} onClick={_handleEngageCallout} />
            </TooltipHost>

            {calloutVisible &&
                <Callout target={`#${buttonId}`} className="iai-callout-container" setInitialFocus onDismiss={_handleDismissCallout}>
                    <div className={`callout-container-header  ${calloutContainerHeader}`}>
                        <span className={`callout-heading  ${calloutHeading}`}>
                            {title}
                        </span>

                        <IconButton className={`callout-close-btn`} iconProps={{ iconName: 'Cancel' }} onClick={_handleDismissCallout} />
                    </div>
                    <div className={`callout-container-body ${calloutContainerBody}`}>
                        <IAIShimmer type={'shimmer-callout'} loading={!content || contentLoading}>
                            {content}
                        </IAIShimmer>
                    </div>
                </Callout>
            }
        </div>
    );
}

export default IAICalloutColumn;