import { configureStore } from '@reduxjs/toolkit';

import accountSliceReducer from '../slices/Account/accountSlice';
import systemSliceReducer from '../slices/System/systemSlice';
import ticketSliceReducer from '../slices/Ticket/ticketSlice';

export const store = configureStore({
    reducer: {
        account: accountSliceReducer,
        system: systemSliceReducer,
        ticket: ticketSliceReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch