import React from 'react';
import parse from "html-react-parser";

import { Separator } from '@fluentui/react/lib/Separator';
import { Stack } from '@fluentui/react/lib/Stack';
import { Label } from '@fluentui/react';

import { Ticket } from '../../../../../api-client';

import TicketViewAttachment from '../TicketViewAttachment/ticket-view-attachment';

interface ITicketViewForm {
    ticket?: Ticket;
}

const TicketViewForm: React.FunctionComponent<ITicketViewForm> = ({ ticket }) => (
    <div className="">
        <Stack tokens={{ childrenGap: 15 }}>
            <Stack.Item>
                <Label>{'Oggetto Richiesta:'}</Label>
                {ticket?.subject}
            </Stack.Item>
            <Stack.Item>
                <Label>{'Descrizione richiesta:'}</Label>
                {parse(ticket?.description || '')}
            </Stack.Item>
        </Stack>

        <Separator />

        <TicketViewAttachment
            ticketId={ticket?.id}
            disabled={ticket?.status === 'closed'}
        />
    </div>
);

export default TicketViewForm;