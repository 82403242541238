import React from 'react';
import { Link } from 'react-router-dom';

import { PrimaryButton } from '@fluentui/react/lib/Button';

import { useClassNames } from './report-card.classNames';

import './report-card.scss';

interface IReportCard {
    id: string;
    description?: string;
    title?: string;
    to?: string;
}

const ReportCard: React.FunctionComponent<IReportCard> = ({ description, title, to }) => {
    const {
        reportCardContainer,
        reportCardHead,
        reportCardHeading
    } = useClassNames();

    return (
        <div className={`report-card-container ${reportCardContainer}`}>
            <div className={reportCardHead}>
                {title &&
                    <h5 className={reportCardHeading}>{title}</h5>
                }
            </div>

            <div className="report-card-body">
                {description &&
                    <span className={`report-card-description my-5`}>{description}</span>
                }

                {to &&
                    <Link to={to}>
                        <PrimaryButton>
                            Visualizza
                        </PrimaryButton>
                    </Link>
                }
            </div>
        </div>
    )
}

export default ReportCard;