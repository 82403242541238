import { useState } from 'react';

import { Ticket, TicketReopen } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models/IHookCallback';
import { ExternalPublicApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';

export const useTicketView = () => {
    interface IRetrieveTicket extends IHookCallback {
        id: string;
        onRetrieveSuccess: (ticket: Ticket) => void;
    }

    const [retrieveTicket, setRetrieveTicket] = useState<Ticket>();
    const [retrieveTicketLoading, setRetrieveTicketLoading] = useState<boolean>(false);

    const handleRetrieveTicket = async (params: IRetrieveTicket) => {
        setRetrieveTicketLoading(true);

        const clientApi = await ExternalPublicApiClient();

        clientApi.getTicketByExternal(params.id)
            .then((response: any) => {
                setRetrieveTicket(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero del ticket.',
                    title: 'Impossibile recuperare il ticket!'
                });
            })
            .finally(() => {
                setRetrieveTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IReopenTicket extends IHookCallback {
        id: string;
        reopenRequest: TicketReopen;
    }

    const [reopenTicketLoading, setReopenTicketLoading] = useState<boolean>(false);

    const handleReopenTicket = async (params: IReopenTicket) => {
        setReopenTicketLoading(true);

        const clientApi = await ExternalPublicApiClient();

        clientApi.reopenTicketByExternal(params.id, params.reopenRequest)
            .then(() => {
                params.onSuccess && params.onSuccess();
            })
            .catch(() => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la riapertura del ticket.',
                    title: 'Impossibile riaprire il ticket!'
                });
            })
            .finally(() => {
                setReopenTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading,
        handleReopenTicket,
        reopenTicketLoading
    }
}