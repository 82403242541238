import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { Item, Solution } from '../../api-client';
import { IHookCallback } from '../models/IHookCallback';
import { SolutionApiClient } from '../../providers/api-provider';
import { NotificationHelper } from '../../utils/notification';

export const useSolution = () => {
    const {
        instance
    } = useMsal();

    const [solutions, setSolutions] = useState<Solution[]>();
    const [solutionsLoading, setSolutionsLoading] = useState<boolean>(false);

    const handleSolutions = async () => {
        setSolutionsLoading(true);

        const clientApi = await SolutionApiClient(instance);

        clientApi.getSolutions()
            .then((response) => {
                setSolutions(response.data);
            })
            .catch((reason) => {
                setSolutions([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero delle soluzioni.',
                    title: 'Impossibile recuperare le soluzioni!'
                });
            })
            .finally(() => {
                setSolutionsLoading(false);
            });
    }

    // CRUD Operations

    interface ICreateSolution extends IHookCallback {
        solution: Solution;
    }

    const [createSolution, setCreateSolution] = useState<Item>();
    const [createSolutionLoading, setCreateSolutionLoading] = useState<boolean>(false);

    const handleCreateSolution = async (params: ICreateSolution) => {
        setCreateSolutionLoading(true);

        const clientApi = await SolutionApiClient(instance);

        clientApi.insertSolution(params.solution)
            .then((response) => {
                setCreateSolution(response.data);

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Soluzione creata con successo.',
                    title: 'Soluzione creata!'
                });
            })
            .catch((reason) => {
                setCreateSolution(undefined);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la creazione della soluzione.',
                    title: 'Impossibile creare la soluzione!'
                });
            })
            .finally(() => {
                setCreateSolutionLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveSolution extends IHookCallback {
        id: string;
        onRetrieveSuccess: (solution: Solution) => void;
    }

    const [retrieveSolution, setRetrieveSolution] = useState<Solution>();
    const [retrieveSolutionLoading, setRetrieveSolutionLoading] = useState<boolean>(false);

    const handleRetrieveSolution = async (params: IRetrieveSolution) => {
        setRetrieveSolutionLoading(true);

        const clientApi = await SolutionApiClient(instance);

        clientApi.getSolution(params.id)
            .then((response: any) => {
                setRetrieveSolution(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero della soluzione.',
                    title: 'Impossibile recuperare la soluzione!'
                });
            })
            .finally(() => {
                setRetrieveSolutionLoading(false);
                
                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateSolution extends IHookCallback {
        id: string;
        solution: Solution;
    }

    const [updateSolutionLoading, setUpdateSolutionLoading] = useState<boolean>(false);

    const handleUpdateSolution = async (params: IUpdateSolution) => {
        setUpdateSolutionLoading(true);

        const clientApi = await SolutionApiClient(instance);

        clientApi.updateSolution(params.id, params.solution)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Soluzione aggiornata con successo.',
                    title: 'Soluzione aggiornata!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante l\'aggiornamento della soluzione.',
                    title: 'Impossibile aggiornare la soluzione!'
                });
            })
            .finally(() => {
                setUpdateSolutionLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteSolution extends IHookCallback {
        id: string;
    }

    const [deleteSolutionLoading, setDeleteSolutionLoading] = useState<boolean>(false);

    const handleDeleteSolution = async (params: IDeleteSolution) => {
        setDeleteSolutionLoading(true);

        const clientApi = await SolutionApiClient(instance);

        clientApi.deleteSolution(params.id)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Soluzione cancellata con successo.',
                    title: 'Soluzione cancellata!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la cancellazione della soluzione.',
                    title: 'Impossibile cancellare la soluzione!'
                });
            })
            .finally(() => {
                setDeleteSolutionLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleSolutions,
        solutions,
        solutionsLoading,
        // CRUD Operations
        handleCreateSolution,
        createSolution,
        createSolutionLoading,
        handleRetrieveSolution,
        retrieveSolution,
        retrieveSolutionLoading,
        handleUpdateSolution,
        updateSolutionLoading,
        handleDeleteSolution,
        deleteSolutionLoading
    }
}