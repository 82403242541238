import { IAnnouncementForm } from '../hooks/useAnnouncementForm';

export interface IAnnouncementFormValidator {
    title?: string;
    description?: string;
}

export class AnnouncementFormValidator {
    public static validateForm = (values: IAnnouncementForm): IAnnouncementFormValidator => {
        let formErrors: IAnnouncementFormValidator = {};

        if (!values.title || !values.title.toString()) {
            formErrors.title = `Il titolo dell'annuncio é obbligatorio!`;
        }

        if (!values.description || !values.description.toString()) {
            formErrors.description = `La descrizione dell'annuncio é obbligatoria!`;
        }

        return formErrors;
    }
}