import { useState } from 'react';
import _ from 'lodash';

import { useMsal } from "@azure/msal-react";

import { Ticket, TicketChangeLog, TicketFilteringCriteria } from '../../../../api-client';
import { IHookCallback } from '../../../../common/models/IHookCallback';
import { TicketApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';

export const useTicket = () => {
    const {
        instance
    } = useMsal();

    interface ITickets extends IHookCallback {
        criteria?: TicketFilteringCriteria;
    }

    const [tickets, setTickets] = useState<Ticket[]>();
    const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);

    const handleTickets = async (params: ITickets) => {
        setTicketsLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getFilteredTickets({ ...params.criteria })
            .then((response: any) => {
                var orderedTickets = _.orderBy(response.data, entry => entry.createdAt, 'desc');

                setTickets(orderedTickets);
            })
            .catch((reason) => {
                setTickets([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero dei ticket.',
                    title: 'Impossibile recuperare i ticket!'
                });
            })
            .finally(() => {
                setTicketsLoading(false);
            });
    }

    interface ITickets extends IHookCallback {
        criteria?: TicketFilteringCriteria;
    }

    const [reopenTickets, setReopenTickets] = useState<Ticket[]>();
    const [reopenTicketsLoading, setReopenTicketsLoading] = useState<boolean>(false);

    const handleReopenTickets = async (params: ITickets) => {
        setReopenTicketsLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getReopenTickets({ ...params.criteria })
            .then((response) => {
                var orderedTickets = _.orderBy(response.data, entry => entry.reopenAt, 'desc');

                setReopenTickets(orderedTickets);
            })
            .catch((reason) => {
                setReopenTickets([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero dei ticket.',
                    title: 'Impossibile recuperare i ticket!'
                });
            })
            .finally(() => {
                setReopenTicketsLoading(false);
            });
    }

    interface IPersonalTickets extends IHookCallback {
        criteria?: TicketFilteringCriteria;
    }

    const [personalTickets, setPersonalTickets] = useState<Ticket[]>();
    const [personalTicketsLoading, setPersonalTicketsLoading] = useState<boolean>(false);

    const handlePersonalTickets = async (params: IPersonalTickets) => {
        setPersonalTicketsLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getPersonalTickets(params.criteria)
            .then((response: any) => {
                var orderedTickets = _.orderBy(response.data, entry => entry.createdAt, 'desc');

                setPersonalTickets(orderedTickets);
            })
            .catch((reason) => {
                setPersonalTickets([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero dei ticket.',
                    title: 'Impossibile recuperare i ticket!'
                });
            })
            .finally(() => {
                setPersonalTicketsLoading(false);
            });
    }

    // CRUD Operations

    interface IRetrieveTicket extends IHookCallback {
        id: string;
        onRetrieveSuccess: (ticket: Ticket) => void;
    }

    const [retrieveTicket, setRetrieveTicket] = useState<Ticket>();
    const [retrieveTicketLoading, setRetrieveTicketLoading] = useState<boolean>(false);

    const handleRetrieveTicket = async (params: IRetrieveTicket) => {
        setRetrieveTicketLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getTicket(params.id)
            .then((response: any) => {
                setRetrieveTicket(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero del ticket.',
                    title: 'Impossibile recuperare il ticket!'
                });
            })
            .finally(() => {
                setRetrieveTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteTicket extends IHookCallback {
        id: string;
    }

    const [deleteTicketLoading, setDeleteTicketLoading] = useState<boolean>(false);

    const handleDeleteTicket = async (params: IDeleteTicket) => {
        setDeleteTicketLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.deleteTicket(params.id)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Ticket cancellato con successo.',
                    title: 'Ticket cancellato!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la cancellazione del ticket.',
                    title: 'Impossibile cancellare il ticket!'
                });
            })
            .finally(() => {
                setDeleteTicketLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveChangeLogs extends IHookCallback {
        id: string;
        onRetrieveSuccess: (changeLogs: TicketChangeLog[]) => void;
    }

    const [retrieveChangeLogs, setRetrieveChangeLogs] = useState<TicketChangeLog[]>();
    const [retrieveChangeLogsLoading, setRetrieveChangeLogsLoading] = useState<boolean>(false);

    const handleRetrieveChangeLogs = async (params: IRetrieveChangeLogs) => {
        setRetrieveChangeLogsLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getTicketChangeLogs(params.id)
            .then((response) => {
                setRetrieveChangeLogs(response.data);

                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveChangeLogs([]);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: `Si è verificato un errore durante il recupero dei dati di monitoraggio.`,
                    title: `Impossibile recuperare i dati di monitoraggio!`
                });
            })
            .finally(() => {
                setRetrieveChangeLogsLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleTickets,
        tickets,
        ticketsLoading,
        handlePersonalTickets,
        personalTickets,
        personalTicketsLoading,
        handleReopenTickets,
        reopenTickets,
        reopenTicketsLoading,
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading,
        handleDeleteTicket,
        deleteTicketLoading,
        handleRetrieveChangeLogs,
        retrieveChangeLogs,
        retrieveChangeLogsLoading
    }
}