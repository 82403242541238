import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { useBoolean } from '@fluentui/react-hooks';
import { IconButton, IContextualMenuItem, Text, Pivot, PivotItem } from '@fluentui/react';

import { RootState } from '../../../redux/application/redux-store';
import { Ticket } from '../../../api-client';
import { SortingComparer } from '../../../utils/data-comparer';
import { DateTimeEnchanter } from '../../../utils/data-enchanters';
import { RouterSettings } from '../../../utils/router';
import { clearCriteria, keepCriteria } from '../../../redux/slices/Ticket/ticketSlice';
import { useTicket } from './hooks/useTicket';
import { useClassNames } from './ticket-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAICalloutColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAICalloutColumn';
import { IAIDataTable } from '../../../common/components/IAIDataTable';
import { IAIDateColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAIDateColumn';
import { IAIDialog } from '../../../common/components/IAIDialog';
import { IAIStatusColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAIStatusColumn';
import { IAIPriorityColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAIPriorityColumn';
import { IAITextColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAITextColumn';
import { IAITicketsRefinementDrawer } from '../../../common/components/IAITicketsRefinementDrawer';
import { default as ChangeLogsDrawer } from './components/ChangeLogsDrawer/change-logs-drawer';

const TicketPage: React.FunctionComponent = () => {
    const {
        pageContainer,
        tableCell
    } = useClassNames();

    const {
        handlePersonalTickets,
        personalTickets,
        personalTicketsLoading,
        handleReopenTickets,
        reopenTickets,
        reopenTicketsLoading,
        handleTickets,
        tickets,
        ticketsLoading,
        handleDeleteTicket,
        deleteTicketLoading,
        handleRetrieveChangeLogs,
        retrieveChangeLogs,
        retrieveChangeLogsLoading
    } = useTicket();

    const iaiCriteria = useSelector((state: RootState) => state.ticket.criteria);

    //#region Criteria

    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();

    const _handleChangeTicketsCriteria = (name: string, value: any) => dispatch(keepCriteria({
        ...iaiCriteria,
        [name]: value
    }));

    const _handleClearTicketsCriteria = () => {
        dispatch(clearCriteria());
        handleTickets({ criteria: { offset: 0, limit: 100 } });
    }

    const _handleFilteredTickets = () => handleTickets({ criteria: iaiCriteria });

    const useQuery = () => new URLSearchParams(useLocation().search);

    const queryProvider = useQuery();

    const [lastUpdate, setLastUpdate] = useState<string>();

    const _handleDefaultTicket = () => {
        setCurrentPage(1);

        dispatch(keepCriteria({ ...iaiCriteria, offset: 0 }));

        let requestedStatus = queryProvider.get("status");
        if (requestedStatus) {
            requestedStatus !== 'all'
                ?
                handleTickets({ criteria: { ...iaiCriteria, status: requestedStatus, offset: 0, limit: 100 } })
                :
                handleTickets({ criteria: { ...iaiCriteria, offset: 0, limit: 100 } });
        }
        else {
            handleTickets({ criteria: { ...iaiCriteria, offset: 0, limit: 100 } });
        }

        handlePersonalTickets({});
        handleReopenTickets({});

        setLastUpdate(new Date().toISOString());
    };

    useEffect(() => {
        _handleDefaultTicket();

        const interval = setInterval(() => {
            _handleDefaultTicket();
        }, 300000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryProvider.get("status")]);

    const _handleApplyCriteria = () => {
        dispatch(keepCriteria({ ...iaiCriteria, offset: 0, limit: 100 }));

        setCurrentPage(1);

        handleTickets({
            criteria: {
                ...iaiCriteria,
                offset: 0,
                limit: 100
            }
        });
    }

    const _handlePageChange = (newPage: number) => {
        const skip = (newPage - 1) * 100;

        dispatch(keepCriteria({ ...iaiCriteria, offset: skip }));

        setCurrentPage(newPage);

        handleTickets({ criteria: { ...iaiCriteria, offset: skip } });
    }

    //#endregion

    const _handleSearchTicket = (entry: Ticket, searchValue: string): boolean => {
        const searchTerm = searchValue.toLowerCase();

        return (
            entry.subject?.toLowerCase().match(new RegExp(searchTerm, 'g')) !== null ||
            entry.description?.toLowerCase().match(new RegExp(searchTerm, 'g')) !== null ||
            entry.id?.toLowerCase().match(new RegExp(searchTerm, 'g')) !== null ||
            entry.requestedBy?.toLowerCase().match(new RegExp(searchTerm, 'g')) !== null
            // entry.department?.displayName?.toLowerCase().match(new RegExp(searchTerm, 'g')) !== null ||
            // entry.agent?.displayName?.toLowerCase().match(new RegExp(searchTerm, 'g')) !== null
        );
    }

    const [refinementDrawerVisible, { setTrue: openRefinementDrawer, setFalse: dismissRefinementDrawer }] = useBoolean(false);

    const _handleRefinementDrawer = (): void => (
        !refinementDrawerVisible ? openRefinementDrawer() : dismissRefinementDrawer()
    );

    const navigate = useNavigate();

    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);
    const [delegatedTicket, setDelegatedTicket] = useState<Ticket>();
    const [changeLogsDrawerVisible, setChangeLogsDrawerVisible] = useState<boolean>(false);

    const _handleRetrieveChangeLogs = (id: string) => handleRetrieveChangeLogs({
        id: id,
        onRetrieveSuccess: (changeLogs) => {
            setChangeLogsDrawerVisible(true);
        }
    });

    const iaiAccount = useSelector((state: RootState) => state.account);

    const _handleTicketActionsByRole = (element: any, dataIndex?: number): IContextualMenuItem[] => {
        let menuItems = [
            {
                key: `${dataIndex}_menu_update__`,
                iconProps: { iconName: 'EditCreate' },
                text: 'Modifica',
                onClick: () => navigate(RouterSettings.PATHS.Dashboard.Ticket.edit(element.id!))
            }
        ];

        if (iaiAccount.role === 'administrator' || iaiAccount.role === 'departmentManager') {
            menuItems.push({
                key: `${dataIndex}_menu_delete__`,
                iconProps: { iconName: 'Delete' },
                text: 'Elimina',
                onClick: () => {
                    setDelegatedTicket(element);
                    setDeleteDialogVisible(true);
                }
            });

            menuItems.push({
                key: `${dataIndex}_menu_monitoring__`,
                iconProps: { iconName: 'ViewAll' },
                text: 'Visualizza Change Logs',
                onClick: () => _handleRetrieveChangeLogs(element.id)
            });
        }

        return menuItems;
    }

    return (
        <IAIApplicationLayout
            icon="Ticket"
            heading="Gestione Richieste di Supporto"
            secondaryHeading={`Ultimo aggiornamento della lista avvenuto alle ${DateTimeEnchanter.formatShortTime(lastUpdate, 'it-IT')}`}
        >
            <div className={pageContainer}>
                <Pivot aria-label="Tickets pivot">
                    {iaiAccount.role !== 'user' && (
                        <PivotItem
                            headerText="Richieste Totali"
                            itemIcon="WorkforceManagement"
                        >
                            <IAIDataTable
                                dataSource={tickets || []}
                                dataLoading={ticketsLoading}
                                columns={[
                                    {
                                        key: '__preview__',
                                        name: '',
                                        minWidth: 50,
                                        maxWidth: 50,
                                        onRender: (element: any) => (
                                            <IAICalloutColumn
                                                title={`Ticket #${element.id}`}
                                                tooltipContent="Anteprima Richiesta"
                                                content={(
                                                    <React.Fragment>
                                                        <Text block variant="xLarge" className="mb-3">
                                                            {element.subject}
                                                        </Text>
                                                        <Text block variant="small">
                                                            {parse(element.description || '')}
                                                        </Text>
                                                    </React.Fragment>
                                                )}
                                            />
                                        )
                                    },
                                    {
                                        key: '__subject__',
                                        fieldName: 'subject',
                                        name: 'Oggetto',
                                        className: tableCell,
                                        minWidth: 250,
                                        isResizable: true,
                                        sorter: SortingComparer.stringComparer
                                    },
                                    {
                                        key: '__status__',
                                        fieldName: 'status',
                                        name: 'Stato',
                                        className: tableCell,
                                        minWidth: 100,
                                        maxWidth: 100,
                                        sorter: SortingComparer.stringComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIStatusColumn status={element.status} />
                                        )
                                    },
                                    {
                                        key: '__requestedBy__',
                                        fieldName: 'requestedBy',
                                        name: 'Richiedente',
                                        className: tableCell,
                                        minWidth: 100,
                                        isResizable: true,
                                        sorter: SortingComparer.stringComparer
                                    },
                                    {
                                        key: '__department__',
                                        fieldName: 'department',
                                        name: 'Ufficio',
                                        className: tableCell,
                                        minWidth: 100,
                                        isResizable: true,
                                        sorter: SortingComparer.stringComparer,
                                        onRender: (element: Ticket) => (
                                            <IAITextColumn text={element.department?.displayName} />
                                        )
                                    },
                                    {
                                        key: '__agent__',
                                        fieldName: 'agent',
                                        name: 'Assegnato a',
                                        className: tableCell,
                                        minWidth: 100,
                                        isResizable: true,
                                        sorter: SortingComparer.stringComparer,
                                        onRender: (element: Ticket) => (
                                            <IAITextColumn text={element.agent?.displayName} />
                                        )
                                    },
                                    {
                                        key: '__priority__',
                                        fieldName: 'priority',
                                        name: 'Priorità',
                                        className: tableCell,
                                        minWidth: 50,
                                        maxWidth: 50,
                                        sorter: SortingComparer.stringComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIPriorityColumn priority={element.priority} />
                                        )
                                    },
                                    {
                                        key: '__createdAt__',
                                        fieldName: 'createdAt',
                                        name: 'Creato Il',
                                        className: tableCell,
                                        minWidth: 95,
                                        maxWidth: 95,
                                        sorter: SortingComparer.dateComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIDateColumn showTime dateTime={element.createdAt} />
                                        )
                                    },
                                    {
                                        key: '__closedAt__',
                                        fieldName: 'closedAt',
                                        name: 'Chiuso Il',
                                        className: tableCell,
                                        minWidth: 95,
                                        maxWidth: 95,
                                        sorter: SortingComparer.dateComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIDateColumn showTime dateTime={element.closedAt} />
                                        )
                                    },
                                    {
                                        key: '__reopenAt__',
                                        fieldName: 'reopenAt',
                                        name: 'Riaperto Il',
                                        className: tableCell,
                                        minWidth: 95,
                                        maxWidth: 95,
                                        sorter: SortingComparer.dateComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIDateColumn showTime dateTime={element.reopenAt} />
                                        )
                                    },
                                    {
                                        key: '__action__',
                                        fieldName: '',
                                        name: '',
                                        minWidth: 45,
                                        maxWidth: 45,
                                        onRender: (element: any, dataIndex?: number) => (
                                            <div className="text-center">
                                                <IconButton
                                                    menuIconProps={{ iconName: 'CollapseMenu' }}
                                                    menuProps={{
                                                        items: _handleTicketActionsByRole(element, dataIndex)
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                ]}
                                emptyContentLabel="Nessuna richiesta disponibile"
                                onItemInvoked={(element) => {
                                    navigate(RouterSettings.PATHS.Dashboard.Ticket.edit(element.id || ''));
                                }}
                                searchable
                                onSearch={_handleSearchTicket}
                                filterable
                                filterActive={!!Object.keys(iaiCriteria || {}).length}
                                onFilter={_handleRefinementDrawer}
                                // ** Paging
                                pagingModule={{
                                    // totalCount: tickets.count || 0,
                                    totalCount: !!tickets && tickets.length < 100 ? tickets.length : 5000,
                                    page: currentPage,
                                    limit: 100,
                                    onPageChage: _handlePageChange
                                }}
                            />
                        </PivotItem>
                    )}

                    {iaiAccount.role !== 'user' && (
                        <PivotItem
                            headerText="Richieste Riaperte"
                            itemCount={reopenTickets?.length}
                            itemIcon="RepeatAll"
                        >
                            <IAIDataTable
                                dataSource={reopenTickets || []}
                                dataLoading={reopenTicketsLoading}
                                columns={[
                                    {
                                        key: '__preview__',
                                        name: '',
                                        minWidth: 50,
                                        maxWidth: 50,
                                        onRender: (element: any) => (
                                            <IAICalloutColumn
                                                title={`Ticket #${element.id}`}
                                                tooltipContent="Anteprima Richiesta"
                                                content={(
                                                    <React.Fragment>
                                                        <Text block variant="xLarge" className="mb-3">
                                                            {element.subject}
                                                        </Text>
                                                        <Text block variant="small">
                                                            {parse(element.description || '')}
                                                        </Text>
                                                    </React.Fragment>
                                                )}
                                            />
                                        )
                                    },
                                    {
                                        key: '__subject__',
                                        fieldName: 'subject',
                                        name: 'Oggetto',
                                        className: tableCell,
                                        minWidth: 250,
                                        isResizable: true,
                                        sorter: SortingComparer.stringComparer
                                    },
                                    {
                                        key: '__status__',
                                        fieldName: 'status',
                                        name: 'Stato',
                                        className: tableCell,
                                        minWidth: 100,
                                        maxWidth: 100,
                                        sorter: SortingComparer.stringComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIStatusColumn status={element.status} />
                                        )
                                    },
                                    {
                                        key: '__requestedBy__',
                                        fieldName: 'requestedBy',
                                        name: 'Richiedente',
                                        className: tableCell,
                                        minWidth: 100,
                                        isResizable: true,
                                        sorter: SortingComparer.stringComparer
                                    },
                                    {
                                        key: '__department__',
                                        fieldName: 'department',
                                        name: 'Ufficio',
                                        className: tableCell,
                                        minWidth: 100,
                                        isResizable: true,
                                        sorter: SortingComparer.stringComparer,
                                        onRender: (element: Ticket) => (
                                            <IAITextColumn text={element.department?.displayName} />
                                        )
                                    },
                                    {
                                        key: '__agent__',
                                        fieldName: 'agent',
                                        name: 'Assegnato a',
                                        className: tableCell,
                                        minWidth: 100,
                                        isResizable: true,
                                        sorter: SortingComparer.stringComparer,
                                        onRender: (element: Ticket) => (
                                            <IAITextColumn text={element.agent?.displayName} />
                                        )
                                    },
                                    {
                                        key: '__priority__',
                                        fieldName: 'priority',
                                        name: 'Priorità',
                                        className: tableCell,
                                        minWidth: 50,
                                        maxWidth: 50,
                                        sorter: SortingComparer.stringComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIPriorityColumn priority={element.priority} />
                                        )
                                    },
                                    {
                                        key: '__createdAt__',
                                        fieldName: 'createdAt',
                                        name: 'Creato Il',
                                        className: tableCell,
                                        minWidth: 95,
                                        maxWidth: 95,
                                        sorter: SortingComparer.dateComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIDateColumn showTime dateTime={element.createdAt} />
                                        )
                                    },
                                    {
                                        key: '__closedAt__',
                                        fieldName: 'closedAt',
                                        name: 'Chiuso Il',
                                        className: tableCell,
                                        minWidth: 95,
                                        maxWidth: 95,
                                        sorter: SortingComparer.dateComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIDateColumn showTime dateTime={element.closedAt} />
                                        )
                                    },
                                    {
                                        key: '__reopenAt__',
                                        fieldName: 'reopenAt',
                                        name: 'Riaperto Il',
                                        className: tableCell,
                                        minWidth: 95,
                                        maxWidth: 95,
                                        sorter: SortingComparer.dateComparer,
                                        onRender: (element: Ticket) => (
                                            <IAIDateColumn showTime dateTime={element.reopenAt} />
                                        )
                                    },
                                    {
                                        key: '__action__',
                                        fieldName: '',
                                        name: '',
                                        minWidth: 45,
                                        maxWidth: 45,
                                        onRender: (element: any, dataIndex?: number) => (
                                            <div className="text-center">
                                                <IconButton
                                                    menuIconProps={{ iconName: 'CollapseMenu' }}
                                                    menuProps={{
                                                        items: _handleTicketActionsByRole(element, dataIndex)
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                ]}
                                emptyContentLabel="Nessuna richiesta disponibile"
                                onItemInvoked={(element) => {
                                    navigate(RouterSettings.PATHS.Dashboard.Ticket.edit(element.id || ''));
                                }}
                                searchable
                                onSearch={_handleSearchTicket}
                            />
                        </PivotItem>
                    )}

                    <PivotItem
                        headerText="Richieste Personali"
                        itemCount={personalTickets?.length}
                        itemIcon="Ticket"
                    >
                        <IAIDataTable
                            dataSource={personalTickets || []}
                            dataLoading={personalTicketsLoading}
                            columns={[
                                {
                                    key: '__preview__',
                                    name: '',
                                    minWidth: 50,
                                    maxWidth: 50,
                                    onRender: (element: any) => (
                                        <IAICalloutColumn
                                            title={`Ticket #${element.id}`}
                                            tooltipContent="Anteprima Richiesta"
                                            content={(
                                                <React.Fragment>
                                                    <Text block variant="xLarge" className="mb-3">
                                                        {element.subject}
                                                    </Text>
                                                    <Text block variant="small">
                                                        {parse(element.description || '')}
                                                    </Text>
                                                </React.Fragment>
                                            )}
                                        />
                                    )
                                },
                                {
                                    key: '__subject__',
                                    fieldName: 'subject',
                                    name: 'Oggetto',
                                    className: tableCell,
                                    minWidth: 250,
                                    isResizable: true,
                                    sorter: SortingComparer.stringComparer
                                },
                                {
                                    key: '__status__',
                                    fieldName: 'status',
                                    name: 'Stato',
                                    className: tableCell,
                                    minWidth: 100,
                                    maxWidth: 100,
                                    sorter: SortingComparer.stringComparer,
                                    onRender: (element: Ticket) => (
                                        <IAIStatusColumn status={element.status} />
                                    )
                                },
                                {
                                    key: '__department__',
                                    fieldName: 'department',
                                    name: 'Ufficio',
                                    className: tableCell,
                                    minWidth: 100,
                                    isResizable: true,
                                    sorter: SortingComparer.stringComparer,
                                    onRender: (element: Ticket) => (
                                        <IAITextColumn text={element.department?.displayName} />
                                    )
                                },
                                {
                                    key: '__priority__',
                                    fieldName: 'priority',
                                    name: 'Priorità',
                                    className: tableCell,
                                    minWidth: 50,
                                    maxWidth: 50,
                                    sorter: SortingComparer.stringComparer,
                                    onRender: (element: Ticket) => (
                                        <IAIPriorityColumn priority={element.priority} />
                                    )
                                },
                                {
                                    key: '__createdAt__',
                                    fieldName: 'createdAt',
                                    name: 'Creato Il',
                                    className: tableCell,
                                    minWidth: 95,
                                    maxWidth: 95,
                                    sorter: SortingComparer.dateComparer,
                                    onRender: (element: Ticket) => (
                                        <IAIDateColumn showTime dateTime={element.createdAt} />
                                    )
                                },
                                {
                                    key: '__action__',
                                    fieldName: '',
                                    name: '',
                                    minWidth: 45,
                                    maxWidth: 45,
                                    onRender: (element: any, dataIndex?: number) => (
                                        <div className="text-center">
                                            <IconButton
                                                menuIconProps={{ iconName: 'CollapseMenu' }}
                                                menuProps={{
                                                    items: [
                                                        {
                                                            key: `menuItem__view_${dataIndex}`,
                                                            iconProps: { iconName: 'View' },
                                                            text: 'Visualizza',
                                                            title: 'Visualizza la richiesta selezionata.',
                                                            onClick: () => {
                                                                navigate(RouterSettings.PATHS.Dashboard.Ticket.view(element.id || ''));
                                                            }
                                                        }
                                                    ]
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            ]}
                            dataTableCommands={[
                                {
                                    disabled: ticketsLoading,
                                    text: 'Nuova Richiesta',
                                    iconProps: { iconName: 'Add' },
                                    onClick: () => navigate(RouterSettings.PATHS.Dashboard.Ticket.edit('00-0000'))
                                }
                            ]}
                            emptyContentLabel="Nessuna richiesta disponibile"
                            onItemInvoked={(element) => {
                                navigate(RouterSettings.PATHS.Dashboard.Ticket.view(element.id || ''));
                            }}
                            searchable
                            onSearch={_handleSearchTicket}
                        />
                    </PivotItem>
                </Pivot>

                <IAIDialog
                    dialogProps={{
                        hidden: !deleteDialogVisible,
                        onDismissed: () => setDelegatedTicket(undefined)
                    }}
                    title={'Eliminazione Ticket'}
                    subText={`Stai per eliminare il ticket "${delegatedTicket?.subject}". Vuoi continuare?`}
                    disabled={deleteTicketLoading}
                    confirmText={'Elimina'}
                    dismissText={'Annulla'}
                    onConfirm={() => {
                        if (delegatedTicket?.id) {
                            handleDeleteTicket({
                                id: delegatedTicket.id,
                                onSuccess: () => {
                                    _handleFilteredTickets();
                                    setDeleteDialogVisible(false);
                                }
                            });
                        }
                    }}
                    onDismiss={() => setDeleteDialogVisible(false)}
                />

                <IAITicketsRefinementDrawer
                    dataLoading={ticketsLoading}
                    drawerVisible={refinementDrawerVisible}
                    criteria={iaiCriteria}
                    onApplyCriteria={() => {
                        _handleApplyCriteria();
                        dismissRefinementDrawer();
                    }}
                    onClearCriteria={() => {
                        _handleClearTicketsCriteria();
                        dismissRefinementDrawer();
                    }}
                    onChangeCriteria={_handleChangeTicketsCriteria}
                    onDrawerDismiss={dismissRefinementDrawer}
                />

                <ChangeLogsDrawer
                    dataLoading={ticketsLoading || retrieveChangeLogsLoading}
                    drawerVisible={changeLogsDrawerVisible}
                    changeLogs={retrieveChangeLogs}
                    onDrawerDismiss={() => setChangeLogsDrawerVisible(false)}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default TicketPage;