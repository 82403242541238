import { useState } from 'react';
import { useMsal } from "@azure/msal-react";

import { User } from '../../../../../../api-client';
import { IHookCallback } from '../../../../../../common/models';
import { SystemApiClient } from '../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../utils/notification';

export const useDepartmentMembers = () => {
    const {
        instance
    } = useMsal();

    interface IRetrieveMembers extends IHookCallback {
        id: string;
    }

    const [retrieveMembers, setRetrieveMembers] = useState<User[]>();
    const [retrieveMembersLoading, setRetrieveMembersLoading] = useState<boolean>(false);

    const handleRetrieveMembers = async (params: IRetrieveMembers) => {
        setRetrieveMembersLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.getDepartmentMembers(params.id)
            .then((response) => {
                setRetrieveMembers(response.data);

                params.onSuccess && params.onSuccess();
            })
            .catch((reason: any) => {
                setRetrieveMembers([]);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero degli utenti del dipartimento.',
                    title: 'Impossibile recuperare gli utenti di dipartimento!'
                });
            })
            .finally(() => {
                setRetrieveMembersLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleRetrieveMembers,
        retrieveMembers,
        retrieveMembersLoading
    }
}