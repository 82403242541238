import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IParametersPageClassNames {
    parametersPageContainer: string;
}

export const useClassNames = (): IParametersPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        parametersPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        }
    });
}