import React from 'react';

import { ApplicationSettings } from '../../../utils/application';
import { useClassNames } from './iai-loading-screen.classNames';

import './iai-loading-screen.scss';

const IAILoadingScreen: React.FunctionComponent = () => {
    const {
        loadingScreenContainer
    } = useClassNames();

    return (
        <div className={`iai-loading-screen-container ${loadingScreenContainer}`}>
            <img
                src={`/theme/${ApplicationSettings.IAI_APP_THEME_ROOT}/app-logo-small.png`}
                className={`loading-screen-logo`}
                style={{ width: 64 }}
            />
        </div>
    );
}

export default IAILoadingScreen;