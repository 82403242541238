import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IHomepageClassNames {
    homepageContainer: string;
}

export const useClassNames = (): IHomepageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        homepageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        }
    });
}