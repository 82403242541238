import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { TicketComment } from '../../../../../../api-client';
import { IHookCallback } from '../../../../../../common/models/IHookCallback';
import { TicketApiClient } from '../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../utils/notification';

export const useTicketChat = () => {
    const {
        instance
    } = useMsal();

    interface ICreateTicketComment extends IHookCallback {
        id: string;
        ticketComment: TicketComment;
    }

    const [createTicketCommentLoading, setCreateTicketCommentLoading] = useState<boolean>(false);

    const handleCreateTicketComment = async (params: ICreateTicketComment) => {
        setCreateTicketCommentLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.insertTicketComment(params.id, params.ticketComment)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Messaggio inviato con successo.',
                    title: 'Messaggio inviato!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante l\'invio del messaggio.',
                    title: 'Impossibile inviare il messaggio!'
                });
            })
            .finally(() => {
                setCreateTicketCommentLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveTicketComments extends IHookCallback {
        id: string;
    }

    const [ticketComments, setTicketComments] = useState<TicketComment[]>();
    const [ticketCommentsLoading, setTicketCommentsLoading] = useState<boolean>(false);

    const handleRetrieveTicketComments = async (params: IRetrieveTicketComments) => {
        setTicketCommentsLoading(true);

        const clientApi = await TicketApiClient(instance);

        clientApi.getTicketComments(params.id)
            .then((response) => {
                setTicketComments(response.data);

                params.onSuccess && params.onSuccess();
            })
            .catch((reason) => {
                setTicketComments([]);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il recupero dei messaggi.',
                    title: 'Impossibile recuperare i messaggi!'
                });
            })
            .finally(() => {
                setTicketCommentsLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleCreateTicketComment,
        createTicketCommentLoading,
        handleRetrieveTicketComments,
        ticketComments,
        ticketCommentsLoading
    }
}