import { useEffect, useState } from 'react';

import { Property } from '../../../../../../api-client';
import { IPropertiesFormValidator, PropertiesFormValidator } from '../validators/properties-form-validator';

export interface IPropertiesForm extends Property { }

const defaultValues = {
    displayName: '',
    values: []
}

export const usePropertiesForm = (onFormValidated: (values: IPropertiesForm) => void) => {
    const [formValues, setFormValues] = useState<IPropertiesForm>(defaultValues);

    const handleInitialize = (values: IPropertiesForm) => setFormValues(values);

    const handleFormChange = (name: string, value: any) => setFormValues({
        ...formValues,
        [name]: value
    });

    const [formErrors, setFormErrors] = useState<IPropertiesFormValidator>({});
    const [formValidated, setFormValidated] = useState<boolean>(false);

    const handleFormSubmit = () => {
        const validationResult = PropertiesFormValidator.validateForm(formValues);
        setFormErrors(validationResult);
        setFormValidated(Object.keys(validationResult).length === 0);;
    }

    useEffect(() => {
        Object.keys(formErrors).length === 0 && formValidated && onFormValidated(formValues);
    }, [formErrors]);

    return {
        handleInitialize,
        handleFormChange,
        formValues,
        handleFormSubmit,
        formErrors
    };
}