import React from 'react';

import { IconButton, Stack, Text } from '@fluentui/react';
import { IModalProps, Modal } from '@fluentui/react/lib/Modal';

import { useClassNames } from './iai-modal.classNames';

interface IIAIModal extends IModalProps {
    heading?: string;
    secondaryHeading?: string;
}

const IAIModal: React.FunctionComponent<IIAIModal> = ({
    heading,
    secondaryHeading,
    children,
    ...other
}) => {
    const {
        modalContainer,
        modalHeader,
        modalHeading,
        modalCloseButton,
        modalBody
    } = useClassNames();

    return (
        <Modal {...other} containerClassName={modalContainer}>
            <div className={modalHeader}>
                <Stack>
                    <Text block variant="xLargePlus" className={modalHeading}>{heading}</Text>
                    <Text block>{secondaryHeading}</Text>
                </Stack>

                <IconButton
                    className={modalCloseButton}
                    iconProps={{ iconName: 'Cancel' }}
                    onClick={() => other.onDismiss && other.onDismiss()}
                />
            </div>
            <div className={modalBody}>
                {children}
            </div>
        </Modal>
    );
}

export default IAIModal;