import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IAnnouncementFormDrawerClassNames {
    section: string;
    title: string;
    subtitle: string;
    editor: string;
}

export const useClassNames = (): IAnnouncementFormDrawerClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        section: {
            background: applicationTheme.semanticColors.bodyBackground
        },
        title: {
            // fontFamily: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
            // -webkit-font-smoothing: antialiased;
            fontSize: '14px',
            fontWeight: '600',
            color: 'rgb(48, 46, 40)',
            boxSizing: 'border-box',
            boxShadow: 'none',
            margin: '0px',
            display: 'block',
            padding: '5px 0px',
            overflowWrap: 'break-word'
        },
        subtitle: {
            color: 'rgb(162, 159, 150)',
            fontSize: '10px'
        },
        editor: {
            width: '100%',
            height: '200px',
            display: 'inline-block',
            zIndex: '9999'
        }
    });
}