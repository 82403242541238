import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IIAILayoutHeadingClassNames {
    headingIconContainer: string;
    headingIcon: string;
    headingSecondaryHeading: string;
}

export const useClassNames = (): IIAILayoutHeadingClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        headingIconContainer: {
            background: applicationTheme.palette.themePrimary
        },
        headingIcon: {
            color: 'rgb(255, 255, 255)',
            fontSize: '30px'
        },
        headingSecondaryHeading: {
            color: applicationTheme.palette.neutralSecondary,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            margin: 0
        }
    });
}