import React from 'react';
import _ from 'lodash';

import { ScrollablePane } from '@fluentui/react/lib/ScrollablePane';

import { TicketComment } from '../../../api-client';
import { useClassNames } from './iai-webchat.classNames';

import './iai-webchat.scss';

import { IAIShimmer } from '../../../common/components/IAIShimmer';
import WebchatForm from './components/WebchatForm/webchat-form';
import WebchatMessage from './components/WebchatMessage/webchat-message';

interface IIAIWebchat {
    dataSource?: TicketComment[];
    disabled?: boolean;
    loading?: boolean;
    isAdmin?: boolean;
    onSendMessage: (message: TicketComment) => void;
}

const IAIWebchat: React.FunctionComponent<IIAIWebchat> = ({
    dataSource,
    disabled,
    loading,
    isAdmin,
    onSendMessage
}) => {
    const {
        webchatFormContainer
    } = useClassNames();

    return (
        <div className={`iai-webchat-container`}>
            <ScrollablePane className={`webchat-container`} initialScrollPosition={5000}>
                <IAIShimmer type={'shimmer-comment'} loading={loading}>
                    {_.map(dataSource, (entry, index) =>
                        <WebchatMessage key={index} message={entry} isAdmin={isAdmin} />
                    )}
                </IAIShimmer>
            </ScrollablePane>

            <div className={`webchat-form-container ${webchatFormContainer}`}>
                <WebchatForm
                    disabled={loading || disabled}
                    isAdmin={isAdmin}
                    onSendMessage={onSendMessage}
                />
            </div>
        </div>
    );
}

export default IAIWebchat;