import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IIAISidenav {
    stackContainer: string;
}

export const useClassNames = (): IIAISidenav => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        stackContainer: {
            height: '100%'
        }
    });
}