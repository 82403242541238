import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pie } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';

import { DatePicker, DefaultButton, ComboBox, PrimaryButton, Separator, MessageBar, MessageBarType, TextField, Stack } from '@fluentui/react';
import { Chart as ChartJS, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';

import { RootState } from '../../redux/application/redux-store';
import { TicketFilteringCriteria, TicketStatusEnum } from '../../api-client';
import { initCriteria } from '../../redux/slices/Ticket/ticketSlice';
import { useReport } from './hooks/useReport';
import { useClassNames } from './report-page.classNames';

// TODO: REFACTOR
import { DatePickerStringsIT } from '../../utils/constants/date-format-costants';
import helpers from '../../utils/helpers/helpers';

import { IAIApplicationLayout } from '../../common/layouts/IAIApplicationLayout';
import { IAILoadingScreen } from '../../common/components/IAILoadingScreen';
import { IAIShimmer } from '../../common/components/IAIShimmer';
import ReportCard from './components/ReportCard/report-card';

ChartJS.register(BarElement, ArcElement, Title, Tooltip, Legend);

const ReportPage: React.FunctionComponent = () => {
    const {
        handleTicketReports,
        ticketReports,
        ticketReportsLoading,
        handleExportTickets,
        exportTicketsLoading
    } = useReport();

    const {
        reportPageContainer
    } = useClassNames();

    const REPORT_WIDGETS = [
        {
            id: 'WGT__Open',
            description: `${ticketReports?.openCount}`,
            title: 'Aperti',
            to: `/ticket?status=${TicketStatusEnum.Open}`
        },
        {
            id: 'WGT__Open',
            description: `${ticketReports?.pendingCount}`,
            title: 'Presi in Carico',
            to: `/ticket?status=${TicketStatusEnum.Pending}`
        },
        {
            id: 'WGT__Pending',
            description: `${ticketReports?.workingCount}`,
            title: 'In Lavorazione',
            to: `/ticket?status=${TicketStatusEnum.Working}`
        },
        {
            id: 'WGT__Closed',
            description: `${ticketReports?.closedCount}`,
            title: 'Risolti',
            to: `/ticket?status=${TicketStatusEnum.Closed}`
        }
    ];

    const PIE_CHART_DATASET = {
        labels: [
            'Aperti',
            'Presi in Carico',
            'In Lavorazione',
            'Risolti'
        ],
        datasets: [
            {
                label: '# di Tickets',
                data: [
                    Math.round(ticketReports?.openCount || 0),
                    Math.round(ticketReports?.pendingCount || 0),
                    Math.round(ticketReports?.workingCount || 0),
                    Math.round(ticketReports?.closedCount || 0)
                ],
                backgroundColor: [
                    '#DC3333',
                    '#469CD6',
                    '#EAD124',
                    '#136100',
                ],
                borderColor: [
                    '#DC3333',
                    '#469CD6',
                    '#EAD124',
                    '#136100',
                ],
                borderWidth: 1,
            }
        ]
    };

    const [reportsCriteria, setReportsCriteria] = useState<TicketFilteringCriteria>({
        fromDate: moment().startOf('month').toDate().toISOString()
    });

    const _handleChangeReportsCriteria = (name: string, value: any) => setReportsCriteria({
        ...reportsCriteria,
        [name]: value
    });

    const _handleClearReportsCriteria = () => {
        setReportsCriteria({
            fromDate: moment().startOf('month').toDate().toISOString()
        });

        handleTicketReports({
            criteria: {
                fromDate: moment().startOf('month').toDate().toISOString()
            }
        });
    }

    const dispatch = useDispatch();

    const _handleTicketReports = () => {
        if (reportsCriteria) {
            dispatch(initCriteria(reportsCriteria));
        }

        handleTicketReports({ criteria: reportsCriteria });
    }

    useEffect(() => {
        _handleTicketReports();
    }, []);

    const iaiAccount = useSelector((state: RootState) => state.account);
    const iaiSystem = useSelector((state: RootState) => state.system);

    return (
        <IAIApplicationLayout
            icon={'LineChart'}
            heading={'Report & Statistiche'}
            secondaryHeading={'Consulta i report.'}
        >
            <div className={`report-page-container ${reportPageContainer}`}>
                <div className="row">
                    <h4 className="mb-4">Ecco i dati della piattaforma</h4>

                    <MessageBar
                        messageBarType={MessageBarType.info}
                        isMultiline={false}
                        styles={{ root: { marginBottom: '25px' } }}
                    >
                        {'Le statistiche fanno riferimento al mese corrente, rimuovere il filtro per avere una visione piu completa.'}
                    </MessageBar>
                </div>

                <div className="row">
                    <div className={'col-8'}>
                        <Stack
                            verticalFill
                            verticalAlign="space-between"
                        >
                            <div className="row">
                                <div className={'col-4'}>
                                    <DatePicker
                                        label={'Data da:'}
                                        placeholder="Seleziona una data..."
                                        /**/
                                        strings={DatePickerStringsIT}
                                        formatDate={(date?: Date): string => helpers.getFormatDate(date)}
                                        disabled={ticketReportsLoading || !iaiSystem.agentsInitialized}
                                        value={reportsCriteria?.fromDate ? new Date(reportsCriteria?.fromDate) : undefined}
                                        onSelectDate={(value) => (
                                            _handleChangeReportsCriteria('fromDate', value ? moment(value).endOf('day').toDate().toISOString() : undefined)
                                        )}
                                    />
                                </div>
                                <div className={'col-4'}>
                                    <DatePicker
                                        label={'Data a:'}
                                        placeholder="Seleziona una data..."
                                        /**/
                                        strings={DatePickerStringsIT}
                                        formatDate={(date?: Date): string => helpers.getFormatDate(date)}
                                        disabled={ticketReportsLoading || !iaiSystem.agentsInitialized}
                                        value={reportsCriteria?.toDate ? new Date(reportsCriteria?.toDate) : undefined}
                                        onSelectDate={(value) => (
                                            _handleChangeReportsCriteria('toDate', value ? moment(value).endOf('day').toDate().toISOString() : undefined)
                                        )}
                                    />
                                </div>
                                <div className={'col-4'}>
                                    <TextField
                                        label={'Richiedente:'}
                                        placeholder={'Inserisci un richiedente...'}
                                        disabled={ticketReportsLoading}
                                        value={reportsCriteria?.requestedBy || ''}
                                        onChange={(e, newValue) => _handleChangeReportsCriteria('requestedBy', newValue)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={'col-4'}>
                                    <ComboBox
                                        label={'Dipartimento:'}
                                        placeholder={'Seleziona il dipartimento...'}
                                        /**/
                                        options={_.map(iaiSystem.departments, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={ticketReportsLoading || !iaiSystem.departmentsInitialized}
                                        selectedKey={reportsCriteria?.departmentId || null}
                                        onChange={(ev, option) => _handleChangeReportsCriteria('departmentId', option?.key)}
                                    />
                                </div>
                                <div className={'col-4'}>
                                    <ComboBox
                                        label={'Operatore:'}
                                        placeholder={'Seleziona l\'operatore...'}
                                        /**/
                                        options={_.map(iaiSystem.agents, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={ticketReportsLoading || !iaiSystem.agentsInitialized}
                                        selectedKey={reportsCriteria?.agentId || null}
                                        onChange={(ev, option) => _handleChangeReportsCriteria('agentId', option?.key)}
                                    />
                                </div>
                            </div>
                        </Stack>
                    </div>
                    <div className={'col-2'}>
                        <Stack
                            horizontalAlign="center"
                            verticalFill
                            verticalAlign="end"
                            tokens={{ childrenGap: 10 }}
                            style={{ paddingTop: 12 }}
                        >
                            <PrimaryButton
                                text="Filtra"
                                iconProps={{ iconName: 'Filter' }}
                                disabled={ticketReportsLoading || !iaiSystem.agentsInitialized}
                                onClick={_handleTicketReports}
                                style={{ width: 120 }}
                            />
                            <DefaultButton
                                text="Pulisci"
                                iconProps={{ iconName: 'Delete' }}
                                disabled={ticketReportsLoading || !iaiSystem.agentsInitialized}
                                onClick={_handleClearReportsCriteria}
                                style={{ width: 120 }}
                            />

                            {iaiAccount.role === 'administrator' && (
                                <PrimaryButton
                                    text="Esporta"
                                    iconProps={{ iconName: 'ExcelLogo' }}
                                    disabled={ticketReportsLoading || !iaiSystem.agentsInitialized || exportTicketsLoading}
                                    onClick={() => handleExportTickets({ criteria: reportsCriteria })}
                                    style={{ width: 120 }}
                                />
                            )}
                        </Stack>
                    </div>
                </div>

                {(exportTicketsLoading) && (
                    <IAILoadingScreen />
                )}

                <Separator className="my-3" />

                <div className="row">
                    {_.map(REPORT_WIDGETS, (entry, index) => (
                        <div key={index} className={'col-3'}>
                            <IAIShimmer
                                type={'shimmer-widget'}
                                loading={!ticketReports || ticketReportsLoading}
                            >
                                <ReportCard {...entry} />
                            </IAIShimmer>
                        </div>
                    ))}
                </div>

                <Separator className="my-3" />

                <div className="row">
                    <div className="col-3">
                        <IAIShimmer
                            type={'shimmer-widget'}
                            loading={!ticketReports || ticketReportsLoading}
                        >
                            <ReportCard
                                id={'WGT__Received'}
                                description={`${ticketReports?.totalCount}`}
                                title={'Ticket Ricevuti'}
                                to={`/ticket?status=${'all'}`}
                            />
                        </IAIShimmer>
                    </div>
                    <div className="col-9">
                        <Pie data={PIE_CHART_DATASET} style={{ maxHeight: '400px' }} />
                    </div>
                </div>
            </div>
        </IAIApplicationLayout>
    );
}

export default ReportPage;