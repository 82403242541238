import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IWidgetNoticesClassNames {
    noticeItem: string;
    noticeItemTitle: string;
}

export const useClassNames = (): IWidgetNoticesClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        noticeItem: {
            background: applicationTheme.palette.themeLighterAlt,
            border: applicationTheme.semanticColors.variantBorder,
            borderLeft: `4px solid ${applicationTheme.palette.themePrimary}`,
            boxShadow: applicationTheme.semanticColors.cardShadow,
            borderRadius: 10,
            padding: '10px 15px'
        },
        noticeItemTitle: {
            color: applicationTheme.palette.themePrimary,
        }
    });
}