import { IPropertiesForm } from '../hooks/usePropertiesForm';

export interface IPropertiesFormValidator {
    displayName?: string;
    values?: string;
}

export class PropertiesFormValidator {
    public static validateForm = (values: IPropertiesForm): IPropertiesFormValidator => {
        let formErrors: IPropertiesFormValidator = {};

        if (!values.displayName || !values.displayName.toString()) {
            formErrors.displayName = `Il nome della proprietà é obbligatorio!`;
        }

        if (!values.values || !values.values.toString()) {
            formErrors.values = `I valori della proprietà sono obbligatori!`;
        }

        return formErrors;
    }
}