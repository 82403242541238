import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IIAIToastContainer {
    toastWrapper: string;
    toastContainer: string;
    toastContainerProgress: string;
}

export const useClassNames = (): IIAIToastContainer => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        toastWrapper: {
            width: `350px !important`,
            zIndex: '1000001 !important'
        },
        toastContainer: {
            borderRadius: `0 !important`,
        },
        toastContainerProgress: {
            background: `${applicationTheme.palette.themePrimary} !important`,
            height: `3px !important`
        }
    });
}