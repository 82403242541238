import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import { ContextualMenuItemType } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';

import { Announcement } from '../../../api-client';
import { SortingComparer } from '../../../utils/data-comparer';
import { DateTimeEnchanter } from '../../../utils/data-enchanters';
import { useNotice } from './hooks/useNotice';
import { useClassNames } from './notice-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAICalloutColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAICalloutColumn';
import { IAIDateColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAIDateColumn';
import { IAIDataTable } from '../../../common/components/IAIDataTable';
import { IAIDialog } from '../../../common/components/IAIDialog';
import { AnnouncementFormDrawer } from './components/AnnouncementFormDrawer';

const NoticePage: React.FunctionComponent = () => {
    const {
        handleNotices,
        notices,
        noticesLoading,
        handleCreateNotice,
        createNoticeLoading,
        handleRetrieveNotice,
        retrieveNotice,
        retrieveNoticeLoading,
        handleUpdateNotice,
        updateNoticeLoading,
        handleDeleteNotice,
        deleteNoticeLoading
    } = useNotice();

    const {
        pageContainer,
        calloutSecondaryHeading,
        calloutText,
        tableCell
    } = useClassNames();

    useEffect(() => {
        handleNotices();
    }, []);

    const _handleSearchAnnouncement = (entry: Announcement, searchValue: string): boolean => (
        entry.title?.toLowerCase().search(searchValue) !== -1
    );

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [delegatedAnnouncement, setDelegatedAnnouncement] = useState<Announcement>();

    const _handleSubmit = (announcement: Announcement, announcementId?: string) => {
        const onUpsertSuccess = () => {
            handleNotices();

            setDrawerVisible(false);
            setDelegatedAnnouncement(undefined);
        };

        !announcementId
            ?
            handleCreateNotice({
                announcement: announcement,
                onSuccess: onUpsertSuccess
            })
            :
            handleUpdateNotice({
                id: announcementId,
                announcement: announcement,
                onSuccess: onUpsertSuccess
            });
    }

    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);

    return (
        <IAIApplicationLayout
            icon={'Flag'}
            heading={'Notifiche'}
            secondaryHeading={'Gestisci le Notifiche.'}
        >
            <div className={pageContainer}>
                <IAIDataTable
                    columns={[
                        {
                            key: 'announcement_preview__',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: any) => (
                                <IAICalloutColumn
                                    title={element.title}
                                    tooltipContent={'Anteprima notifica'}
                                    contentLoading={retrieveNoticeLoading}
                                    content={
                                        retrieveNotice &&
                                        <div className="col-12">
                                            <span className={calloutSecondaryHeading}>
                                                {`Ultimo aggiornamento: ${DateTimeEnchanter.formatStandardDate(retrieveNotice.createdAt, 'it-IT')}`}
                                            </span>
                                            <span className={calloutText}>
                                                {parse(retrieveNotice.description || '')}
                                            </span>
                                        </div>
                                    }
                                    onEngage={() => handleRetrieveNotice({
                                        id: element.id,
                                        onRetrieveSuccess: (announcement) => setDelegatedAnnouncement(announcement)
                                    })}
                                    onDismiss={() => setDelegatedAnnouncement(undefined)}
                                />
                            )
                        },
                        {
                            key: 'announcement_title__',
                            fieldName: 'title',
                            name: 'Titolo',
                            className: tableCell,
                            minWidth: 250,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer
                        },
                        {
                            key: 'announcement_availableFrom__',
                            fieldName: 'availableFrom',
                            name: 'Valido da',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            sorter: SortingComparer.dateComparer,
                            onRender: (element: Announcement) => <IAIDateColumn dateTime={element.availableFrom} />
                        },
                        {
                            key: 'announcement_availableTo__',
                            fieldName: 'availableTo',
                            name: 'Valido a',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            sorter: SortingComparer.dateComparer,
                            onRender: (element: Announcement) => <IAIDateColumn dateTime={element.availableTo} />
                        },
                        {
                            key: 'announcement_action__',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: any, dataIndex?: number) => (
                                <div className="text-center">
                                    <IconButton
                                        menuIconProps={{ iconName: 'CollapseMenu' }}
                                        menuProps={{
                                            items: [
                                                {
                                                    key: `${dataIndex}_menu_header__`,
                                                    itemType: ContextualMenuItemType.Header,
                                                    text: 'Azioni'
                                                },
                                                {
                                                    key: `${dataIndex}_menu_update__`,
                                                    iconProps: { iconName: 'EditCreate' },
                                                    text: 'Modifica',
                                                    onClick: () => {
                                                        handleRetrieveNotice({
                                                            id: element.id,
                                                            onRetrieveSuccess: (announcement) => {
                                                                setDelegatedAnnouncement(announcement);
                                                                setDrawerVisible(true);
                                                            }
                                                        });
                                                    }
                                                },
                                                {
                                                    key: `${dataIndex}_menu_delete__`,
                                                    iconProps: { iconName: 'Delete' },
                                                    text: 'Elimina',
                                                    onClick: () => {
                                                        setDelegatedAnnouncement(element);
                                                        setDeleteDialogVisible(true);
                                                    }
                                                }
                                            ]
                                        }}
                                    />
                                </div>
                            )
                        }
                    ]}
                    dataSource={notices || []}
                    dataLoading={noticesLoading}
                    dataTableCommands={[{
                        disabled: noticesLoading,
                        text: 'Nuova notifica',
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            setDelegatedAnnouncement(undefined)
                            setDrawerVisible(true)
                        }
                    }]}
                    emptyContentLabel="Nessuna richiesta disponibile"
                    onItemInvoked={(element) => {
                        handleRetrieveNotice({
                            id: element.id,
                            onRetrieveSuccess: (announcement) => {
                                setDelegatedAnnouncement(announcement);
                                setDrawerVisible(true);
                            }
                        });
                    }}
                    searchable
                    onSearch={_handleSearchAnnouncement}
                />

                <IAIDialog
                    dialogProps={{
                        hidden: !deleteDialogVisible,
                        onDismissed: () => setDelegatedAnnouncement(undefined)
                    }}
                    title={'Eliminazione notifica'}
                    subText={`Stai per eliminare la notifica "${delegatedAnnouncement?.title}". Vuoi continuare?`}
                    disabled={deleteNoticeLoading || retrieveNoticeLoading}
                    confirmText={'Elimina'}
                    dismissText={'Annulla'}
                    onConfirm={() => {
                        if (delegatedAnnouncement && delegatedAnnouncement.id) {
                            handleDeleteNotice({
                                id: delegatedAnnouncement.id,
                                onSuccess: () => {
                                    handleNotices();

                                    setDeleteDialogVisible(false);
                                }
                            });
                        }
                    }}
                    onDismiss={() => setDeleteDialogVisible(false)}
                />

                <AnnouncementFormDrawer
                    disabled={retrieveNoticeLoading || createNoticeLoading || updateNoticeLoading}
                    drawerVisible={drawerVisible}
                    announcement={delegatedAnnouncement}
                    onDrawerDismiss={() => setDrawerVisible(false)}
                    onSubmit={_handleSubmit}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default NoticePage;