import React from 'react';

import { TooltipHost } from '@fluentui/react/lib/Tooltip';

import { DataEnchanter } from '../../../../../../utils/data-enchanters';

interface IIAIPriorityColumn {
    priority?: string;
}

const IAIPriorityColumn: React.FunctionComponent<IIAIPriorityColumn> = ({ priority }) => (
    <div className="text-center">
        <TooltipHost content={DataEnchanter.textByPriorityEnum(priority || '')}>
            {DataEnchanter.textByPriorityEnum(priority || '')}
        </TooltipHost>
    </div>
);

export default IAIPriorityColumn;