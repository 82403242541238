import React, { MouseEventHandler } from 'react';

import { IDropdownProps, Dropdown, Icon, Stack } from '@fluentui/react';

interface IIAIDropdown extends IDropdownProps {
    onClear?: () => void;
}

const IAIDropdown: React.FunctionComponent<IIAIDropdown> = ({ onClear, ...props }) => (
    <Dropdown
        {...props}
        onRenderCaretDown={(props) => (
            <Stack horizontal verticalAlign={"center"}>
                {(!!props?.selectedKey || !!props?.selectedKeys) &&
                    <Icon
                        iconName={"Cancel"}
                        styles={{
                            root: {
                                color: "rgb(96, 94, 92)",
                                paddingRight: ".7em",
                                "&:hover": {
                                    fontWeight: 800
                                }
                            }
                        }}
                        onClick={(ev) => {
                            ev.stopPropagation();

                            onClear && onClear();
                        }}
                    />
                }

                <Icon
                    iconName={"ChevronDown"}
                    styles={{
                        root: {
                            color: "rgb(96, 94, 92)",
                            "&:hover": {
                                fontWeight: 800
                            }
                        }
                    }}
                />
            </Stack>
        )}
    />
);

export default IAIDropdown;