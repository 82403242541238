import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface IGroupsPageClassNames {
    groupsPageContainer: string;
    tableCell: string;
}

export const useClassNames = (): IGroupsPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        groupsPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground
        },
        tableCell: {
            alignSelf: 'center'
        },
    });
}