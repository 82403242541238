import { mergeStyleSets } from '@fluentui/react';

type IIAIPaginationClassNames = {
    paginationContainer: string;
}

export const useClassNames = (): IIAIPaginationClassNames => {
    return mergeStyleSets({
        paginationContainer: {
            borderTop: '1px solid rgba(182, 178, 175, 0.25)',
            padding: '12px'
        }
    });
}