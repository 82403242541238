import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Stack, Text } from '@fluentui/react';

import { RootState } from '../../../redux/application/redux-store';
import { ProfileRoleEnum } from '../../../api-client';
import { RouterSettings } from '../../../utils/router';
import { useClassNames } from './setting-page.classNames';

import './setting-page.scss';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIWidgetIcon } from '../../../common/components/IAIWidget';

const SettingPage: React.FunctionComponent = () => {
    const {
        settingPageContainer
    } = useClassNames();

    const SETTING_WIDGETS = [
        {
            icon: 'EngineeringGroup',
            title: 'Gruppi Abilitati',
            tooltip: 'Gestisci i gruppi abilitati sulla piattaforma.',
            to: RouterSettings.PATHS.Dashboard.Setting.groups,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            icon: 'ArrangeBringToFront',
            title: 'Gestione Aree',
            tooltip: 'Gestisci le aree delle richieste.',
            to: RouterSettings.PATHS.Dashboard.Setting.area,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        },
        {
            icon: 'Flag',
            title: 'Gestione Avvisi',
            tooltip: 'Gestisci gli avvisi della piattaforma.',
            to: RouterSettings.PATHS.Dashboard.Setting.notice,
            roles: [
                ProfileRoleEnum.Administrator
            ]
        }
    ];

    const iaiAccountInfo = useSelector((state: RootState) => state.account);

    const _handleWidgetsByRole = () => (
        _.filter(SETTING_WIDGETS, entry => (
            _.some(entry.roles, entry => entry === iaiAccountInfo.role)
        ))
    );

    return (
        <IAIApplicationLayout
            icon={'Settings'}
            heading={'Impostazioni'}
            secondaryHeading={'Gestisci le impostazioni della piattaforma.'}
        >
            <div className={`mt-3 ${settingPageContainer}`}>
                <Text block variant="xLargePlus" className="mb-4">
                    Impostazioni di Sistema
                </Text>

                <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 15 }}
                    wrap
                    styles={{ inner: { justifyContent: 'start' } }}
                >
                    {_.map(_handleWidgetsByRole(), (dataItem, dataIndex) => (
                        <IAIWidgetIcon key={dataIndex} {...dataItem} />
                    ))}
                </Stack>
            </div>
        </IAIApplicationLayout>
    );
}

export default SettingPage;