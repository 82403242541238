import React, { useEffect, useState } from 'react';
import parse from "html-react-parser";
import _ from 'lodash';

import { ContextualMenuItemType, IIconProps } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';

// Interfaces > Utils > Hooks > ClassNames
import { EventReceiver } from '../../../api-client';
import { SortingComparer } from '../../../utils/data-comparer';
import { DateTimeEnchanter } from '../../../utils/data-enchanters';
import { useEventReceiver } from './components/EventReceiversFormDrawer/hooks/useEventReceivers';
import { useClassNames } from './event-receivers-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAICalloutColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAICalloutColumn';
import { IAIDateColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAIDateColumn';
import { IAIDataTable } from '../../../common/components/IAIDataTable';
import { IAIDialog } from '../../../common/components/IAIDialog';

import EventReceiverFormDrawer from './components/EventReceiversFormDrawer/event-receivers-form-drawer';
import { IAITextColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAITextColumn';

const EventReceiverPage: React.FunctionComponent = () => {
    const {
        handleEventReceivers,
        eventReceivers,
        eventReceiversLoading,
        handleCreateEventReceiver,
        createEventReceiverLoading,
        handleUpdateEventReceiver,
        updateEventReceiverLoading,
        // handleRetrieveEventReceiver,
        // retrieveEventReceiver,
        // retrieveEventReceiverLoading,
        // handleDeleteEventReceiver,
        // deleteEventReceiverLoading
    } = useEventReceiver();

    const {
        eventReceiverPageContainer,
        calloutSecondaryHeading,
        calloutText,
        tableCell,
    } = useClassNames();

    useEffect(() => {
        handleEventReceivers();
    }, []);

    const _handleSearchEventReceiver = (entry: EventReceiver, searchValue: string): boolean => (
        entry.displayName?.toLowerCase().search(searchValue) !== -1
    );

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [delegatedEventReceiver, setDelegatedEventReceiver] = useState<EventReceiver>();

    const onUpsertSuccessed = () => {
        setDrawerVisible(false);
        setDelegatedEventReceiver(undefined);
        handleEventReceivers();
    };

    const fixEventsCollection = (collection: any) => {
        let collectionFixed: any = [];
        if (collection && collection.length > 0) {
            collection.forEach((tag: any) => {
                collectionFixed.push(tag.value)
            });
        }
        return collectionFixed;
    }

    const _handleSubmit = (eventReceiver: EventReceiver, eventReceiverId?: string) => {
        console.log('_handleSubmit', eventReceiverId);
        eventReceiver.onCreated = fixEventsCollection(eventReceiver.onCreated);
        eventReceiver.onCreating = fixEventsCollection(eventReceiver.onCreating);
        eventReceiver.onDeleted = fixEventsCollection(eventReceiver.onDeleted);
        eventReceiver.onDeleting = fixEventsCollection(eventReceiver.onDeleting);
        eventReceiver.onUpdated = fixEventsCollection(eventReceiver.onUpdated);
        eventReceiver.onUpdating = fixEventsCollection(eventReceiver.onUpdating);
        console.log('_handleSubmit', eventReceiver);

        if (eventReceiverId) {
            handleUpdateEventReceiver({
                id: eventReceiverId,
                eventReceiver: eventReceiver,
                onSuccess: onUpsertSuccessed
            });
        }
    }

    const EditCreate: IIconProps = { iconName: 'EditCreate' };

    return (
        <IAIApplicationLayout
            icon={'ScheduleEventAction'}
            heading={'Event Receivers'}
            secondaryHeading={'Gestisci gli Event Receivers.'}
        >
            <div className={eventReceiverPageContainer}>
                <IAIDataTable
                    columns={[
                        {
                            key: 'eventReceiver_preview__',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: EventReceiver) => (
                                <IAICalloutColumn
                                    title={element.displayName}
                                    tooltipContent={'Anteprima Event Receiver'}
                                    content={
                                        element &&
                                        <section>
                                            <div className="col-12">
                                                <span className={calloutSecondaryHeading}>
                                                    {`Ultimo aggiornamento: ${DateTimeEnchanter.formatStandardDate(element.createdAt, 'it-IT')}`}
                                                </span>
                                                <span className={calloutText}>
                                                    {parse(element.displayName || '')}
                                                </span>
                                                <hr />
                                            </div>
                                            <div className="col-12">
                                                <h6 className='m-0'>onCreated ({element.onCreated && element.onCreated.length})</h6>
                                                <div className='mb-2'>
                                                    {_.map(element.onCreated, (dataItem: string, dataIndex) => (
                                                        <span key={dataIndex}>{dataItem}; </span>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <h6 className='m-0'>onCreating ({element.onCreating && element.onCreating.length})</h6>
                                                <div className='mb-2'>
                                                    {_.map(element.onCreating, (dataItem: string, dataIndex) => (
                                                        <span key={dataIndex}>{dataItem}; </span>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <h6 className='m-0'>onDeleted ({element.onDeleted && element.onDeleted.length})</h6>
                                                <div className='mb-2'>
                                                    {_.map(element.onDeleted, (dataItem: string, dataIndex) => (
                                                        <span key={dataIndex}>{dataItem}; </span>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <h6 className='m-0'>onDeleting ({element.onDeleting && element.onDeleting.length})</h6>
                                                <div className='mb-2'>
                                                    {_.map(element.onDeleting, (dataItem: string, dataIndex) => (
                                                        <span key={dataIndex}>{dataItem}; </span>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <h6 className='m-0'>onUpdated ({element.onUpdated && element.onUpdated.length})</h6>
                                                <div className='mb-2'>
                                                    {_.map(element.onUpdated, (dataItem: string, dataIndex) => (
                                                        <span key={dataIndex}>{dataItem}; </span>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <h6 className='m-0'>onUpdating ({element.onUpdating && element.onUpdating.length})</h6>
                                                <div className='mb-2'>
                                                    {_.map(element.onUpdating, (dataItem: string, dataIndex) => (
                                                        <span key={dataIndex}>{dataItem}; </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </section>
                                    }
                                />
                            )
                        },
                        {
                            key: 'eventReceiver_title__',
                            fieldName: 'displayName',
                            name: 'Nome',
                            className: tableCell,
                            minWidth: 150,
                            maxWidth: 150,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer
                        },
                        {
                            key: 'eventReceiver_onCreated__',
                            fieldName: 'onCreated',
                            name: 'onCreated',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: true,
                            onRender: (element: EventReceiver) => <IAITextColumn text={element.onCreated && element.onCreated.length > 0 ? element.onCreated.length + '' : '0'} />
                        },
                        {
                            key: 'eventReceiver_onCreating__',
                            fieldName: 'onCreating',
                            name: 'onCreating',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: true,
                            onRender: (element: EventReceiver) => <IAITextColumn text={element.onCreating && element.onCreating.length > 0 ? element.onCreating.length + '' : '0'} />
                        },
                        {
                            key: 'eventReceiver_onDeleted__',
                            fieldName: 'onDeleted',
                            name: 'onDeleted',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: true,
                            onRender: (element: EventReceiver) => <IAITextColumn text={element.onDeleted && element.onDeleted.length > 0 ? element.onDeleted.length + '' : '0'} />
                        },
                        {
                            key: 'eventReceiver_onDeleting__',
                            fieldName: 'onDeleting',
                            name: 'onDeleting',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: true,
                            onRender: (element: EventReceiver) => <IAITextColumn text={element.onDeleting && element.onDeleting.length > 0 ? element.onDeleting.length + '' : '0'} />
                        },
                        {
                            key: 'eventReceiver_onUpdated__',
                            fieldName: 'onUpdated',
                            name: 'onUpdated',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: true,
                            onRender: (element: EventReceiver) => <IAITextColumn text={element.onUpdated && element.onUpdated.length > 0 ? element.onUpdated.length + '' : '0'} />
                        },
                        {
                            key: 'eventReceiver_onUpdating__',
                            fieldName: 'onUpdating',
                            name: 'onUpdating',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            isResizable: true,
                            onRender: (element: EventReceiver) => <IAITextColumn text={element.onUpdating && element.onUpdating.length > 0 ? element.onUpdating.length + '' : '0'} />
                        },
                        {
                            key: 'eventReceiver_action__',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: EventReceiver, dataIndex?: number) => (
                                <div className="text-center">
                                    <IconButton
                                        iconProps={EditCreate}
                                        title="Modifica"
                                        ariaLabel="Modifica"
                                        onClick={() => {
                                            setDelegatedEventReceiver(element);
                                            setDrawerVisible(true);
                                        }}
                                    />
                                </div>
                            )
                        }
                    ]}
                    dataSource={eventReceivers || []}
                    dataLoading={eventReceiversLoading}
                    emptyContentLabel="Nessuna richiesta disponibile"
                    searchable
                    onSearch={_handleSearchEventReceiver}
                />

                <EventReceiverFormDrawer
                    disabled={createEventReceiverLoading || updateEventReceiverLoading}
                    drawerVisible={drawerVisible}
                    eventReceiver={delegatedEventReceiver}
                    onDrawerDismiss={() => {
                        setDrawerVisible(false);
                        setDelegatedEventReceiver(undefined);
                    }}
                    onSubmit={_handleSubmit}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default EventReceiverPage;