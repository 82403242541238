import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAccountInfo } from '../../../common/models/Account';
import { SessionStorageHelper } from '../../../utils/storage';

// Define a type for the slice state
interface AccountState extends IAccountInfo {
    accountInitialized?: boolean;
    graphInitialized?: boolean;
}

// Define the initial state using that type
const initialState: AccountState = {
    accountInitialized: false,
    graphInitialized: false
};

export const accountSlice = createSlice({
    name: 'account',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        initAccountInfo: (state, action: PayloadAction<IAccountInfo>) => {
            Object.assign(state, {
                ...action.payload,
                accountInitialized: true,
                // DEBUG ROLES IMPERSONATION
                // role: 'departmentAgent'
            });

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_ACCOUNT, {
                ...action.payload,
                accountInitialized: true
            });
        },
        initGraphInfo: (state, action: PayloadAction<IAccountInfo>) => {
            state.id = action.payload.id;
            state.displayName = action.payload.displayName;
            state.mail = action.payload.mail;
            state.jobTitle = action.payload.jobTitle;
            state.department = action.payload.department;
            state.graphInitialized = true;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_ACCOUNT, state);
        },
        initGraphPhoto: (state, action: PayloadAction<string>) => {
            state.photo = action.payload;

            SessionStorageHelper.createItem(SessionStorageHelper.STORAGE_KEYS.IAI_SYSTEM_ACCOUNT, state);
        }
    }
});

export const { initAccountInfo, initGraphInfo, initGraphPhoto } = accountSlice.actions;

export default accountSlice.reducer;