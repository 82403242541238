import { useState } from "react";

import { useMsal } from "@azure/msal-react";

import { GraphApiClient } from "../../../../../../providers/graph-provider";
import { IHookCallback } from "../../../../../models";
import { ExternalApiClient } from "../../../../../../providers/api-provider";

export const useTeams = () => {
	const {
		instance
	} = useMsal();

	// GRAPH - Create onlineMeeting: /me/onlineMeetings

	interface IOnlineMeeting extends IHookCallback {
		subject: string;
		startDateTime: string;
		endDateTime: string;
		onOnlineMeetingCreated: (meetingCreated: any) => void;
	}

	const [graphOnlineMeeting, setGraphOnlineMeeting] = useState<any>();
	const [graphOnlineMeetingLoading, setGraphOnlineMeetingLoading] = useState<boolean>(false);

	const handleOnlineMeeting = async (params: IOnlineMeeting) => {
		setGraphOnlineMeetingLoading(true);

		const clientApi = await GraphApiClient(instance);

		clientApi.post('/me/onlineMeetings', {
			subject: params.subject,
			startDateTime: params.startDateTime,
			endDateTime: params.endDateTime,
		})
			.then((response) => {
				setGraphOnlineMeeting(response.data);

				params.onOnlineMeetingCreated(response.data);
			})
			.catch((reason) => {
				// CATCH REASON
			})
			.finally(() => {
				setGraphOnlineMeetingLoading(false);
			});
	};

	const handleOnlineMeetingClient = async (params: IOnlineMeeting) => {
		setGraphOnlineMeetingLoading(true);

		const clientApi = await ExternalApiClient(instance);

		clientApi.createMeetingByExternal({
			subject: params.subject,
			startDateTime: params.startDateTime,
			endDateTime: params.endDateTime,
		})
			.then((response) => {
				setGraphOnlineMeeting(response.data);

				params.onOnlineMeetingCreated(response.data);
			})
			.catch((reason) => {
				// CATCH REASON
			})
			.finally(() => {
				setGraphOnlineMeetingLoading(false);
			});
	};

	return {
		handleOnlineMeeting,
		handleOnlineMeetingClient,
		graphOnlineMeeting,
		graphOnlineMeetingLoading,
	};
};
