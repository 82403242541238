export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
    ?
    {
        type: Key;
    }
    :
    {
        type: Key;
        payload: M[Key];
    };
};

export enum AuthTypes {
    Init = 'INIT',
    Signin = 'SIGNIN',
    Signout = 'SIGNOUT'
}

export type AuthPayload = {
    [AuthTypes.Init]: {
        isAuthenticated: boolean;
        contextType: 'ms-online' | 'ms-teams';
    };
    [AuthTypes.Signout]: undefined;
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];