import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";

import { Separator } from '@fluentui/react';

import { RootState } from '../../../redux/application/redux-store';
import { Ticket } from '../../../api-client';
import { TICKET } from '../../../utils/constants';
import { RouterSettings } from '../../../utils/router';
import { DataEnchanter, DateTimeEnchanter } from '../../../utils/data-enchanters';
import { useTicketDetails } from './hooks/useTicketDetails';

import './ticket-details-page.scss';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIShimmer } from '../../../common/components/IAIShimmer';
import { IAIDialog } from '../../../common/components/IAIDialog';
import TicketDetailsForm from './components/TicketDetailsForm/ticket-details-form';
import TicketChat from './components/TicketChat/ticket-chat';

const TicketDetailsPage: React.FunctionComponent = () => {
    const {
        handleCreateTicket,
        createTicketLoading,
        handleRetrieveTicket,
        retrieveTicket,
        retrieveTicketLoading,
        handleUpdateTicket,
        updateTicketLoading,
        handleRetrieveChangeLogs,
        retrieveChangeLogs,
        retrieveChangeLogsLoading
    } = useTicketDetails();

    const routeParams = useParams();

    const createAction = routeParams.ticketId && routeParams.ticketId === TICKET.NEW;

    useEffect(() => {
        if (routeParams.ticketId && !createAction) {
            handleRetrieveTicket({
                id: routeParams.ticketId,
                onRetrieveSuccess: () => { }
            });

            handleRetrieveChangeLogs({
                id: routeParams.ticketId,
                onRetrieveSuccess: (changeLogs) => { }
            });
        }
    }, [routeParams.ticketId]);

    const navigate = useNavigate();

    const [ticketDialogVisible, setTicketDialogVisible] = useState<boolean>(false);

    const _handleSubmit = (ticket: Ticket, ticketId?: string) => {
        !ticketId
            ?
            handleCreateTicket({
                ticket: ticket,
                onSuccess: () => navigate(RouterSettings.PATHS.Dashboard.Ticket.root)
            })
            :
            handleUpdateTicket({
                id: ticketId,
                ticket: ticket,
                onSuccess: () => setTicketDialogVisible(true)
            });
    }

    const iaiSystem = useSelector((state: RootState) => state.system);

    return (
        <IAIApplicationLayout
            icon={'Ticket'}
            heading={createAction ? 'Nuovo Ticket' : `Ticket #${routeParams.ticketId}`}
            secondaryHeading={createAction ? 'Crea un nuovo Ticket' : 'Gestisci il ticket'}
        >
            <div className="ticket-details-page-container">
                <div className="row">
                    {!createAction &&
                        <React.Fragment>
                            <div className="col-7">
                                <IAIShimmer type={'shimmer-heading'} loading={!retrieveTicket || retrieveTicketLoading}>
                                    <span className="ticket-details-data ticket-details-heading">
                                        {`Richiedente: ${retrieveTicket?.requestedBy}`}
                                    </span>
                                    <span className="ticket-details-data">
                                        {`Creata il: ${DateTimeEnchanter.formatCustomDate(retrieveTicket?.createdAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}`}
                                    </span>
                                </IAIShimmer>
                            </div>
                            <div className="col-5 text-end">
                                <IAIShimmer type={'shimmer-heading'} loading={!retrieveTicket || retrieveTicketLoading}>
                                    <span className="ticket-details-data ticket-details-heading">
                                        {`Stato: ${DataEnchanter.textByStatusEnum(retrieveTicket?.status || '')}`}
                                    </span>

                                    {retrieveTicket?.status === 'closed'
                                        ?
                                        <span className="ticket-details-data">
                                            {`Risolto il: ${DateTimeEnchanter.formatCustomDate(retrieveTicket?.closedAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}`}
                                        </span>
                                        :
                                        retrieveTicket?.reopenAt && (
                                            <span className="ticket-details-data">
                                                {`Riaperto il: ${DateTimeEnchanter.formatCustomDate(retrieveTicket?.reopenAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}`}
                                            </span>
                                        )
                                    }
                                </IAIShimmer>
                            </div>
                        </React.Fragment>
                    }

                    <Separator className="my-3" />

                    <div className={`${!createAction ? 'col-7' : 'col-12'}`}>
                        <TicketDetailsForm
                            ticket={retrieveTicket}
                            changeLogs={retrieveChangeLogs}
                            dispatchManageAction={routeParams.ticketId !== TICKET.NEW}
                            loading={
                                retrieveTicketLoading ||
                                retrieveChangeLogsLoading ||
                                createTicketLoading ||
                                updateTicketLoading ||
                                !iaiSystem.areasInitialized
                            }
                            onSubmit={_handleSubmit}
                        />
                    </div>

                    {!createAction &&
                        <div className="col-5">
                            <TicketChat ticketId={routeParams.ticketId} />
                        </div>
                    }
                </div>

                <IAIDialog
                    dialogProps={{
                        hidden: !ticketDialogVisible
                    }}
                    title={'Ticket modificato correttamente'}
                    subText={`Stai per ritornare alla pagina di riepilogo. Vuoi continuare?`}
                    disabled={
                        retrieveTicketLoading ||
                        retrieveChangeLogsLoading ||
                        createTicketLoading ||
                        updateTicketLoading ||
                        !iaiSystem.areasInitialized
                    }
                    confirmText={'Continua'}
                    dismissText={'Annulla'}
                    onConfirm={() => {
                        navigate(RouterSettings.PATHS.Dashboard.Ticket.root)
                    }}
                    onDismiss={() => setTicketDialogVisible(false)}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default TicketDetailsPage;