import React from 'react';

import { Text } from '@fluentui/react';

import './iai-empty-content.scss';

interface IIAIEmptyContent {
    title: string;
    img?: string;
    description?: string;
}

const IAIEmptyContent: React.FunctionComponent<IIAIEmptyContent> = ({ title, description, img, ...other }) => (
    <div className="iai-empty-content-container">
        <img
            alt="empty content"
            src={
                img ||
                '/assets/illustration_empty_content.svg'
            }
            className="mb-3"
            style={{ height: 150 }}
        />

        <Text block>
            {title}
        </Text>
    </div>
);

export default IAIEmptyContent;