import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import _ from 'lodash';

import { Dropdown, Panel, PanelType, Stack, TextField } from '@fluentui/react';

import { RootState } from '../../../../redux/application/redux-store';
import { Solution } from '../../../../api-client';

import { IAITextEditor } from '../../../../common/components/IAIControls/IAITextEditor';
import SolutionFormFooter from './components/SolutionFormFooter/solution-form-footer';

interface ISolutionFormDrawer {
    disabled?: boolean;
    drawerVisible?: boolean;
    solution?: Solution;
    onDrawerDismiss: () => void;
    onSubmit: (solution: Solution, solutionId?: string) => void;
}

const SolutionFormDrawer: React.FunctionComponent<ISolutionFormDrawer> = ({ disabled, drawerVisible, solution, onDrawerDismiss, onSubmit }) => {
    const {
        control,
        formState: { errors },
        reset,
        handleSubmit
    } = useForm<Solution>();

    const _handleSubmit: SubmitHandler<Solution> = (values: Solution) => onSubmit(values, values.id);

    useEffect(() => {
        solution
            ? reset(solution)
            : reset({
                id: '',
                displayName: '',
                title: '',
                department: undefined,
                description: ''
            });
    }, [solution]);

    const _handleDismissForm = () => {
        reset({
            id: '',
            displayName: '',
            title: '',
            department: undefined,
            description: ''
        });
        onDrawerDismiss();
    }

    const iaiSystem = useSelector((state: RootState) => state.system);

    return (
        <Panel
            customWidth={'750px'}
            headerText={solution && solution.id ? 'Modifica risposta rapida' : 'Inserisci risposta rapida'}
            type={PanelType.custom}
            isFooterAtBottom={true}
            isOpen={drawerVisible}
            layerProps={{ styles: { root: { zIndex: 0 } } }}
            overlayProps={{ styles: { root: { zIndex: 0 } } }}
            onDismiss={_handleDismissForm}
            onRenderFooterContent={() => (
                <SolutionFormFooter
                    disabled={disabled}
                    onDismiss={_handleDismissForm}
                    onSubmit={handleSubmit(_handleSubmit)}
                />
            )}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={'Titolo:'}
                            placeholder={'Inserisci il titolo...'}
                            errorMessage={errors.title && "Il titolo é obbligatorio!"}
                            disabled={disabled}
                            required
                        />
                    )}
                />

                <Controller
                    name="department"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Dropdown
                            {...field}
                            label={'Dipartimento:'}
                            placeholder={'Seleziona il dipartimento...'}
                            /**/
                            options={_.map(iaiSystem.departments, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                            disabled={disabled || !iaiSystem.departmentsInitialized}
                            required
                            selectedKey={field.value && field.value?.key || null}
                            onChange={(ev, option) => {
                                if (option) {
                                    let selectedDepartment = _.find(iaiSystem.departments, entry => entry.key === option.key);

                                    field.onChange(selectedDepartment);
                                }
                            }}
                        />
                    )}
                />

                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                        <IAITextEditor
                            label={'Inserisci la descrizione:'}
                            errorMessage={errors.description && "La descrizione é obbligatoria!"}
                            disabled={disabled}
                            required
                            textEditorProps={field}
                        />
                    )}
                />
            </Stack>
        </Panel>
    );
}

export default SolutionFormDrawer;