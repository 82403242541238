import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface ILoginPage {
    loginPageButton: string;
    loginPageContainer: string;
    loginPageLogo: string;
    loginPageH2: string;
    loginPageSpinner: string;
}

export const useClassNames = (): ILoginPage => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        loginPageButton: {
            height: 40,
            fontSize: '1rem'
        },
        loginPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground,
            height: '100%',
            padding: '50px 100px',
            textAlign: 'center'
        },
        loginPageH2: {
            color: '#5f5f5f',
            fontSize: '1rem',
            fontWeight: 400,
            letterSpacing: '2.5',
            lineHeight: '1.5',
            textAlign: 'center',
            textTransform: 'uppercase',
            margin: 0,
            marginBottom: 35
        },
        loginPageLogo: {
            width: '100%',
            marginBottom: 35
        },
        loginPageSpinner: {
            selectors: {
                '.ms-Spinner-circle': {
                    width: 100,
                    height: 100,
                    borderWidth: 2.5,
                }
            }
        }
    });
}