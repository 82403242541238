import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { DefaultButton, PrimaryButton, Dropdown, Separator, Stack, TextField } from '@fluentui/react';

import { RootState } from '../../../../../redux/application/redux-store';
import {
    Department,
    Ticket,
    TicketChangeLog,
    TicketModeEnum,
    TicketPriorityEnum,
    TicketStatusEnum,
    User
} from '../../../../../api-client';
import { RouterSettings } from '../../../../../utils/router';
import { useDepartmentMembers } from './hooks/useDepartmentMembers';

import { IAITextEditor } from '../../../../../common/components/IAIControls/IAITextEditor';
import TicketAttachment from '../TicketAttachment/ticket-attachment';
import ChangeLogsAccordion from './components/ChangeLogsAccordion/change-logs-accordion';

import './ticket-details-form.scss';

interface ITicketDetailsForm {
    loading?: boolean;
    dispatchManageAction?: boolean;
    ticket?: Ticket;
    changeLogs?: TicketChangeLog[];
    onSubmit: (ticket: Ticket, ticketId?: string) => void;
}

const TicketDetailsForm: React.FunctionComponent<ITicketDetailsForm> = ({
    loading,
    dispatchManageAction,
    ticket,
    changeLogs,
    onSubmit
}) => {
    const {
        control,
        formState: { errors, isSubmitting },
        reset,
        setValue,
        handleSubmit
    } = useForm<Ticket>();

    const _handleSubmit: SubmitHandler<Ticket> = values => {
        onSubmit({
            ...values,
            mode: values.mode || TicketModeEnum.Level01,
            priority: values.priority || TicketPriorityEnum.Medium,
            status: values.status || TicketStatusEnum.Open
        }, values.id);
    };

    const {
        handleRetrieveMembers,
        retrieveMembers,
        retrieveMembersLoading
    } = useDepartmentMembers();

    const iaiSystem = useSelector((state: RootState) => state.system);

    const [departmentsByArea, setDepartmentsByArea] = useState<Department[]>([]);
    const [agentsByDepartment, setAgentsByDepartment] = useState<User[]>([]);

    useEffect(() => {
        if (ticket && iaiSystem.areasInitialized) {
            handleRetrieveMembers({ id: ticket.department?.groupId! });

            let selectedArea = _.find(iaiSystem.areas, entry => entry.key === ticket.area?.key);

            setDepartmentsByArea(selectedArea?.departments || []);

            reset(ticket);
        }
    }, [ticket, iaiSystem.areasInitialized]);

    useEffect(() => {
        if (retrieveMembers && !!retrieveMembers.length) {
            setAgentsByDepartment(retrieveMembers);
        }
    }, [retrieveMembers]);

    const navigate = useNavigate();

    const iaiAccount = useSelector((state: RootState) => state.account);

    const _changeFormUnauthorize = () => (
        ticket &&
        ticket.status === 'closed' &&
        (iaiAccount.role === 'departmentAgent' || iaiAccount.role === 'user')
    );

    const disableCondition = loading || _changeFormUnauthorize() || isSubmitting;

    return (
        <div className="ticket-details-form-container">
            <Stack tokens={{ childrenGap: 15 }}>
                {dispatchManageAction && (
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <Controller
                                name="mode"
                                control={control}
                                defaultValue={ticket?.mode || 'level01'}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        label={'Livello:'}
                                        placeholder={'Seleziona il livello...'}
                                        errorMessage={errors.mode && 'Il livello é obbligatorio!'}
                                        /**/
                                        disabled={disableCondition}
                                        required
                                        options={[
                                            { key: TicketModeEnum.Level01, text: 'Livello 0' },
                                            { key: TicketModeEnum.Level02, text: 'Livello 1' },
                                            { key: TicketModeEnum.Level03, text: 'Livello 2' }
                                        ]}
                                        selectedKey={field.value || null}
                                        onChange={(ev, option) => option && field.onChange(option.key)}
                                    />
                                )}
                            />
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-12 col-md-4">
                        <Controller
                            name="area"
                            control={control}
                            defaultValue={ticket?.area || undefined}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    label={'Area:'}
                                    placeholder={'Seleziona l\'area...'}
                                    errorMessage={errors.area && 'L\'area é obbligatoria!'}
                                    /**/
                                    disabled={disableCondition}
                                    required
                                    options={_.map(iaiSystem.areas, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                                    selectedKey={field.value?.key || null}
                                    onChange={(ev, option) => {
                                        setDepartmentsByArea([]);
                                        setAgentsByDepartment([]);

                                        setValue("agent", undefined);

                                        if (option) {
                                            let selectedArea = _.find(iaiSystem.areas, entry => entry.key === option.key);

                                            field.onChange({
                                                tenantId: selectedArea?.tenantId,
                                                id: selectedArea?.id,
                                                displayName: selectedArea?.displayName,
                                                key: selectedArea?.key,
                                                text: selectedArea?.text
                                            });

                                            let selectedAreaDepartments = selectedArea?.departments || [];

                                            setDepartmentsByArea(selectedAreaDepartments);

                                            if (selectedAreaDepartments.length === 1) {
                                                setValue("department", selectedAreaDepartments[0]);

                                                handleRetrieveMembers({ id: selectedAreaDepartments[0].groupId! });
                                            }
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <Controller
                            name="department"
                            control={control}
                            defaultValue={ticket?.department || undefined}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    label={'Dipartimento:'}
                                    placeholder={'Seleziona il dipartimento...'}
                                    errorMessage={errors.department && 'Il dipartimento é obbligatorio!'}
                                    /**/
                                    disabled={disableCondition || !departmentsByArea.length}
                                    required
                                    options={_.map(departmentsByArea, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                                    selectedKey={field.value?.key || null}
                                    onChange={(ev, option) => {
                                        setAgentsByDepartment([]);

                                        setValue("agent", undefined);

                                        if (option) {
                                            let selectedDepartment = _.find(departmentsByArea, entry => entry.key === option.key);

                                            field.onChange(selectedDepartment);

                                            handleRetrieveMembers({ id: selectedDepartment?.groupId! });
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>

                    {dispatchManageAction && (
                        <div className="col-12 col-md-4">
                            <Controller
                                name="agent"
                                control={control}
                                defaultValue={ticket?.agent || undefined}
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        label={'Assegnato a:'}
                                        placeholder={'Seleziona l\'operatore...'}
                                        /**/
                                        disabled={(
                                            disableCondition ||
                                            retrieveMembersLoading ||
                                            !agentsByDepartment.length
                                        )}
                                        options={_.map(agentsByDepartment, entry => ({ key: `${entry.key}`, text: `${entry.text}` }))}
                                        selectedKey={field.value?.key || null}
                                        onChange={(ev, option) => {
                                            if (option) {
                                                let selectedAgent = _.find(agentsByDepartment, entry => entry.key === option.key);

                                                field.onChange(selectedAgent);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </div>
                    )}
                </div>

                <div className="row">
                    {dispatchManageAction && (
                        <div className="col-12 col-md-4">
                            <Controller
                                name="status"
                                control={control}
                                defaultValue={ticket?.status || 'open'}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        label={'Stato:'}
                                        placeholder={'Seleziona lo stato...'}
                                        errorMessage={errors.status && "Lo stato é obbligatorio!"}
                                        /**/
                                        disabled={disableCondition}
                                        required
                                        options={[
                                            { key: TicketStatusEnum.Open, text: 'Aperto' },
                                            { key: TicketStatusEnum.Working, text: 'In Lavorazione' },
                                            { key: TicketStatusEnum.Pending, text: 'Preso in Carico' },
                                            { key: TicketStatusEnum.Closed, text: 'Risolto' }
                                        ]}
                                        selectedKey={field.value}
                                        onChange={(ev, option) => option && field.onChange(option.key)}
                                    />
                                )}
                            />
                        </div>
                    )}

                    <div className="col-12 col-md-4">
                        <Controller
                            name="priority"
                            control={control}
                            defaultValue={ticket?.priority || 'low'}
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    label={'Priorità:'}
                                    placeholder={'Seleziona la priorità...'}
                                    /**/
                                    disabled={disableCondition}
                                    options={[
                                        { key: TicketPriorityEnum.Low, text: 'Bassa' },
                                        { key: TicketPriorityEnum.Medium, text: 'Media' },
                                        { key: TicketPriorityEnum.High, text: 'Alta' }
                                    ]}
                                    selectedKey={field.value}
                                    onChange={(ev, option) => option && field.onChange(option.key)}
                                />
                            )}
                        />
                    </div>
                </div>

                <Separator />
            </Stack>

            <Stack tokens={{ childrenGap: 15 }}>
                <Controller
                    name="subject"
                    control={control}
                    defaultValue={ticket?.subject || ''}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={'Oggetto richiesta:'}
                            placeholder={'Inserisci l\'oggetto...'}
                            errorMessage={errors.subject && "L\'oggetto é obbligatorio!"}
                            /**/
                            disabled={disableCondition}
                            required
                        />
                    )}
                />

                <Controller
                    name="description"
                    control={control}
                    defaultValue={ticket?.description || ''}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <IAITextEditor
                            label={'Inserisci la descrizione:'}
                            errorMessage={errors.description && "La descrizione é obbligatoria!"}
                            /**/
                            disabled={disableCondition}
                            required
                            textEditorProps={field}
                        />
                    )}
                />

                {dispatchManageAction && (
                    <Stack tokens={{ childrenGap: 25 }}>
                        <Controller
                            name="note"
                            control={control}
                            defaultValue={ticket?.note || ''}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={'Note aggiuntive:'}
                                    placeholder={'Inserisci le note...'}
                                    /**/
                                    disabled={disableCondition}
                                    multiline
                                    rows={5}
                                    resizable={false}
                                />
                            )}
                        />

                        {(iaiAccount.role === 'administrator' || iaiAccount.role === 'departmentManager') && (
                            <ChangeLogsAccordion changeLogs={changeLogs} dataLoading={disableCondition} />
                        )}
                    </Stack>
                )}

                <Separator />

                <TicketAttachment ticketId={ticket?.id} disabled={disableCondition} />

                <Separator />

                <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: 15 }}>
                    <DefaultButton
                        disabled={disableCondition}
                        iconProps={{ iconName: 'Cancel' }}
                        styles={{ root: { marginRight: 10 } }}
                        onClick={() => { navigate(RouterSettings.PATHS.Dashboard.Ticket.root); }}
                    >
                        Annulla
                    </DefaultButton>
                    <PrimaryButton
                        type="submit"
                        disabled={disableCondition}
                        iconProps={{ iconName: 'CheckMark' }}
                        onClick={handleSubmit(_handleSubmit)}
                    >
                        Salva Richiesta
                    </PrimaryButton>
                </Stack>
            </Stack>
        </div>
    );
}

export default TicketDetailsForm;