export class SessionStorageHelper {
    public static STORAGE_KEYS = {
        IAI_SYSTEM_ACCOUNT: 'IAI_SYSTEM_ACCOUNT',
        IAI_SYSTEM_AGENT: 'IAI_SYSTEM_AGENT',
        IAI_SYSTEM_AGENT_INIT: 'IAI_SYSTEM_AGENT_INIT',
        IAI_SYSTEM_AREA: 'IAI_SYSTEM_AREA',
        IAI_SYSTEM_AREA_INIT: 'IAI_SYSTEM_AREA_INIT',
        IAI_SYSTEM_DEPARTMENT: 'IAI_SYSTEM_DEPARTMENT',
        IAI_SYSTEM_DEPARTMENT_INIT: 'IAI_SYSTEM_DEPARTMENT_INIT',
        IAI_SYSTEM_NOTICES: 'IAI_SYSTEM_NOTICES'
    };
    public static createItem = (key: string, value: any) => (
        sessionStorage.setItem(key, JSON.stringify(value))
    );
    public static retrieveItem = (key: string) => {
        const storedItem = sessionStorage.getItem(key);

        if (!storedItem) {
            return null;
        };

        return JSON.parse(storedItem);
    };
    public static deleteItem = (key: string) => (
        sessionStorage.removeItem(key)
    );
}