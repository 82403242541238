import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { Group } from '../../../../api-client';
import { SystemApiClient } from '../../../../providers/api-provider';
import { NotificationHelper } from '../../../../utils/notification';
import { IHookCallback } from '../../../../common/models';
import { useSystem } from '../../../../common/hooks/useSystem';

export const useGroup = () => {
    const {
        instance
    } = useMsal();

    const [groups, setGroups] = useState<Group[]>();
    const [groupsLoading, setGroupsLoading] = useState<boolean>(false);

    const handleGroups = async () => {
        setGroupsLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.getGroups()
            .then((response: any) => {
                setGroups(response.data);
            })
            .catch((reason: any) => {
                setGroups([]);

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero dei gruppi.',
                    title: 'Impossibile recuperare i gruppi!'
                });
            })
            .finally(() => {
                setGroupsLoading(false);
            });
    }

    // CRUD Operations

    const {
        handleSystemDepartments
    } = useSystem();

    interface ICreateGroup extends IHookCallback {
        group: Group;
    }

    const [createGroupLoading, setCreateGroupLoading] = useState<boolean>(false);

    const handleCreateGroup = async (params: ICreateGroup) => {
        setCreateGroupLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.insertGroup(params.group)
            .then((response: any) => {
                handleSystemDepartments();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Associazione creata con successo.',
                    title: 'Associazione creata!'
                });
            })
            .catch((reason: any) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante l\'associazione.',
                    title: 'Impossibile creare l\'associazione!'
                });
            })
            .finally(() => {
                setCreateGroupLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteGroup extends IHookCallback {
        id: string;
    }

    const [deleteGroupLoading, setDeleteGroupLoading] = useState<boolean>(false);

    const handleDeleteGroup = async (params: IDeleteGroup) => {
        setDeleteGroupLoading(true);

        const clientApi = await SystemApiClient(instance);

        clientApi.deleteGroup(params.id)
            .then((response: any) => {
                handleSystemDepartments();

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Associazione cancellata con successo.',
                    title: 'Associazione cancellata!'
                });
            })
            .catch((reason: any) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la cancellazione dell\'associazione.',
                    title: 'Impossibile cancellare l\'associazione!'
                });
            })
            .finally(() => {
                setDeleteGroupLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleGroups,
        groups,
        groupsLoading,
        handleCreateGroup,
        createGroupLoading,
        handleDeleteGroup,
        deleteGroupLoading
    }
}