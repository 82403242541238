import { useSelector } from 'react-redux';

import { mergeStyleSets } from '@fluentui/react';

interface IIAILoadingScreenClassNames {
    loadingScreenContainer: string;
}

export const useClassNames = (): IIAILoadingScreenClassNames => {
    return mergeStyleSets({
        loadingScreenContainer: {
            background: 'rgba(0, 0, 0, .75)',
            backdropFilter: 'blur(2.5px)'
        }
    });
}