import React from 'react';

import {
    IconButton,
    Stack,
    Text
} from '@fluentui/react';

import { useClassNames } from './iai-pagination.classNames';

type IPaginationModule = {
    totalCount: number;
    page: number;
    limit: number;
    onPageChage: (newPage: number) => void;
}

const IAIPagination: React.FunctionComponent<IPaginationModule> = ({
    totalCount,
    page,
    limit,
    onPageChage
}) => {
    const {
        paginationContainer
    } = useClassNames();

    const _handlePageSizeCount = () => {
        const currentPageSize = (((page - 1) * limit) + limit);

        if (totalCount < currentPageSize) return totalCount;

        return currentPageSize;
    }

    const _handleFistPageClick = () => onPageChage(1);

    const _handlePrevPageClick = () => onPageChage(page - 1);

    const _handleNextPageClick = () => onPageChage(page + 1);

    const _handleLastPageClick = () => onPageChage(Math.ceil(totalCount / limit));

    const hasNext = totalCount !== 0 && page !== Math.ceil(totalCount / limit);
    const hasPrev = page !== 1;

    return (
        <Stack
            grow
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={`iai-pagination-container ${paginationContainer}`}
        >
            <Stack grow={1} horizontalAlign="start">
                <Text block variant="small">
                    {`${(((page - 1) * limit) + 1)} - ${_handlePageSizeCount()} of ${totalCount} elementi`}
                </Text>
            </Stack>
            <Stack grow={1} horizontal horizontalAlign="end" verticalAlign="center">
                <IconButton
                    iconProps={{ iconName: 'DoubleChevronLeft' }}
                    disabled={!hasPrev}
                    onClick={_handleFistPageClick}
                />
                <IconButton
                    iconProps={{ iconName: 'ChevronLeft' }}
                    disabled={!hasPrev}
                    onClick={_handlePrevPageClick}
                />
                <Text block variant="small" styles={{ root: { margin: '0 10px' } }}>
                    {`Pagina ${page}`}
                </Text>
                <IconButton
                    iconProps={{ iconName: 'ChevronRight' }}
                    disabled={!hasNext}
                    onClick={_handleNextPageClick}
                />
                <IconButton
                    iconProps={{ iconName: 'DoubleChevronRight' }}
                    disabled={!hasNext}
                    onClick={_handleLastPageClick}
                />
            </Stack>
        </Stack>
    );
};

export default IAIPagination;