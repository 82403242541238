import React from 'react';
import _ from 'lodash';

import { Icon, Stack, TooltipHost } from '@fluentui/react';

import { DataEnchanter } from '../../../../../../utils/data-enchanters';
import { useClassNames } from './iai-status-column.classNames';

interface IIAIStatusColumn {
    status?: string;
}

const IAIStatusColumn: React.FunctionComponent<IIAIStatusColumn> = ({ status }) => {
    const {
        statusIcon
    } = useClassNames({ status });

    return (
        <TooltipHost
            content={(
                <Stack>
                    {_.map(DataEnchanter.colorblindByStatusEnum(status || ''), (dataItem, dataIndex) => (
                        <Stack key={dataIndex} horizontal verticalAlign="center">
                            <Icon
                                iconName={'StatusCircleInner'}
                                className={statusIcon}
                                styles={{ root: { color: dataItem } }}
                            />
                            {DataEnchanter.textByStatusEnum(status || '')}
                        </Stack>
                    ))}
                </Stack>
            )}
        >
            <Stack horizontal verticalAlign="center">
                <Icon iconName={'StatusCircleInner'} className={statusIcon} />
                {DataEnchanter.textByStatusEnum(status || '')}
            </Stack>
        </TooltipHost>
    );
}

export default IAIStatusColumn;