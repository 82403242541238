import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { Icon, Text, TooltipHost } from '@fluentui/react';

import { useClassNames } from './iai-widget-icon.classNames';

import './iai-widget-icon.scss';

interface IIAIWidgetIcon {
    icon: string;
    title: string;
    tooltip: string;
    to?: string;
    onClick?: () => void;
}

const IAIWidgetIcon: React.FunctionComponent<IIAIWidgetIcon> = ({
    icon,
    title,
    tooltip,
    to,
    onClick
}) => {
    const {
        widgetContainer,
        widgetIcon
    } = useClassNames();

    return (
        <TooltipHost content={tooltip}>
            {to && (
                <Link className={`iai-widget-icon-container ${widgetContainer}`} to={to}>
                    <Icon className={`widget-icon ${widgetIcon}`} iconName={icon} />

                    <Text block variant="mediumPlus">
                        {title}
                    </Text>
                </Link>
            )}

            {onClick && (
                <div className={`iai-widget-icon-container ${widgetContainer}`} onClick={onClick}>
                    <Icon className={`widget-icon ${widgetIcon}`} iconName={icon} />

                    <Text block variant="mediumPlus">
                        {title}
                    </Text>
                </div>
            )}
        </TooltipHost>
    );
}

export default IAIWidgetIcon;