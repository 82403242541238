import React, { useEffect, useState } from 'react';

import { IIconProps } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';

import { Group } from '../../../api-client';
import { SortingComparer } from '../../../utils/data-comparer';
import { useGroup } from './hooks/useGroup';
import { useClassNames } from './groups-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAIDataTable } from '../../../common/components/IAIDataTable';
import { IAIDialog } from '../../../common/components/IAIDialog';

const GroupsPage: React.FunctionComponent = () => {
    const {
        handleGroups,
        groups,
        groupsLoading,
        handleCreateGroup,
        createGroupLoading,
        handleDeleteGroup,
        deleteGroupLoading
    } = useGroup();

    const {
        groupsPageContainer,
        tableCell,
    } = useClassNames();

    useEffect(() => {
        handleGroups();
    }, []);

    const _handleSearchGroup = (entry: Group, searchValue: string): boolean => (
        entry.displayName?.toLowerCase().search(searchValue) !== -1
    );

    const [delegatedGroup, setDelegatedGroup] = useState<Group>();
    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);
    const [groupDepartmentAssociation, setGroupDepartmentAssociation] = useState<boolean>(false);

    const CheckboxCompositeReversed: IIconProps = { iconName: 'CheckboxCompositeReversed' };
    const Checkbox: IIconProps = { iconName: 'Checkbox' };

    const onOperationCompleted = () => {
        setDeleteDialogVisible(false);
        setDelegatedGroup(undefined);
        handleGroups();
    };

    return (
        <IAIApplicationLayout
            icon={'EngineeringGroup'}
            heading={'Gruppi / Dipartimenti'}
            secondaryHeading={'Gestisci associazione Gruppi -> Dipartimenti.'}
        >
            <div className={groupsPageContainer}>
                <IAIDataTable
                    columns={[
                        {
                            key: 'group_action__',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: any, dataIndex?: number) => (
                                <div className="text-center">
                                    {element.isDepartment === true
                                        ?
                                        <IconButton iconProps={CheckboxCompositeReversed}
                                            title="CheckboxCompositeReversed"
                                            ariaLabel="CheckboxCompositeReversed"
                                            onClick={() => {
                                                element.isDepartment = false;
                                                setGroupDepartmentAssociation(false);
                                                setDelegatedGroup(element);
                                                setDeleteDialogVisible(true);
                                            }}
                                        />
                                        :
                                        <IconButton iconProps={Checkbox}
                                            title="Checkbox"
                                            ariaLabel="Checkbox"
                                            onClick={() => {
                                                element.isDepartment = true;
                                                setGroupDepartmentAssociation(true);
                                                setDelegatedGroup(element);
                                                setDeleteDialogVisible(true);
                                            }}
                                        />
                                    }
                                </div>
                            )
                        },
                        {
                            key: 'group_displayName__',
                            fieldName: 'displayName',
                            name: 'Nome',
                            className: tableCell,
                            minWidth: 250,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer
                        },
                        {
                            key: 'group_mailAddress__',
                            fieldName: 'mailAddress',
                            name: 'Indirizzo Email',
                            className: tableCell,
                            minWidth: 250,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer
                        }
                    ]}
                    dataSource={groups || []}
                    dataLoading={groupsLoading}
                    emptyContentLabel="Nessuna richiesta disponibile"
                    searchable
                    onSearch={_handleSearchGroup}
                />

                <IAIDialog
                    dialogProps={{
                        hidden: !deleteDialogVisible,
                        onDismissed: onOperationCompleted
                    }}
                    title={groupDepartmentAssociation === true ? 'Associa Gruppo / Dipartimento' : 'Dissocia Gruppo / Dipartimento'}
                    subText={groupDepartmentAssociation === true ? `Stai per associare Gruppo / Dipartimento. Vuoi continuare?` : `Stai per eliminare l'associazione Gruppo / Dipartimento. Vuoi continuare?`}
                    disabled={deleteGroupLoading}
                    confirmText={groupDepartmentAssociation === true ? 'Associa' : 'Dissocia'}
                    dismissText={'Annulla'}
                    onConfirm={() => {
                        if (delegatedGroup && delegatedGroup.id) {
                            if (groupDepartmentAssociation) {
                                handleCreateGroup({
                                    group: delegatedGroup,
                                    onSuccess: onOperationCompleted,
                                    onError: onOperationCompleted,
                                    onAlways: onOperationCompleted
                                })
                            } else {
                                handleDeleteGroup({
                                    id: delegatedGroup.id,
                                    onSuccess: onOperationCompleted,
                                    onError: onOperationCompleted,
                                    onAlways: onOperationCompleted
                                });
                            }
                        }
                    }}
                    onDismiss={onOperationCompleted}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default GroupsPage;