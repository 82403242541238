/* tslint:disable */
/* eslint-disable */
/**
 * ServiceDesk
 * Service Desk backend API. 
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: dev@intranet.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Agent
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    'mailAddress'?: string;
}
/**
 * 
 * @export
 * @interface AgentAllOf
 */
export interface AgentAllOf {
    /**
     * 
     * @type {string}
     * @memberof AgentAllOf
     */
    'mailAddress'?: string;
}
/**
 * Issue solution
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Announcement
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'availableFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'availableTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface AnnouncementAllOf
 */
export interface AnnouncementAllOf {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementAllOf
     */
    'availableFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementAllOf
     */
    'availableTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementAllOf
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementAllOf
     */
    'title'?: string;
}
/**
 * Area
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Area
     */
    'archived'?: boolean;
    /**
     * 
     * @type {Array<Department>}
     * @memberof Area
     */
    'departments'?: Array<Department>;
}
/**
 * 
 * @export
 * @interface AreaAllOf
 */
export interface AreaAllOf {
    /**
     * 
     * @type {Array<Department>}
     * @memberof AreaAllOf
     */
    'departments'?: Array<Department>;
}
/**
 * User department
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Department
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'mailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'groupId'?: string;
}
/**
 * 
 * @export
 * @interface DepartmentAllOf
 */
export interface DepartmentAllOf {
    /**
     * 
     * @type {string}
     * @memberof DepartmentAllOf
     */
    'mailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentAllOf
     */
    'groupId'?: string;
}
/**
 * Response modeled over ASP.NET built-in error type based on https://datatracker.ietf.org/doc/html/rfc7807
 * @export
 * @interface ErrorResult
 */
export interface ErrorResult {
    /**
     * 
     * @type {string}
     * @memberof ErrorResult
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResult
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorResult
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResult
     */
    'detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResult
     */
    'instance'?: string;
}
/**
 * Event Receiver
 * @export
 * @interface EventReceiver
 */
export interface EventReceiver {
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventReceiver
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventReceiver
     */
    'archived'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiver
     */
    'onCreating'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiver
     */
    'onCreated'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiver
     */
    'onUpdating'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiver
     */
    'onUpdated'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiver
     */
    'onDeleting'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiver
     */
    'onDeleted'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EventReceiverAllOf
 */
export interface EventReceiverAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiverAllOf
     */
    'onCreating'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiverAllOf
     */
    'onCreated'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiverAllOf
     */
    'onUpdating'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiverAllOf
     */
    'onUpdated'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiverAllOf
     */
    'onDeleting'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventReceiverAllOf
     */
    'onDeleted'?: Array<string>;
}
/**
 * Azure AD user group
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'mailAddress'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Group
     */
    'isDepartment'?: boolean;
}
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'archived'?: boolean;
}
/**
 * 
 * @export
 * @interface ItemAllOf
 */
export interface ItemAllOf {
    /**
     * 
     * @type {string}
     * @memberof ItemAllOf
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemAllOf
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemAllOf
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemAllOf
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemAllOf
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemAllOf
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemAllOf
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItemAllOf
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemAllOf
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ItemId
 */
export interface ItemId {
    /**
     * 
     * @type {string}
     * @memberof ItemId
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemId
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemId
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemId
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemId
     */
    'text'?: string;
}
/**
 * Meeting Request
 * @export
 * @interface MeetingRequest
 */
export interface MeetingRequest {
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingRequest
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'startDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    'endDateTime'?: string;
}
/**
 * 
 * @export
 * @interface MeetingRequestAllOf
 */
export interface MeetingRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof MeetingRequestAllOf
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequestAllOf
     */
    'startDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequestAllOf
     */
    'endDateTime'?: string;
}
/**
 * Application user profile
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'role'?: ProfileRoleEnum;
}

export const ProfileRoleEnum = {
    Administrator: 'administrator',
    DepartmentManager: 'departmentManager',
    DepartmentAgent: 'departmentAgent',
    User: 'user'
} as const;

export type ProfileRoleEnum = typeof ProfileRoleEnum[keyof typeof ProfileRoleEnum];

/**
 * 
 * @export
 * @interface ProfileAllOf
 */
export interface ProfileAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProfileAllOf
     */
    'role'?: ProfileAllOfRoleEnum;
}

export const ProfileAllOfRoleEnum = {
    Administrator: 'administrator',
    DepartmentManager: 'departmentManager',
    DepartmentAgent: 'departmentAgent',
    User: 'user'
} as const;

export type ProfileAllOfRoleEnum = typeof ProfileAllOfRoleEnum[keyof typeof ProfileAllOfRoleEnum];

/**
 * Property
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Property
     */
    'archived'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Property
     */
    'values'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PropertyAllOf
 */
export interface PropertyAllOf {
    /**
     * 
     * @type {string}
     * @memberof PropertyAllOf
     */
    'displayName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyAllOf
     */
    'values'?: Array<string>;
}
/**
 * Selected Area
 * @export
 * @interface SelectedArea
 */
export interface SelectedArea {
    /**
     * 
     * @type {string}
     * @memberof SelectedArea
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedArea
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedArea
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedArea
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedArea
     */
    'text'?: string;
}
/**
 * Setting
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Setting
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'title'?: string;
    /**
     * 
     * @type {object}
     * @memberof Setting
     */
    'value'?: object;
}
/**
 * 
 * @export
 * @interface SettingAllOf
 */
export interface SettingAllOf {
    /**
     * 
     * @type {string}
     * @memberof SettingAllOf
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingAllOf
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SettingAllOf
     */
    'key'?: string;
    /**
     * 
     * @type {object}
     * @memberof SettingAllOf
     */
    'value'?: object;
}
/**
 * Issue solution
 * @export
 * @interface Solution
 */
export interface Solution {
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Solution
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    'title'?: string;
    /**
     * 
     * @type {Department}
     * @memberof Solution
     */
    'department'?: Department;
}
/**
 * 
 * @export
 * @interface SolutionAllOf
 */
export interface SolutionAllOf {
    /**
     * 
     * @type {string}
     * @memberof SolutionAllOf
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionAllOf
     */
    'title'?: string;
    /**
     * 
     * @type {Department}
     * @memberof SolutionAllOf
     */
    'department'?: Department;
}
/**
 * Ticket
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Ticket
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'closedAt'?: string;
    /**
     * 
     * @type {SelectedArea}
     * @memberof Ticket
     */
    'area'?: SelectedArea;
    /**
     * 
     * @type {Department}
     * @memberof Ticket
     */
    'department'?: Department;
    /**
     * 
     * @type {Agent}
     * @memberof Ticket
     */
    'agent'?: Agent;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'requestedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'mode'?: TicketModeEnum;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'status'?: TicketStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'priority'?: TicketPriorityEnum;
    /**
     * 
     * @type {Array<TicketAllOfProperties>}
     * @memberof Ticket
     */
    'properties'?: Array<TicketAllOfProperties>;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'reopenCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'reopenAt'?: string;
}

export const TicketModeEnum = {
    Level01: 'level01',
    Level02: 'level02',
    Level03: 'level03'
} as const;

export type TicketModeEnum = typeof TicketModeEnum[keyof typeof TicketModeEnum];
export const TicketStatusEnum = {
    Open: 'open',
    Pending: 'pending',
    Working: 'working',
    Closed: 'closed'
} as const;

export type TicketStatusEnum = typeof TicketStatusEnum[keyof typeof TicketStatusEnum];
export const TicketPriorityEnum = {
    Low: 'low',
    Medium: 'medium',
    High: 'high'
} as const;

export type TicketPriorityEnum = typeof TicketPriorityEnum[keyof typeof TicketPriorityEnum];

/**
 * 
 * @export
 * @interface TicketAllOf
 */
export interface TicketAllOf {
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'closedAt'?: string;
    /**
     * 
     * @type {SelectedArea}
     * @memberof TicketAllOf
     */
    'area'?: SelectedArea;
    /**
     * 
     * @type {Department}
     * @memberof TicketAllOf
     */
    'department'?: Department;
    /**
     * 
     * @type {Agent}
     * @memberof TicketAllOf
     */
    'agent'?: Agent;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'requestedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'mode'?: TicketAllOfModeEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'status'?: TicketAllOfStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'priority'?: TicketAllOfPriorityEnum;
    /**
     * 
     * @type {Array<TicketAllOfProperties>}
     * @memberof TicketAllOf
     */
    'properties'?: Array<TicketAllOfProperties>;
    /**
     * 
     * @type {number}
     * @memberof TicketAllOf
     */
    'reopenCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOf
     */
    'reopenAt'?: string;
}

export const TicketAllOfModeEnum = {
    Level01: 'level01',
    Level02: 'level02',
    Level03: 'level03'
} as const;

export type TicketAllOfModeEnum = typeof TicketAllOfModeEnum[keyof typeof TicketAllOfModeEnum];
export const TicketAllOfStatusEnum = {
    Open: 'open',
    Pending: 'pending',
    Working: 'working',
    Closed: 'closed'
} as const;

export type TicketAllOfStatusEnum = typeof TicketAllOfStatusEnum[keyof typeof TicketAllOfStatusEnum];
export const TicketAllOfPriorityEnum = {
    Low: 'low',
    Medium: 'medium',
    High: 'high'
} as const;

export type TicketAllOfPriorityEnum = typeof TicketAllOfPriorityEnum[keyof typeof TicketAllOfPriorityEnum];

/**
 * 
 * @export
 * @interface TicketAllOfProperties
 */
export interface TicketAllOfProperties {
    /**
     * 
     * @type {string}
     * @memberof TicketAllOfProperties
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAllOfProperties
     */
    'value'?: string;
}
/**
 * Ticket attachment
 * @export
 * @interface TicketAttachment
 */
export interface TicketAttachment {
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TicketAttachment
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'fileUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachment
     */
    'fileType'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketAttachment
     */
    'fileSize'?: number;
}
/**
 * 
 * @export
 * @interface TicketAttachmentAllOf
 */
export interface TicketAttachmentAllOf {
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentAllOf
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentAllOf
     */
    'fileUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentAllOf
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentAllOf
     */
    'fileType'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketAttachmentAllOf
     */
    'fileSize'?: number;
}
/**
 * Ticket attachment file
 * @export
 * @interface TicketAttachmentFile
 */
export interface TicketAttachmentFile {
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentFile
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentFile
     */
    'fileType'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketAttachmentFile
     */
    'fileSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentFile
     */
    'fileContent'?: string;
}
/**
 * 
 * @export
 * @interface TicketAttachmentFileAllOf
 */
export interface TicketAttachmentFileAllOf {
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentFileAllOf
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentFileAllOf
     */
    'fileType'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketAttachmentFileAllOf
     */
    'fileSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentFileAllOf
     */
    'fileContent'?: string;
}
/**
 * Ticket change log
 * @export
 * @interface TicketChangeLog
 */
export interface TicketChangeLog {
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TicketChangeLog
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLog
     */
    'change'?: string;
    /**
     * 
     * @type {TicketCommentAllOfUserInfo}
     * @memberof TicketChangeLog
     */
    'userInfo'?: TicketCommentAllOfUserInfo;
}
/**
 * 
 * @export
 * @interface TicketChangeLogAllOf
 */
export interface TicketChangeLogAllOf {
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLogAllOf
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLogAllOf
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChangeLogAllOf
     */
    'change'?: string;
    /**
     * 
     * @type {TicketCommentAllOfUserInfo}
     * @memberof TicketChangeLogAllOf
     */
    'userInfo'?: TicketCommentAllOfUserInfo;
}
/**
 * Ticket for ChatBot
 * @export
 * @interface TicketChatBot
 */
export interface TicketChatBot {
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TicketChatBot
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBot
     */
    'requestedBy'?: string;
    /**
     * 
     * @type {Array<TicketAllOfProperties>}
     * @memberof TicketChatBot
     */
    'properties'?: Array<TicketAllOfProperties>;
}
/**
 * 
 * @export
 * @interface TicketChatBotAllOf
 */
export interface TicketChatBotAllOf {
    /**
     * 
     * @type {string}
     * @memberof TicketChatBotAllOf
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketChatBotAllOf
     */
    'requestedBy'?: string;
    /**
     * 
     * @type {Array<TicketAllOfProperties>}
     * @memberof TicketChatBotAllOf
     */
    'properties'?: Array<TicketAllOfProperties>;
}
/**
 * Ticket comment
 * @export
 * @interface TicketComment
 */
export interface TicketComment {
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TicketComment
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketComment
     */
    'message'?: string;
    /**
     * 
     * @type {TicketCommentAllOfUserInfo}
     * @memberof TicketComment
     */
    'userInfo'?: TicketCommentAllOfUserInfo;
}
/**
 * 
 * @export
 * @interface TicketCommentAllOf
 */
export interface TicketCommentAllOf {
    /**
     * 
     * @type {string}
     * @memberof TicketCommentAllOf
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketCommentAllOf
     */
    'message'?: string;
    /**
     * 
     * @type {TicketCommentAllOfUserInfo}
     * @memberof TicketCommentAllOf
     */
    'userInfo'?: TicketCommentAllOfUserInfo;
}
/**
 * 
 * @export
 * @interface TicketCommentAllOfUserInfo
 */
export interface TicketCommentAllOfUserInfo {
    /**
     * 
     * @type {string}
     * @memberof TicketCommentAllOfUserInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketCommentAllOfUserInfo
     */
    'displayName'?: string;
}
/**
 * Ticket Filtering Criteria
 * @export
 * @interface TicketFilteringCriteria
 */
export interface TicketFilteringCriteria {
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'modifiedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TicketFilteringCriteria
     */
    'archived'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'toDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'areaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'departmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'mode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'priority'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteria
     */
    'requestedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketFilteringCriteria
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketFilteringCriteria
     */
    'limit'?: number;
}
/**
 * 
 * @export
 * @interface TicketFilteringCriteriaAllOf
 */
export interface TicketFilteringCriteriaAllOf {
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'toDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'areaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'departmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'mode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'priority'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'requestedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketFilteringCriteriaAllOf
     */
    'limit'?: number;
}
/**
 * Ticket reopen request
 * @export
 * @interface TicketReopen
 */
export interface TicketReopen {
    /**
     * 
     * @type {string}
     * @memberof TicketReopen
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketReopen
     */
    'requestedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketReopen
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TicketReopenAllOf
 */
export interface TicketReopenAllOf {
    /**
     * 
     * @type {string}
     * @memberof TicketReopenAllOf
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketReopenAllOf
     */
    'requestedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketReopenAllOf
     */
    'message'?: string;
}
/**
 * Application user
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'mail'?: string;
}
/**
 * 
 * @export
 * @interface UserAllOf
 */
export interface UserAllOf {
    /**
     * 
     * @type {string}
     * @memberof UserAllOf
     */
    'mail'?: string;
}

/**
 * AnnouncementApi - axios parameter creator
 * @export
 */
export const AnnouncementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a announcement by ID
         * @param {string} id ID of the announcement to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncement: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAnnouncement', 'id', id)
            const localVarPath = `/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an announcement
         * @param {string} id ID of the announcement to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncement: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnouncement', 'id', id)
            const localVarPath = `/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets announcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts an announcement
         * @param {Announcement} [announcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAnnouncement: async (announcement?: Announcement, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an announcement
         * @param {string} id ID of the announcement to update
         * @param {Announcement} [announcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnouncement: async (id: string, announcement?: Announcement, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAnnouncement', 'id', id)
            const localVarPath = `/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementApi - functional programming interface
 * @export
 */
export const AnnouncementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a announcement by ID
         * @param {string} id ID of the announcement to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnouncement(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnouncement(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an announcement
         * @param {string} id ID of the announcement to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncement(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncement(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets announcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Announcement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts an announcement
         * @param {Announcement} [announcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertAnnouncement(announcement?: Announcement, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertAnnouncement(announcement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an announcement
         * @param {string} id ID of the announcement to update
         * @param {Announcement} [announcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnouncement(id: string, announcement?: Announcement, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnouncement(id, announcement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementApi - factory interface
 * @export
 */
export const AnnouncementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a announcement by ID
         * @param {string} id ID of the announcement to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncement(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnouncement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an announcement
         * @param {string} id ID of the announcement to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncement(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getAnnouncement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets announcements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncements(options?: any): AxiosPromise<Array<Announcement>> {
            return localVarFp.getAnnouncements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts an announcement
         * @param {Announcement} [announcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertAnnouncement(announcement?: Announcement, options?: any): AxiosPromise<Item> {
            return localVarFp.insertAnnouncement(announcement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an announcement
         * @param {string} id ID of the announcement to update
         * @param {Announcement} [announcement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnouncement(id: string, announcement?: Announcement, options?: any): AxiosPromise<void> {
            return localVarFp.updateAnnouncement(id, announcement, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementApi - object-oriented interface
 * @export
 * @class AnnouncementApi
 * @extends {BaseAPI}
 */
export class AnnouncementApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a announcement by ID
     * @param {string} id ID of the announcement to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public deleteAnnouncement(id: string, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).deleteAnnouncement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an announcement
     * @param {string} id ID of the announcement to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public getAnnouncement(id: string, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).getAnnouncement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets announcements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public getAnnouncements(options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).getAnnouncements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts an announcement
     * @param {Announcement} [announcement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public insertAnnouncement(announcement?: Announcement, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).insertAnnouncement(announcement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an announcement
     * @param {string} id ID of the announcement to update
     * @param {Announcement} [announcement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public updateAnnouncement(id: string, announcement?: Announcement, options?: AxiosRequestConfig) {
        return AnnouncementApiFp(this.configuration).updateAnnouncement(id, announcement, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AreaApi - axios parameter creator
 * @export
 */
export const AreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an area by ID
         * @param {string} id ID of the area to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArea: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteArea', 'id', id)
            const localVarPath = `/areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an area
         * @param {string} id ID of the area to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArea: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getArea', 'id', id)
            const localVarPath = `/areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all areas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts an area
         * @param {Area} [area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertArea: async (area?: Area, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(area, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an area
         * @param {string} id ID of the area to update
         * @param {Area} [area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArea: async (id: string, area?: Area, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateArea', 'id', id)
            const localVarPath = `/areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(area, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreaApi - functional programming interface
 * @export
 */
export const AreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes an area by ID
         * @param {string} id ID of the area to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArea(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArea(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an area
         * @param {string} id ID of the area to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArea(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArea(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all areas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAreas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Area>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts an area
         * @param {Area} [area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertArea(area?: Area, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertArea(area, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an area
         * @param {string} id ID of the area to update
         * @param {Area} [area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArea(id: string, area?: Area, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArea(id, area, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreaApi - factory interface
 * @export
 */
export const AreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreaApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes an area by ID
         * @param {string} id ID of the area to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArea(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteArea(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an area
         * @param {string} id ID of the area to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArea(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getArea(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all areas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreas(options?: any): AxiosPromise<Array<Area>> {
            return localVarFp.getAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts an area
         * @param {Area} [area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertArea(area?: Area, options?: any): AxiosPromise<Item> {
            return localVarFp.insertArea(area, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an area
         * @param {string} id ID of the area to update
         * @param {Area} [area] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArea(id: string, area?: Area, options?: any): AxiosPromise<void> {
            return localVarFp.updateArea(id, area, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreaApi - object-oriented interface
 * @export
 * @class AreaApi
 * @extends {BaseAPI}
 */
export class AreaApi extends BaseAPI {
    /**
     * 
     * @summary Deletes an area by ID
     * @param {string} id ID of the area to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public deleteArea(id: string, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).deleteArea(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an area
     * @param {string} id ID of the area to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public getArea(id: string, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).getArea(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all areas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public getAreas(options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).getAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts an area
     * @param {Area} [area] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public insertArea(area?: Area, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).insertArea(area, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an area
     * @param {string} id ID of the area to update
     * @param {Area} [area] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public updateArea(id: string, area?: Area, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).updateArea(id, area, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventReceiverApi - axios parameter creator
 * @export
 */
export const EventReceiverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a eventReceiver by ID
         * @param {string} id ID of the eventReceiver to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventReceiver: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEventReceiver', 'id', id)
            const localVarPath = `/eventReceivers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an eventReceiver
         * @param {string} id ID of the eventReceiver to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventReceiver: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEventReceiver', 'id', id)
            const localVarPath = `/eventReceivers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets eventReceivers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventReceivers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/eventReceivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts an eventReceiver
         * @param {EventReceiver} [eventReceiver] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertEventReceiver: async (eventReceiver?: EventReceiver, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/eventReceivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventReceiver, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an eventReceiver
         * @param {string} id ID of the eventReceiver to update
         * @param {EventReceiver} [eventReceiver] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventReceiver: async (id: string, eventReceiver?: EventReceiver, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEventReceiver', 'id', id)
            const localVarPath = `/eventReceivers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventReceiver, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventReceiverApi - functional programming interface
 * @export
 */
export const EventReceiverApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventReceiverApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a eventReceiver by ID
         * @param {string} id ID of the eventReceiver to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEventReceiver(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEventReceiver(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an eventReceiver
         * @param {string} id ID of the eventReceiver to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventReceiver(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventReceiver(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets eventReceivers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventReceivers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventReceiver>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventReceivers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts an eventReceiver
         * @param {EventReceiver} [eventReceiver] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertEventReceiver(eventReceiver?: EventReceiver, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertEventReceiver(eventReceiver, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an eventReceiver
         * @param {string} id ID of the eventReceiver to update
         * @param {EventReceiver} [eventReceiver] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEventReceiver(id: string, eventReceiver?: EventReceiver, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventReceiver(id, eventReceiver, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventReceiverApi - factory interface
 * @export
 */
export const EventReceiverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventReceiverApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a eventReceiver by ID
         * @param {string} id ID of the eventReceiver to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventReceiver(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEventReceiver(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an eventReceiver
         * @param {string} id ID of the eventReceiver to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventReceiver(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getEventReceiver(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets eventReceivers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventReceivers(options?: any): AxiosPromise<Array<EventReceiver>> {
            return localVarFp.getEventReceivers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts an eventReceiver
         * @param {EventReceiver} [eventReceiver] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertEventReceiver(eventReceiver?: EventReceiver, options?: any): AxiosPromise<Item> {
            return localVarFp.insertEventReceiver(eventReceiver, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an eventReceiver
         * @param {string} id ID of the eventReceiver to update
         * @param {EventReceiver} [eventReceiver] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventReceiver(id: string, eventReceiver?: EventReceiver, options?: any): AxiosPromise<void> {
            return localVarFp.updateEventReceiver(id, eventReceiver, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventReceiverApi - object-oriented interface
 * @export
 * @class EventReceiverApi
 * @extends {BaseAPI}
 */
export class EventReceiverApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a eventReceiver by ID
     * @param {string} id ID of the eventReceiver to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventReceiverApi
     */
    public deleteEventReceiver(id: string, options?: AxiosRequestConfig) {
        return EventReceiverApiFp(this.configuration).deleteEventReceiver(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an eventReceiver
     * @param {string} id ID of the eventReceiver to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventReceiverApi
     */
    public getEventReceiver(id: string, options?: AxiosRequestConfig) {
        return EventReceiverApiFp(this.configuration).getEventReceiver(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets eventReceivers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventReceiverApi
     */
    public getEventReceivers(options?: AxiosRequestConfig) {
        return EventReceiverApiFp(this.configuration).getEventReceivers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts an eventReceiver
     * @param {EventReceiver} [eventReceiver] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventReceiverApi
     */
    public insertEventReceiver(eventReceiver?: EventReceiver, options?: AxiosRequestConfig) {
        return EventReceiverApiFp(this.configuration).insertEventReceiver(eventReceiver, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an eventReceiver
     * @param {string} id ID of the eventReceiver to update
     * @param {EventReceiver} [eventReceiver] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventReceiverApi
     */
    public updateEventReceiver(id: string, eventReceiver?: EventReceiver, options?: AxiosRequestConfig) {
        return EventReceiverApiFp(this.configuration).updateEventReceiver(id, eventReceiver, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExternalApi - axios parameter creator
 * @export
 */
export const ExternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Meeting by External
         * @param {MeetingRequest} [meetingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingByExternal: async (meetingRequest?: MeetingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/externalMeeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign unmovimented tickets
         * @param {string} id ID of the Tenant to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalAssignment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('externalAssignment', 'id', id)
            const localVarPath = `/externalAssignment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a ticket by External
         * @param {string} id ID of the ticket to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketByExternal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketByExternal', 'id', id)
            const localVarPath = `/externalTickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets ticket comments by External
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketCommentsByExternal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketCommentsByExternal', 'id', id)
            const localVarPath = `/externalTickets/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads an Attachment by External
         * @param {string} id ID of the ticket to attachment belongs to
         * @param {TicketAttachmentFile} [ticketAttachmentFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketAttachmentByExternal: async (id: string, ticketAttachmentFile?: TicketAttachmentFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insertTicketAttachmentByExternal', 'id', id)
            const localVarPath = `/externalTickets/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketAttachmentFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a ticket by anonymous
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketByAnonymous: async (ticket?: Ticket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a ticket by chatBot
         * @param {TicketChatBot} [ticketChatBot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketByChatBot: async (ticketChatBot?: TicketChatBot, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chatBot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketChatBot, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a ticket comment by External
         * @param {string} id ID of the ticket
         * @param {TicketComment} [ticketComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketCommentByExternal: async (id: string, ticketComment?: TicketComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insertTicketCommentByExternal', 'id', id)
            const localVarPath = `/externalTickets/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reopen ticket by External
         * @param {string} id ID of the ticket
         * @param {TicketReopen} [ticketReopen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenTicketByExternal: async (id: string, ticketReopen?: TicketReopen, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reopenTicketByExternal', 'id', id)
            const localVarPath = `/externalTickets/{id}/reopen`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketReopen, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalApi - functional programming interface
 * @export
 */
export const ExternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Meeting by External
         * @param {MeetingRequest} [meetingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeetingByExternal(meetingRequest?: MeetingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeetingByExternal(meetingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign unmovimented tickets
         * @param {string} id ID of the Tenant to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalAssignment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalAssignment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a ticket by External
         * @param {string} id ID of the ticket to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketByExternal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketByExternal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets ticket comments by External
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketCommentsByExternal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketComment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketCommentsByExternal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads an Attachment by External
         * @param {string} id ID of the ticket to attachment belongs to
         * @param {TicketAttachmentFile} [ticketAttachmentFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTicketAttachmentByExternal(id: string, ticketAttachmentFile?: TicketAttachmentFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTicketAttachmentByExternal(id, ticketAttachmentFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a ticket by anonymous
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTicketByAnonymous(ticket?: Ticket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTicketByAnonymous(ticket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a ticket by chatBot
         * @param {TicketChatBot} [ticketChatBot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTicketByChatBot(ticketChatBot?: TicketChatBot, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTicketByChatBot(ticketChatBot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a ticket comment by External
         * @param {string} id ID of the ticket
         * @param {TicketComment} [ticketComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTicketCommentByExternal(id: string, ticketComment?: TicketComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTicketCommentByExternal(id, ticketComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reopen ticket by External
         * @param {string} id ID of the ticket
         * @param {TicketReopen} [ticketReopen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reopenTicketByExternal(id: string, ticketReopen?: TicketReopen, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reopenTicketByExternal(id, ticketReopen, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExternalApi - factory interface
 * @export
 */
export const ExternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Meeting by External
         * @param {MeetingRequest} [meetingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingByExternal(meetingRequest?: MeetingRequest, options?: any): AxiosPromise<Item> {
            return localVarFp.createMeetingByExternal(meetingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign unmovimented tickets
         * @param {string} id ID of the Tenant to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalAssignment(id: string, options?: any): AxiosPromise<Item> {
            return localVarFp.externalAssignment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a ticket by External
         * @param {string} id ID of the ticket to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketByExternal(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getTicketByExternal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets ticket comments by External
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketCommentsByExternal(id: string, options?: any): AxiosPromise<Array<TicketComment>> {
            return localVarFp.getTicketCommentsByExternal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads an Attachment by External
         * @param {string} id ID of the ticket to attachment belongs to
         * @param {TicketAttachmentFile} [ticketAttachmentFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketAttachmentByExternal(id: string, ticketAttachmentFile?: TicketAttachmentFile, options?: any): AxiosPromise<void> {
            return localVarFp.insertTicketAttachmentByExternal(id, ticketAttachmentFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a ticket by anonymous
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketByAnonymous(ticket?: Ticket, options?: any): AxiosPromise<Item> {
            return localVarFp.insertTicketByAnonymous(ticket, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a ticket by chatBot
         * @param {TicketChatBot} [ticketChatBot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketByChatBot(ticketChatBot?: TicketChatBot, options?: any): AxiosPromise<Item> {
            return localVarFp.insertTicketByChatBot(ticketChatBot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a ticket comment by External
         * @param {string} id ID of the ticket
         * @param {TicketComment} [ticketComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketCommentByExternal(id: string, ticketComment?: TicketComment, options?: any): AxiosPromise<Item> {
            return localVarFp.insertTicketCommentByExternal(id, ticketComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reopen ticket by External
         * @param {string} id ID of the ticket
         * @param {TicketReopen} [ticketReopen] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reopenTicketByExternal(id: string, ticketReopen?: TicketReopen, options?: any): AxiosPromise<void> {
            return localVarFp.reopenTicketByExternal(id, ticketReopen, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalApi - object-oriented interface
 * @export
 * @class ExternalApi
 * @extends {BaseAPI}
 */
export class ExternalApi extends BaseAPI {
    /**
     * 
     * @summary Create a Meeting by External
     * @param {MeetingRequest} [meetingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public createMeetingByExternal(meetingRequest?: MeetingRequest, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).createMeetingByExternal(meetingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign unmovimented tickets
     * @param {string} id ID of the Tenant to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public externalAssignment(id: string, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).externalAssignment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a ticket by External
     * @param {string} id ID of the ticket to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getTicketByExternal(id: string, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).getTicketByExternal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets ticket comments by External
     * @param {string} id ID of the ticket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public getTicketCommentsByExternal(id: string, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).getTicketCommentsByExternal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads an Attachment by External
     * @param {string} id ID of the ticket to attachment belongs to
     * @param {TicketAttachmentFile} [ticketAttachmentFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public insertTicketAttachmentByExternal(id: string, ticketAttachmentFile?: TicketAttachmentFile, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).insertTicketAttachmentByExternal(id, ticketAttachmentFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a ticket by anonymous
     * @param {Ticket} [ticket] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public insertTicketByAnonymous(ticket?: Ticket, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).insertTicketByAnonymous(ticket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a ticket by chatBot
     * @param {TicketChatBot} [ticketChatBot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public insertTicketByChatBot(ticketChatBot?: TicketChatBot, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).insertTicketByChatBot(ticketChatBot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a ticket comment by External
     * @param {string} id ID of the ticket
     * @param {TicketComment} [ticketComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public insertTicketCommentByExternal(id: string, ticketComment?: TicketComment, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).insertTicketCommentByExternal(id, ticketComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reopen ticket by External
     * @param {string} id ID of the ticket
     * @param {TicketReopen} [ticketReopen] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public reopenTicketByExternal(id: string, ticketReopen?: TicketReopen, options?: AxiosRequestConfig) {
        return ExternalApiFp(this.configuration).reopenTicketByExternal(id, ticketReopen, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a property by ID
         * @param {string} id ID of the property to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProperty: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProperty', 'id', id)
            const localVarPath = `/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a property
         * @param {string} id ID of the property to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProperty', 'id', id)
            const localVarPath = `/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a property
         * @param {Property} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProperty: async (property?: Property, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(property, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a property
         * @param {string} id ID of the property to update
         * @param {Property} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperty: async (id: string, property?: Property, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProperty', 'id', id)
            const localVarPath = `/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(property, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a property by ID
         * @param {string} id ID of the property to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProperty(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProperty(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperties(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Property>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperties(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a property
         * @param {string} id ID of the property to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProperty(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProperty(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a property
         * @param {Property} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertProperty(property?: Property, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertProperty(property, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a property
         * @param {string} id ID of the property to update
         * @param {Property} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProperty(id: string, property?: Property, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProperty(id, property, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a property by ID
         * @param {string} id ID of the property to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProperty(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProperty(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all properties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperties(options?: any): AxiosPromise<Array<Property>> {
            return localVarFp.getProperties(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a property
         * @param {string} id ID of the property to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProperty(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getProperty(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a property
         * @param {Property} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertProperty(property?: Property, options?: any): AxiosPromise<Item> {
            return localVarFp.insertProperty(property, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a property
         * @param {string} id ID of the property to update
         * @param {Property} [property] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProperty(id: string, property?: Property, options?: any): AxiosPromise<Item> {
            return localVarFp.updateProperty(id, property, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a property by ID
     * @param {string} id ID of the property to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public deleteProperty(id: string, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).deleteProperty(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public getProperties(options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).getProperties(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a property
     * @param {string} id ID of the property to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public getProperty(id: string, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).getProperty(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a property
     * @param {Property} [property] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public insertProperty(property?: Property, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).insertProperty(property, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a property
     * @param {string} id ID of the property to update
     * @param {Property} [property] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public updateProperty(id: string, property?: Property, options?: AxiosRequestConfig) {
        return PropertyApiFp(this.configuration).updateProperty(id, property, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a setting by ID
         * @param {string} id ID of the setting to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSetting: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSetting', 'id', id)
            const localVarPath = `/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an setting
         * @param {string} id ID of the setting to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetting: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSetting', 'id', id)
            const localVarPath = `/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a setting
         * @param {Setting} [setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSetting: async (setting?: Setting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a setting
         * @param {string} id ID of the setting to update
         * @param {Setting} [setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting: async (id: string, setting?: Setting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSetting', 'id', id)
            const localVarPath = `/settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a setting by ID
         * @param {string} id ID of the setting to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSetting(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSetting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an setting
         * @param {string} id ID of the setting to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSetting(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSetting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Setting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a setting
         * @param {Setting} [setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSetting(setting?: Setting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertSetting(setting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a setting
         * @param {string} id ID of the setting to update
         * @param {Setting} [setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSetting(id: string, setting?: Setting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSetting(id, setting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a setting by ID
         * @param {string} id ID of the setting to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSetting(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSetting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an setting
         * @param {string} id ID of the setting to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetting(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getSetting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<Array<Setting>> {
            return localVarFp.getSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a setting
         * @param {Setting} [setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSetting(setting?: Setting, options?: any): AxiosPromise<Item> {
            return localVarFp.insertSetting(setting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a setting
         * @param {string} id ID of the setting to update
         * @param {Setting} [setting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting(id: string, setting?: Setting, options?: any): AxiosPromise<void> {
            return localVarFp.updateSetting(id, setting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a setting by ID
     * @param {string} id ID of the setting to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public deleteSetting(id: string, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).deleteSetting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an setting
     * @param {string} id ID of the setting to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public getSetting(id: string, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).getSetting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public getSettings(options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a setting
     * @param {Setting} [setting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public insertSetting(setting?: Setting, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).insertSetting(setting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a setting
     * @param {string} id ID of the setting to update
     * @param {Setting} [setting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public updateSetting(id: string, setting?: Setting, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).updateSetting(id, setting, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SolutionApi - axios parameter creator
 * @export
 */
export const SolutionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an issue solution by ID
         * @param {string} id ID of the issue solution to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSolution: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSolution', 'id', id)
            const localVarPath = `/solutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an issue solution
         * @param {string} id ID of the issue solution to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolution: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSolution', 'id', id)
            const localVarPath = `/solutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets issue solutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolutions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts an issue solution
         * @param {Solution} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSolution: async (solution?: Solution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solution, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an issue solution
         * @param {string} id ID of the issue solution to update
         * @param {Solution} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolution: async (id: string, solution?: Solution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolution', 'id', id)
            const localVarPath = `/solutions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solution, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SolutionApi - functional programming interface
 * @export
 */
export const SolutionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SolutionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes an issue solution by ID
         * @param {string} id ID of the issue solution to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSolution(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSolution(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an issue solution
         * @param {string} id ID of the issue solution to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolution(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolution(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets issue solutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolutions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Solution>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolutions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts an issue solution
         * @param {Solution} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSolution(solution?: Solution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertSolution(solution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an issue solution
         * @param {string} id ID of the issue solution to update
         * @param {Solution} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolution(id: string, solution?: Solution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolution(id, solution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SolutionApi - factory interface
 * @export
 */
export const SolutionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SolutionApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes an issue solution by ID
         * @param {string} id ID of the issue solution to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSolution(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSolution(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an issue solution
         * @param {string} id ID of the issue solution to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolution(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getSolution(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets issue solutions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolutions(options?: any): AxiosPromise<Array<Solution>> {
            return localVarFp.getSolutions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts an issue solution
         * @param {Solution} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSolution(solution?: Solution, options?: any): AxiosPromise<Item> {
            return localVarFp.insertSolution(solution, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an issue solution
         * @param {string} id ID of the issue solution to update
         * @param {Solution} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolution(id: string, solution?: Solution, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolution(id, solution, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SolutionApi - object-oriented interface
 * @export
 * @class SolutionApi
 * @extends {BaseAPI}
 */
export class SolutionApi extends BaseAPI {
    /**
     * 
     * @summary Deletes an issue solution by ID
     * @param {string} id ID of the issue solution to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionApi
     */
    public deleteSolution(id: string, options?: AxiosRequestConfig) {
        return SolutionApiFp(this.configuration).deleteSolution(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an issue solution
     * @param {string} id ID of the issue solution to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionApi
     */
    public getSolution(id: string, options?: AxiosRequestConfig) {
        return SolutionApiFp(this.configuration).getSolution(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets issue solutions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionApi
     */
    public getSolutions(options?: AxiosRequestConfig) {
        return SolutionApiFp(this.configuration).getSolutions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts an issue solution
     * @param {Solution} [solution] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionApi
     */
    public insertSolution(solution?: Solution, options?: AxiosRequestConfig) {
        return SolutionApiFp(this.configuration).insertSolution(solution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an issue solution
     * @param {string} id ID of the issue solution to update
     * @param {Solution} [solution] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionApi
     */
    public updateSolution(id: string, solution?: Solution, options?: AxiosRequestConfig) {
        return SolutionApiFp(this.configuration).updateSolution(id, solution, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticates current Azure AD user to application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a group by ID
         * @param {string} id ID of the group to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGroup', 'id', id)
            const localVarPath = `/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a user by ID
         * @param {string} id ID of the user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all agents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets department members
         * @param {string} id Department members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentMembers: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartmentMembers', 'id', id)
            const localVarPath = `/departments/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a group
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertGroup: async (group?: Group, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a user
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUser: async (user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an user
         * @param {string} id ID of the user to update
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, user?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authenticates current Azure AD user to application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a group by ID
         * @param {string} id ID of the group to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a user by ID
         * @param {string} id ID of the user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all agents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Agent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets department members
         * @param {string} id Department members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentMembers(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentMembers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Department>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a group
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertGroup(group?: Group, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertGroup(group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a user
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertUser(user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an user
         * @param {string} id ID of the user to update
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, user?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @summary Authenticates current Azure AD user to application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(options?: any): AxiosPromise<Group> {
            return localVarFp.authenticate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a group by ID
         * @param {string} id ID of the group to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a user by ID
         * @param {string} id ID of the user to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all agents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgents(options?: any): AxiosPromise<Array<Agent>> {
            return localVarFp.getAgents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets department members
         * @param {string} id Department members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentMembers(id: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getDepartmentMembers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartments(options?: any): AxiosPromise<Array<Department>> {
            return localVarFp.getDepartments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroups(options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.getGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(options?: any): AxiosPromise<Profile> {
            return localVarFp.getUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a group
         * @param {Group} [group] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertGroup(group?: Group, options?: any): AxiosPromise<Item> {
            return localVarFp.insertGroup(group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a user
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUser(user?: User, options?: any): AxiosPromise<Item> {
            return localVarFp.insertUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an user
         * @param {string} id ID of the user to update
         * @param {User} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, user?: User, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(id, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @summary Authenticates current Azure AD user to application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public authenticate(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).authenticate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a group by ID
     * @param {string} id ID of the group to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteGroup(id: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).deleteGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a user by ID
     * @param {string} id ID of the user to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all agents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getAgents(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getAgents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets department members
     * @param {string} id Department members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getDepartmentMembers(id: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getDepartmentMembers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all departments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getDepartments(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getDepartments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getGroups(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getUserProfile(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getUserProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a group
     * @param {Group} [group] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public insertGroup(group?: Group, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).insertGroup(group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a user
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public insertUser(user?: User, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).insertUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an user
     * @param {string} id ID of the user to update
     * @param {User} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public updateUser(id: string, user?: User, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).updateUser(id, user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TicketApi - axios parameter creator
 * @export
 */
export const TicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a ticket by ID
         * @param {string} id ID of the ticket to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTicket', 'id', id)
            const localVarPath = `/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a ticket attachment by ID
         * @param {string} id ID of the ticket
         * @param {string} attachmentId ID of the ticket attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketAttachment: async (id: string, attachmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTicketAttachment', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('deleteTicketAttachment', 'attachmentId', attachmentId)
            const localVarPath = `/tickets/{id}/attachments/{attachmentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate tickets export
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTickets: async (ticketFilteringCriteria?: TicketFilteringCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketFilteringCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTickets: async (ticketFilteringCriteria?: TicketFilteringCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/filteredTickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketFilteringCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalTickets: async (ticketFilteringCriteria?: TicketFilteringCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/personalTickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketFilteringCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReopenTickets: async (ticketFilteringCriteria?: TicketFilteringCriteria, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reopenTickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketFilteringCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a ticket
         * @param {string} id ID of the ticket to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicket', 'id', id)
            const localVarPath = `/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets ticket attachments
         * @param {string} id ID of the ticket
         * @param {string} attachmentId ID of the ticket attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAttachment: async (id: string, attachmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketAttachment', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('getTicketAttachment', 'attachmentId', attachmentId)
            const localVarPath = `/tickets/{id}/attachments/{attachmentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets ticket attachments
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAttachments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketAttachments', 'id', id)
            const localVarPath = `/tickets/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets ticket change logs
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketChangeLogs: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketChangeLogs', 'id', id)
            const localVarPath = `/tickets/{id}/changeLogs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets ticket comments
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketComments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketComments', 'id', id)
            const localVarPath = `/tickets/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTickets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a ticket
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicket: async (ticket?: Ticket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads an Attachment
         * @param {string} id ID of the ticket to attachment belongs to
         * @param {TicketAttachmentFile} [ticketAttachmentFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketAttachment: async (id: string, ticketAttachmentFile?: TicketAttachmentFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insertTicketAttachment', 'id', id)
            const localVarPath = `/tickets/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketAttachmentFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts a ticket comment
         * @param {string} id ID of the ticket
         * @param {TicketComment} [ticketComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketComment: async (id: string, ticketComment?: TicketComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insertTicketComment', 'id', id)
            const localVarPath = `/tickets/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticketComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a ticket
         * @param {string} id ID of the ticket to create
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicket: async (id: string, ticket?: Ticket, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTicket', 'id', id)
            const localVarPath = `/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ticket, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketApi - functional programming interface
 * @export
 */
export const TicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a ticket by ID
         * @param {string} id ID of the ticket to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a ticket attachment by ID
         * @param {string} id ID of the ticket
         * @param {string} attachmentId ID of the ticket attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicketAttachment(id: string, attachmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketAttachment(id, attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate tickets export
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportTickets(ticketFilteringCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredTickets(ticketFilteringCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalTickets(ticketFilteringCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReopenTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReopenTickets(ticketFilteringCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a ticket
         * @param {string} id ID of the ticket to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets ticket attachments
         * @param {string} id ID of the ticket
         * @param {string} attachmentId ID of the ticket attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketAttachment(id: string, attachmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketAttachment(id, attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets ticket attachments
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketAttachments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketAttachment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketAttachments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets ticket change logs
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketChangeLogs(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketChangeLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketChangeLogs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets ticket comments
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketComments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TicketComment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketComments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTickets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTickets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a ticket
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTicket(ticket?: Ticket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTicket(ticket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads an Attachment
         * @param {string} id ID of the ticket to attachment belongs to
         * @param {TicketAttachmentFile} [ticketAttachmentFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTicketAttachment(id: string, ticketAttachmentFile?: TicketAttachmentFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTicketAttachment(id, ticketAttachmentFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Inserts a ticket comment
         * @param {string} id ID of the ticket
         * @param {TicketComment} [ticketComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTicketComment(id: string, ticketComment?: TicketComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Item>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTicketComment(id, ticketComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a ticket
         * @param {string} id ID of the ticket to create
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicket(id: string, ticket?: Ticket, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicket(id, ticket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketApi - factory interface
 * @export
 */
export const TicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a ticket by ID
         * @param {string} id ID of the ticket to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a ticket attachment by ID
         * @param {string} id ID of the ticket
         * @param {string} attachmentId ID of the ticket attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketAttachment(id: string, attachmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTicketAttachment(id, attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate tickets export
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: any): AxiosPromise<string> {
            return localVarFp.exportTickets(ticketFilteringCriteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.getFilteredTickets(ticketFilteringCriteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.getPersonalTickets(ticketFilteringCriteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets filtered tickets
         * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReopenTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.getReopenTickets(ticketFilteringCriteria, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a ticket
         * @param {string} id ID of the ticket to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.getTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets ticket attachments
         * @param {string} id ID of the ticket
         * @param {string} attachmentId ID of the ticket attachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAttachment(id: string, attachmentId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getTicketAttachment(id, attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets ticket attachments
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAttachments(id: string, options?: any): AxiosPromise<Array<TicketAttachment>> {
            return localVarFp.getTicketAttachments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets ticket change logs
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketChangeLogs(id: string, options?: any): AxiosPromise<Array<TicketChangeLog>> {
            return localVarFp.getTicketChangeLogs(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets ticket comments
         * @param {string} id ID of the ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketComments(id: string, options?: any): AxiosPromise<Array<TicketComment>> {
            return localVarFp.getTicketComments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets tickets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTickets(options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.getTickets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a ticket
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicket(ticket?: Ticket, options?: any): AxiosPromise<Item> {
            return localVarFp.insertTicket(ticket, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads an Attachment
         * @param {string} id ID of the ticket to attachment belongs to
         * @param {TicketAttachmentFile} [ticketAttachmentFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketAttachment(id: string, ticketAttachmentFile?: TicketAttachmentFile, options?: any): AxiosPromise<void> {
            return localVarFp.insertTicketAttachment(id, ticketAttachmentFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts a ticket comment
         * @param {string} id ID of the ticket
         * @param {TicketComment} [ticketComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTicketComment(id: string, ticketComment?: TicketComment, options?: any): AxiosPromise<Item> {
            return localVarFp.insertTicketComment(id, ticketComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a ticket
         * @param {string} id ID of the ticket to create
         * @param {Ticket} [ticket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicket(id: string, ticket?: Ticket, options?: any): AxiosPromise<void> {
            return localVarFp.updateTicket(id, ticket, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketApi - object-oriented interface
 * @export
 * @class TicketApi
 * @extends {BaseAPI}
 */
export class TicketApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a ticket by ID
     * @param {string} id ID of the ticket to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public deleteTicket(id: string, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).deleteTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a ticket attachment by ID
     * @param {string} id ID of the ticket
     * @param {string} attachmentId ID of the ticket attachment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public deleteTicketAttachment(id: string, attachmentId: string, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).deleteTicketAttachment(id, attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate tickets export
     * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public exportTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).exportTickets(ticketFilteringCriteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets filtered tickets
     * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getFilteredTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getFilteredTickets(ticketFilteringCriteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets filtered tickets
     * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getPersonalTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getPersonalTickets(ticketFilteringCriteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets filtered tickets
     * @param {TicketFilteringCriteria} [ticketFilteringCriteria] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getReopenTickets(ticketFilteringCriteria?: TicketFilteringCriteria, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getReopenTickets(ticketFilteringCriteria, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a ticket
     * @param {string} id ID of the ticket to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getTicket(id: string, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets ticket attachments
     * @param {string} id ID of the ticket
     * @param {string} attachmentId ID of the ticket attachment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getTicketAttachment(id: string, attachmentId: string, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getTicketAttachment(id, attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets ticket attachments
     * @param {string} id ID of the ticket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getTicketAttachments(id: string, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getTicketAttachments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets ticket change logs
     * @param {string} id ID of the ticket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getTicketChangeLogs(id: string, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getTicketChangeLogs(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets ticket comments
     * @param {string} id ID of the ticket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getTicketComments(id: string, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getTicketComments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets tickets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public getTickets(options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).getTickets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a ticket
     * @param {Ticket} [ticket] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public insertTicket(ticket?: Ticket, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).insertTicket(ticket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads an Attachment
     * @param {string} id ID of the ticket to attachment belongs to
     * @param {TicketAttachmentFile} [ticketAttachmentFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public insertTicketAttachment(id: string, ticketAttachmentFile?: TicketAttachmentFile, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).insertTicketAttachment(id, ticketAttachmentFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts a ticket comment
     * @param {string} id ID of the ticket
     * @param {TicketComment} [ticketComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public insertTicketComment(id: string, ticketComment?: TicketComment, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).insertTicketComment(id, ticketComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a ticket
     * @param {string} id ID of the ticket to create
     * @param {Ticket} [ticket] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApi
     */
    public updateTicket(id: string, ticket?: Ticket, options?: AxiosRequestConfig) {
        return TicketApiFp(this.configuration).updateTicket(id, ticket, options).then((request) => request(this.axios, this.basePath));
    }
}


