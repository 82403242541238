import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TicketComment } from '../../../../../api-client';
import { RouterSettings } from '../../../../../utils/router';
import { useTicketChat } from './hooks/useTicketChat';

import { IAIWebchat } from '../../../../../common/components/IAIWebchat';
import { IAIDialog } from '../../../../../common/components/IAIDialog';

interface ITicketChat {
    ticketId?: string;
}

const TicketChat: React.FunctionComponent<ITicketChat> = ({ ticketId }) => {
    const {
        handleCreateTicketComment,
        createTicketCommentLoading,
        handleRetrieveTicketComments,
        ticketComments,
        ticketCommentsLoading
    } = useTicketChat();

    useEffect(() => {
        ticketId && handleRetrieveTicketComments({ id: ticketId });
    }, [ticketId]);

    const [webchatDialogVisible, setWebchatDialogVisible] = useState<boolean>(false);

    const _handleSubmit = (comment: TicketComment) => {
        ticketId && handleCreateTicketComment({
            id: ticketId,
            ticketComment: comment,
            onSuccess: () => {
                handleRetrieveTicketComments({ id: ticketId });

                setWebchatDialogVisible(true);
            }
        });
    }

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <IAIWebchat
                dataSource={ticketComments}
                loading={ticketCommentsLoading || createTicketCommentLoading}
                isAdmin
                onSendMessage={_handleSubmit}
            />

            <IAIDialog
                dialogProps={{
                    hidden: !webchatDialogVisible
                }}
                title={'Messaggio inviato correttamente'}
                subText={`Stai per ritornare alla pagina di riepilogo. Vuoi continuare?`}
                disabled={ticketCommentsLoading || createTicketCommentLoading}
                confirmText={'Continua'}
                dismissText={'Annulla'}
                onConfirm={() => {
                    navigate(RouterSettings.PATHS.Dashboard.Ticket.root)
                }}
                onDismiss={() => setWebchatDialogVisible(false)}
            />
        </React.Fragment>
    );
}

export default TicketChat;