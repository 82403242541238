import { getTheme } from '@fluentui/react';
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

interface ISettingPageClassNames {
    settingPageContainer: string;
    settingCardContainer: string;
}

export const useClassNames = (): ISettingPageClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        settingPageContainer: {
            background: applicationTheme.semanticColors.bodyBackground,
        },
        settingCardContainer: {
            selector: {
                '&:hover': {
                    background: applicationTheme.palette.themePrimary
                },
                '&.isActive': {
                    background: applicationTheme.palette.themePrimary
                }
            }
        }
    });
}