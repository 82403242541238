import { getTheme, mergeStyleSets } from '@fluentui/react';

interface IIAIApplicationLayoutClassNames {
    applicationLayoutContainer: string;
}

export const useClassNames = (): IIAIApplicationLayoutClassNames => {
    const applicationTheme = getTheme();

    return mergeStyleSets({
        applicationLayoutContainer: {
            background: applicationTheme.semanticColors.bodyBackground,
        }
    });
}