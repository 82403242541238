import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { ContextualMenuItemType } from '@fluentui/react';
import { IconButton } from '@fluentui/react/lib/Button';

import { Area } from '../../../api-client';
import { SortingComparer } from '../../../utils/data-comparer';
import { DateTimeEnchanter } from '../../../utils/data-enchanters';
import { useArea } from './hooks/useArea';
import { useClassNames } from './area-page.classNames';

import { IAIApplicationLayout } from '../../../common/layouts/IAIApplicationLayout';
import { IAICalloutColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAICalloutColumn';
import { IAIDateColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAIDateColumn';
import { IAIDataTable } from '../../../common/components/IAIDataTable';
import { IAITextColumn } from '../../../common/components/IAIDataTable/components/IAIColumns/IAITextColumn';
import { IAIDialog } from '../../../common/components/IAIDialog';
import AreaFormDrawer from './components/AreaFormDrawer/area-form-drawer';

const AreaPage: React.FunctionComponent = () => {
    const {
        handleAreas,
        areas,
        areasLoading,
        handleCreateArea,
        createAreaLoading,
        handleRetrieveArea,
        retrieveArea,
        retrieveAreaLoading,
        handleUpdateArea,
        updateAreaLoading,
        handleDeleteArea,
        deleteAreaLoading
    } = useArea();

    const {
        areaPageContainer,
        calloutSecondaryHeading,
        calloutText,
        tableCell,
    } = useClassNames();

    useEffect(() => {
        handleAreas();
    }, []);

    const _handleSearchArea = (entry: Area, searchValue: string): boolean => (
        entry.displayName?.toLowerCase().search(searchValue) !== -1
    );

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [delegatedArea, setDelegatedArea] = useState<Area>();

    const _handleSubmit = (area: Area, areaId?: string) => {
        const onUpsertSuccess = () => {
            handleAreas();

            setDrawerVisible(false);
            setDelegatedArea(undefined);
        };

        !areaId
            ?
            handleCreateArea({
                area: area,
                onSuccess: onUpsertSuccess
            })
            :
            handleUpdateArea({
                id: areaId,
                area: area,
                onSuccess: onUpsertSuccess
            });
    }

    const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);

    return (
        <IAIApplicationLayout
            icon={'ArrangeBringToFront'}
            heading={'Aree'}
            secondaryHeading={'Gestisci le Aree.'}
        >
            <div className={areaPageContainer}>
                <IAIDataTable
                    columns={[
                        {
                            key: 'area_preview__',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: any) => (
                                <IAICalloutColumn
                                    title={element.title}
                                    tooltipContent={'Anteprima soluzione'}
                                    contentLoading={retrieveAreaLoading}
                                    content={
                                        retrieveArea &&
                                        <div className="col-12">
                                            <span className={calloutSecondaryHeading}>
                                                {`Ultimo aggiornamento: ${DateTimeEnchanter.formatStandardDate(retrieveArea.createdAt, 'it-IT')}`}
                                            </span>
                                            <span className={calloutText}>
                                                {'Dipartimenti associati:'}
                                            </span>
                                            <span className={calloutText}>
                                                {_.map(retrieveArea.departments, (dataItem, dataIndex) => (
                                                    <span key={dataIndex} className="badge rounded-pill bg-info mr-2 mt-2">
                                                        {dataItem.displayName}
                                                    </span>
                                                ))}
                                            </span>
                                        </div>
                                    }
                                    onEngage={() => handleRetrieveArea({
                                        id: element.id,
                                        onRetrieveSuccess: (area) => setDelegatedArea(area)
                                    })}
                                    onDismiss={() => setDelegatedArea(undefined)}
                                />
                            )
                        },
                        {
                            key: 'area_title__',
                            fieldName: 'displayName',
                            name: 'Nome',
                            className: tableCell,
                            minWidth: 250,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer
                        },
                        {
                            key: 'solution_department__',
                            fieldName: 'department',
                            name: 'Dipartimenti',
                            className: tableCell,
                            minWidth: 250,
                            isResizable: true,
                            sorter: SortingComparer.stringComparer,
                            onRender: (element: Area) => <IAITextColumn text={`${element.departments?.length || 0}`} />
                        },
                        {
                            key: 'area_date__',
                            fieldName: 'createdAt',
                            name: 'Data inserimento',
                            className: tableCell,
                            minWidth: 100,
                            maxWidth: 100,
                            sorter: SortingComparer.dateComparer,
                            onRender: (element: Area) => <IAIDateColumn dateTime={element.createdAt} />
                        },
                        {
                            key: 'area_action__',
                            fieldName: '',
                            name: '',
                            minWidth: 50,
                            maxWidth: 50,
                            onRender: (element: any, dataIndex?: number) => (
                                <div className="text-center">
                                    <IconButton
                                        className={''}
                                        menuIconProps={{ iconName: 'CollapseMenu' }}
                                        menuProps={{
                                            items: [
                                                {
                                                    key: `${dataIndex}_menu_header__`,
                                                    itemType: ContextualMenuItemType.Header,
                                                    text: 'Azioni'
                                                },
                                                {
                                                    key: `${dataIndex}_menu_update__`,
                                                    iconProps: { iconName: 'EditCreate' },
                                                    text: 'Modifica',
                                                    onClick: () => {
                                                        handleRetrieveArea({
                                                            id: element.id,
                                                            onRetrieveSuccess: (area) => {
                                                                setDelegatedArea(area);
                                                                setDrawerVisible(true);
                                                            }
                                                        });
                                                    }
                                                },
                                                {
                                                    key: `${dataIndex}_menu_delete__`,
                                                    iconProps: { iconName: 'Delete' },
                                                    text: 'Elimina',
                                                    onClick: () => {
                                                        setDelegatedArea(element);
                                                        setDeleteDialogVisible(true);
                                                    }
                                                }
                                            ]
                                        }}
                                    />
                                </div>
                            )
                        }
                    ]}
                    dataSource={areas || []}
                    dataLoading={areasLoading}
                    dataTableCommands={[{
                        disabled: areasLoading,
                        text: 'Aggiungi area',
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            setDelegatedArea(undefined)
                            setDrawerVisible(true)
                        }
                    }]}
                    emptyContentLabel="Nessuna richiesta disponibile"
                    onItemInvoked={(element) => {
                        handleRetrieveArea({
                            id: element.id,
                            onRetrieveSuccess: (area) => {
                                setDelegatedArea(area);
                                setDrawerVisible(true);
                            }
                        });
                    }}
                    searchable
                    onSearch={_handleSearchArea}
                />

                <IAIDialog
                    dialogProps={{
                        hidden: !deleteDialogVisible,
                        onDismissed: () => setDelegatedArea(undefined)
                    }}
                    title={'Eliminazione area'}
                    subText={`Stai per eliminare l'area "${delegatedArea?.displayName}". Vuoi continuare?`}
                    disabled={deleteAreaLoading || retrieveAreaLoading}
                    confirmText={'Elimina'}
                    dismissText={'Annulla'}
                    onConfirm={() => {
                        if (delegatedArea && delegatedArea.id) {
                            handleDeleteArea({
                                id: delegatedArea.id,
                                onSuccess: () => {
                                    setDeleteDialogVisible(false);
                                    handleAreas();
                                }
                            });
                        }
                    }}
                    onDismiss={() => setDeleteDialogVisible(false)}
                />

                <AreaFormDrawer
                    disabled={retrieveAreaLoading || createAreaLoading || updateAreaLoading}
                    drawerVisible={drawerVisible}
                    area={delegatedArea}
                    onDrawerDismiss={() => {
                        setDrawerVisible(false);
                        setDelegatedArea(undefined);
                    }}
                    onSubmit={_handleSubmit}
                />
            </div>
        </IAIApplicationLayout>
    );
}

export default AreaPage;