import React, { useEffect, useMemo } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import {
    Dialog,
    DialogType,
    DialogFooter,
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField
} from '@fluentui/react';

import { TicketReopen } from '../../../../../api-client';

interface ITicketViewReopen {
    disabled?: boolean;
    dialogVisible?: boolean;
    onDialogConfirm: (message: TicketReopen) => void;
    onDialogDismiss: () => void;
}

const TicketViewReopen: React.FunctionComponent<ITicketViewReopen> = ({
    disabled,
    dialogVisible,
    onDialogConfirm,
    onDialogDismiss
}) => {
    const {
        control,
        formState: { errors },
        reset,
        setValue,
        handleSubmit
    } = useForm<TicketReopen>();

    const _handleSubmit: SubmitHandler<TicketReopen> = values => {
        onDialogConfirm(values);
        reset();
    }

    return (
        <Dialog
            hidden={!dialogVisible}
            onDismiss={onDialogDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Riapri il ticket',
                subText: 'Per riaprire un ticket é necessario inserire un messaggio, é possibile riaprire un ticket per un massimo di 2 volte.'
            }}
            modalProps={{ isBlocking: true }}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <Controller
                    name="message"
                    control={control}
                    defaultValue={''}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={'Scrivi un nuovo messaggio:'}
                            placeholder={'Inserisci il messaggio...'}
                            errorMessage={errors.message && "Il messaggio é obbligatorio!"}
                            multiline
                            rows={5}
                            resizable={false}
                            required
                            disabled={disabled}
                        />
                    )}
                />
            </Stack>

            <DialogFooter>
                <DefaultButton
                    disabled={disabled}
                    text={'Annulla'}
                    onClick={onDialogDismiss}
                />
                <PrimaryButton
                    type="submit"
                    disabled={disabled}
                    text={'Invia'}
                    onClick={handleSubmit(_handleSubmit)}
                />
            </DialogFooter>
        </Dialog>
    );
}

export default TicketViewReopen;