import { TicketStatusEnum, TicketPriorityEnum } from '../../api-client';

export class DataEnchanter {
    public static textByStatusEnum = (status: string) => {
        switch (status) {
            case TicketStatusEnum.Open: return 'Aperto';
            case TicketStatusEnum.Pending: return 'Preso in carico';
            case TicketStatusEnum.Working: return 'In lavorazione';
            case TicketStatusEnum.Closed: return 'Risolto';

            default: return 'Sconosciuto';
        }
    };

    public static colorByStatusEnum = (status: string) => {
        switch (status) {
            case TicketStatusEnum.Open: return '#DC3333';
            case TicketStatusEnum.Pending: return '#469CD6';
            case TicketStatusEnum.Working: return '#EAD124';
            case TicketStatusEnum.Closed: return '#136100';

            default: return '#000000';
        }
    };

    public static textByPriorityEnum = (priority: string) => {
        switch (priority) {
            case TicketPriorityEnum.High:
                return 'Alta';
            case TicketPriorityEnum.Low:
                return 'Bassa';
            case TicketPriorityEnum.Medium:
                return 'Media';

            default:
                return 'Sconosciuto';
        }
    };

    public static fileToB64 = (file: any) => new Promise<string>((resolve: any, reject: any) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    public static fileSize = (size: any, systemUnit = false, decimalPoint = 1) => {
        const thresh = systemUnit ? 1000 : 1024;

        if (Math.abs(size) < thresh) {
            return size + ' B';
        }

        const units = systemUnit
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** decimalPoint;

        do {
            size /= thresh;
            ++u;
        } while (Math.round(Math.abs(size) * r) / r >= thresh && u < units.length - 1);


        return size.toFixed(decimalPoint) + ' ' + units[u];
    };

    public static fileIcon = (fileName: string) => {
        if (!fileName) {
            return 'TextDocument';
        }

        const extension = fileName.split('.')?.pop()?.toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'PDF';
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif':
                return 'FileImage';
            case 'xls':
            case 'xlsx':
            case 'csv':
                return 'ExcelDocument';
            case 'doc':
            case 'docx':
            case 'gdoc':
                return 'WordDocument';
            case 'ppt':
            case 'pptx':
                return 'PowerPointDocument';
            default:
                return 'TextDocument';
        }
    };

    public static colorblindByStatusEnum = (status: string) => {
        switch (status) {
            case TicketStatusEnum.Open: return ['#807448', '#936F2A', '#DB3638'];
            case TicketStatusEnum.Pending: return ['#8091CE', '#7492D8', '#2BA2AF'];
            case TicketStatusEnum.Working: return ['#EAD029', '#FFC663', '#f7C1D0'];
            case TicketStatusEnum.Closed: return ['#5F5400', '#6B5015', '#325A61'];

            default: return ['#000000'];
        }
    };
}