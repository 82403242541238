import React from 'react';
import _ from 'lodash';

import { ActivityItem, Panel, PanelType, Separator, Stack } from '@fluentui/react';

import { TicketChangeLog } from '../../../../../api-client';
import { DateTimeEnchanter } from '../../../../../utils/data-enchanters';

import { IAIEmptyContent } from '../../../../../common/components/IAIEmptyContent';
import { IAIShimmer } from '../../../../../common/components/IAIShimmer';

interface IChangeLogsDrawer {
    dataLoading?: boolean;
    drawerVisible?: boolean;
    changeLogs?: TicketChangeLog[];
    onDrawerDismiss: () => void;
};

const ChangeLogsDrawer: React.FunctionComponent<IChangeLogsDrawer> = ({
    dataLoading,
    drawerVisible,
    changeLogs,
    onDrawerDismiss
}) => {
    const mapObj: any = {
        Level01Enum: 'Livello 0',
        Level02Enum: 'Livello 1',
        Level03Enum: 'Livello 2',
        OpenEnum: 'Aperto',
        PendingEnum: 'Preso in carico',
        WorkingEnum: 'In lavorazione',
        ClosedEnum: 'Risolto',
        LowEnum: 'Bassa',
        MediumEnum: 'Media',
        HighEnum: 'Alta'
    };

    const _handleChangesRefined = (changeLog: TicketChangeLog) => {
        let changeLogRefined = changeLog?.change || '';

        _.each(Object.keys(mapObj), entry => {
            changeLogRefined = changeLogRefined.replace(entry, mapObj[entry])
        });

        return changeLogRefined;
    }

    const _handleInitialsFromName = (displayName?: string) => {
        if (!displayName) {
            return 'OP';
        }

        let initials = displayName.split(' ');

        return ((initials.shift()?.[0] || '') + (initials.pop()?.[0] || '')).toUpperCase();
    }

    return (
        <Panel
            customWidth={'500px'}
            headerText={'Monitoraggio Ticket'}
            type={PanelType.custom}
            isBlocking={true}
            isFooterAtBottom={true}
            isOpen={drawerVisible}
            onDismiss={onDrawerDismiss}
        >
            <Separator />

            <Stack tokens={{ childrenGap: 15 }}>
                <IAIShimmer type="shimmer-callout" loading={dataLoading}>
                    <Stack tokens={{ childrenGap: 15 }}>
                        {_.map(changeLogs, (dataItem, dataIndex) => (
                            <ActivityItem
                                key={dataIndex}
                                activityDescription={(
                                    <React.Fragment>
                                        <span key={0}><strong>{dataItem?.userInfo?.displayName}</strong> </span>
                                        <span key={1}>{dataItem?.field} </span>
                                        <span key={2}>{_handleChangesRefined(dataItem)}</span>
                                    </React.Fragment>
                                )}
                                activityPersonas={[
                                    {
                                        imageInitials: _handleInitialsFromName(dataItem?.userInfo?.displayName),
                                        text: dataItem?.userInfo?.displayName,
                                        imageAlt: dataItem?.userInfo?.displayName,
                                        imageShouldFadeIn: true
                                    }
                                ]}
                                timeStamp={DateTimeEnchanter.formatCustomDate(dataItem?.createdAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}
                            />
                        ))}

                        {!dataLoading && !changeLogs?.length && (
                            <IAIEmptyContent title="Nessun dato disponibile" />
                        )}
                    </Stack>
                </IAIShimmer>
            </Stack>
        </Panel>
    );
}

export default ChangeLogsDrawer;