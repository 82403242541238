import { useState } from 'react';

import { useMsal } from "@azure/msal-react";

import { Item, Property } from '../../../../../../api-client';
import { IHookCallback } from '../../../../../../common/models/IHookCallback';
import { PropertyApiClient } from '../../../../../../providers/api-provider';
import { NotificationHelper } from '../../../../../../utils/notification';

import { useAtom } from 'jotai';
import globalDataPropertiesAtom from '../../../../../../common/atoms/atomsProperties';

export const useProperties = () => {
    const {
        instance
    } = useMsal();

    const [properties, setProperties] = useState<Property[]>();
    const [propertiesLoading, setPropertiesLoading] = useState<boolean>(false);
    const [globalDataProperties,setGlobalDataProperties] = useAtom(globalDataPropertiesAtom);

    const handleProperties = async () => {
        setPropertiesLoading(true);
        
        const clientApi = await PropertyApiClient(instance);
        clientApi.getProperties()
            .then((response: any) => {
                setProperties(response.data);
                // Fix for Fluent UI -> Dropdown
                response.data.forEach((props: any) => {
                    for (let index = 0; index < props.values.length; index++) {
                        const element = props.values[index];
                        const obj = { key: element, text: element }
                        props.values[index] = obj;
                    }
                });
                setGlobalDataProperties({
                    collection: response.data,
                    count: response.data.length
                })
            })
            .catch((reason) => {
                setProperties([]);
                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante il tentativo di recupero delle proprietà.',
                    title: 'Impossibile recuperare le proprietà!'
                });
            })
            .finally(() => {
                setPropertiesLoading(false);
            });
    }

    // CRUD Operations

    interface ICreateProperties extends IHookCallback {
        properties: Property;
    }
    const [createProperties, setCreateProperties] = useState<Item>();
    const [createPropertiesLoading, setCreatePropertiesLoading] = useState<boolean>(false);

    const handleCreateProperties = async (params: ICreateProperties) => {
        setCreatePropertiesLoading(true);

        const clientApi = await PropertyApiClient(instance);

        clientApi.insertProperty(params.properties)
            .then((response) => {
                setCreateProperties(response.data);

                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Proprietà creata con successo.',
                    title: 'Proprietà creata!'
                });
            })
            .catch((reason) => {
                setCreateProperties(undefined);

                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: `Si è verificato un errore durante la creazione della Proprietà.`,
                    title: `Impossibile creare la Proprietà!`
                });
            })
            .finally(() => {
                setCreatePropertiesLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IRetrieveProperties extends IHookCallback {
        id: string;
        onRetrieveSuccess: (properties: Property) => void;
    }

    const [retrieveProperties, setRetrieveProperties] = useState<Property>();
    const [retrievePropertiesLoading, setRetrievePropertiesLoading] = useState<boolean>(false);

    const handleRetrieveProperties = async (params: IRetrieveProperties) => {
        setRetrievePropertiesLoading(true);
        const clientApi = await PropertyApiClient(instance);
        clientApi.getProperty(params.id)
            .then((response: any) => {
                setRetrieveProperties(response.data);
                params.onRetrieveSuccess(response.data);
            })
            .catch((reason) => {
                setRetrieveProperties(undefined);
                params.onError && params.onError();
                NotificationHelper.createNotification({
                    type: 'error',
                    message: `Si è verificato un errore durante la creazione della Proprietà.`,
                    title: `Impossibile creare la Proprietà!`
                });
            })
            .finally(() => {
                setRetrievePropertiesLoading(false);
                params.onAlways && params.onAlways();
            });
    }

    interface IUpdateProperties extends IHookCallback {
        id: string;
        properties: Property;
    }

    const [updatePropertiesLoading, setUpdatePropertiesLoading] = useState<boolean>(false);

    const handleUpdateProperties = async (params: IUpdateProperties) => {
        setUpdatePropertiesLoading(true);

        const clientApi = await PropertyApiClient(instance);

        clientApi.updateProperty(params.id, params.properties)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Proprietà aggiornato con successo.',
                    title: 'Proprietà aggiornato!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: `Si è verificato un errore durante l'aggiornamento della Proprietà.`,
                    title: `Impossibile aggiornare la Proprietà!`
                });
            })
            .finally(() => {
                setUpdatePropertiesLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    interface IDeleteProperties extends IHookCallback {
        id: string;
    }

    const [deletePropertiesLoading, setDeletePropertiesLoading] = useState<boolean>(false);

    const handleDeleteProperties = async (params: IDeleteProperties) => {
        setDeletePropertiesLoading(true);

        const clientApi = await PropertyApiClient(instance);

        clientApi.deleteProperty(params.id)
            .then((response) => {
                params.onSuccess && params.onSuccess();

                NotificationHelper.createNotification({
                    type: 'success',
                    message: 'Proprietà cancellata con successo.',
                    title: 'Proprietà cancellata!'
                });
            })
            .catch((reason) => {
                params.onError && params.onError();

                NotificationHelper.createNotification({
                    type: 'error',
                    message: 'Si è verificato un errore durante la cancellazione della Proprietà.',
                    title: 'Impossibile cancellare la Proprietà!'
                });
            })
            .finally(() => {
                setDeletePropertiesLoading(false);

                params.onAlways && params.onAlways();
            });
    }

    return {
        handleProperties,
        properties,
        propertiesLoading,
        // CRUD Operations
        handleCreateProperties,
        createProperties,
        createPropertiesLoading,
        handleRetrieveProperties,
        retrieveProperties,
        retrievePropertiesLoading,
        handleUpdateProperties,
        updatePropertiesLoading,
        handleDeleteProperties,
        deletePropertiesLoading
    }
}