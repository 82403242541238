import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import moment from 'moment';

import { mergeStyleSets, DatePicker, Panel, PanelType, Stack, TextField } from '@fluentui/react';

import { Announcement } from '../../../../../api-client';


import { IAnnouncementForm, useAnnouncementForm } from './hooks/useAnnouncementForm';
import { useClassNames } from './announcement-form-drawer.classNames';

import { AnnouncementFormFooter } from './components/AnnouncementFormFooter';

import 'react-quill/dist/quill.snow.css';
import './announcement-form-drawer.scss';

import { DatePickerStringsIT } from '../../../../../utils/constants/date-format-costants';
import helpers from '../../../../../utils/helpers/helpers';

const styles = mergeStyleSets({
    root: { selectors: { '> *': { marginBottom: 15 } } },
    control: { maxWidth: 300, marginBottom: 15 },
});

interface IAnnouncementFormDrawer {
    disabled?: boolean;
    drawerVisible?: boolean;
    announcement?: Announcement;
    onDrawerDismiss: () => void;
    onSubmit: (announcement: Announcement, announcementId?: string) => void;
}

const AnnouncementFormDrawer: React.FunctionComponent<IAnnouncementFormDrawer> = ({
    disabled,
    drawerVisible,
    announcement,
    onDrawerDismiss,
    onSubmit
}) => {
    const _handleSubmit = (values: IAnnouncementForm) => onSubmit(values, values.id);

    const {
        handleInitialize,
        handleFormChange,
        formValues,
        handleFormSubmit,
        formErrors
    } = useAnnouncementForm(_handleSubmit);

    useEffect(() => {
        if (announcement) {
            handleInitialize(announcement)
            if (announcement.description)
                setDescriptionFieldValue(announcement.description);
        } else {
            handleInitialize({ title: '', description: '', displayName: '', availableFrom: new Date().toDateString(), availableTo: new Date().toDateString() });
            setDescriptionFieldValue('');
        }
    }, [announcement]);

    const _handleDismissForm = () => {
        handleInitialize({ title: '', description: '', displayName: '', availableFrom: new Date().toDateString(), availableTo: new Date().toDateString() });
        onDrawerDismiss();
    }

    const {
        section,
        title,
        editor
    } = useClassNames();

    const [descriptionFieldValue, setDescriptionFieldValue] = React.useState('');
    const handleDescriptionChange = (value: any) => {
        setDescriptionFieldValue(value);
        handleFormChange('description', value)
    }

    return (
        <Panel
            type={PanelType.medium}
            headerText={announcement?.id ? 'Modifica notifica' : 'Inserisci notifica'}
            isOpen={drawerVisible}
            isBlocking={true}
            isFooterAtBottom={true}
            onDismiss={_handleDismissForm}
            onRenderFooterContent={() => (
                <AnnouncementFormFooter
                    disabled={disabled}
                    onDismiss={_handleDismissForm}
                    onSubmit={handleFormSubmit}
                />
            )}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <TextField
                    id={'sln_title__'}
                    label={'Titolo:'}
                    placeholder={'Inserisci il titolo...'}
                    errorMessage={formErrors.title}
                    /**/
                    disabled={disabled}
                    required
                    value={formValues.title}
                    onChange={(ev, value) => handleFormChange('title', value)}
                />

                <div className={section}>
                    <div className="p-0">
                        <label className={title}>Descrizione: *</label>
                        <div className="p-0">
                            <ReactQuill className={editor}
                                value={descriptionFieldValue}
                                onChange={handleDescriptionChange.bind(this)}
                                readOnly={disabled}
                            />
                        </div>
                    </div>
                </div>

                <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <div className="col-6">
                        <DatePicker
                            label="Visibile da:"
                            disabled={disabled}
                            formatDate={(date?: Date): string => helpers.getFormatDate(date)}
                            className={styles.control}
                            strings={DatePickerStringsIT}
                            value={moment(formValues.availableFrom).toDate()}
                            onSelectDate={(value) => {
                                handleFormChange('availableFrom', value)
                            }}
                        />
                    </div>
                    <div className="col-6">
                        <DatePicker
                            label="Visibile a:"
                            disabled={disabled}
                            formatDate={(date?: Date): string => helpers.getFormatDate(date)}
                            className={styles.control}
                            strings={DatePickerStringsIT}
                            value={moment(formValues.availableTo).toDate()}
                            onSelectDate={(value) => {
                                handleFormChange('availableTo', value)
                            }}
                        />
                    </div>
                </Stack>
            </Stack>
        </Panel>
    );
}

export default AnnouncementFormDrawer;