import React from 'react';
import parse from 'html-react-parser';

import { Stack } from '@fluentui/react';
import { Persona } from '@fluentui/react/lib/Persona';

import { TicketComment } from '../../../../../api-client';
import { DateTimeEnchanter } from '../../../../../utils/data-enchanters'
import { useClassNames } from './webchat-message.classNames';

import './webchat-message.scss';

interface IWebchatMessage {
    message?: TicketComment;
    isAdmin?: boolean;
}

const WebchatMessage: React.FunctionComponent<IWebchatMessage> = ({ message, isAdmin }) => {
    const {
        messageContainer,
        messageContainerAlt
    } = useClassNames();

    const _handleWebchatMessage = (className: string) => (
        message &&
        <div className={`webchat-message ${className}`}>
            <Stack tokens={{ childrenGap: 15 }}>
                <Stack horizontal horizontalAlign={'space-between'}>
                    <span className="message-user-info">
                        {!isAdmin && message.userInfo?.id !== '00000000-0000-0000-0000-000000000000'
                            ? 'Operatore'
                            : message.userInfo?.displayName
                        }
                    </span>
                    <span className="message-user-info">
                        {`${DateTimeEnchanter.formatCustomDate(message.createdAt, 'it-IT', { dateStyle: 'short', timeStyle: 'short' })}`}
                    </span>
                </Stack>

                <span className="message">
                    {parse(message.message || '')}
                </span>
            </Stack>
        </div>
    );

    const _handleWebchatPersona = (displayName?: string) => {
        if (!displayName) {
            return (
                <Persona imageInitials={'A'} />
            );
        }

        let displayNameSplitted = displayName.toUpperCase().split(' ');
        if (displayNameSplitted.length > 1) {
            return (
                <Persona imageInitials={`${displayNameSplitted[0].charAt(0)}${displayNameSplitted[1].charAt(0)}`} />
            );
        }

        return (
            <Persona imageInitials={`${displayNameSplitted[0].charAt(0)}${displayNameSplitted[0].charAt(1)}`} />
        );
    }

    return (
        <React.Fragment>
            {message && message.userInfo &&
                <div className={`webchat-message-container`}>
                    {message.userInfo.id === '00000000-0000-0000-0000-000000000000'
                        ?
                        <Stack horizontal tokens={{ childrenGap: 15 }}>
                            <Stack.Item grow>
                                {_handleWebchatMessage(messageContainerAlt)}
                            </Stack.Item>
                            <Stack.Item className="d-none d-md-block">
                                {_handleWebchatPersona(message.userInfo.displayName)}
                            </Stack.Item>
                        </Stack>
                        :
                        <Stack horizontal>
                            <Stack.Item className="d-none d-md-block">
                                {_handleWebchatPersona(message.userInfo.displayName)}
                            </Stack.Item>
                            <Stack.Item grow>
                                {_handleWebchatMessage(messageContainer)}
                            </Stack.Item>
                        </Stack>
                    }
                </div>
            }
        </React.Fragment>
    );
}

export default WebchatMessage;